import React, { useEffect, useState } from "react";
import "../AddCategories/AddCategories";
import style from "../EditCategories/EditCategories.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { MdCategory } from "react-icons/md";
import { Helmet } from "react-helmet-async";
import {
  API_BASE_URL,
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
} from "../../../Constant/Global";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../Offers/Offer.css";
const AddCategories = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [categoryTitle, setCategoryTitle] = useState("");
  const [hindiCategoryTitle, setHindiCategoryTitle] = useState("");
  const [parentCat, setParentCat] = useState("0");
  const [status, setStatus] = useState("1");
  const [validated, setValidated] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const navigate = useNavigate("");

  const handleStatus = (e) => {
    setStatus(e.target.value);
  };

  const { id } = useParams();
  // Function to handle file input change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  // categories api

  useEffect(() => {
    const handleData = async () => {
      try {
        const response = await axios.get(API_BASE_URL + "admin/categories", {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        });
        setCategoryData(response.data.data);
      } catch (error) {
        console.log("errror occured fetching data", error);
      }
    };
    handleData();
  }, []);

  const isNumeric = (value) => {
    return value !== "";
  };

  const validateForm = () => {
    let valid = true;
    if (selectedImage === null) {
      toast.error("image is required");
      valid = false;
    }
    return valid;
  };

  // add category api

  const handleAddCategory = async (e) => {
    e.preventDefault();

    if (!isNumeric(categoryTitle) || !validateForm() || isSubmitting) {
      setValidated(true);
      return;
    }
    setIsSubmitting(true);
    const finalData = {
      title: categoryTitle,
      title_in: hindiCategoryTitle,
      image: selectedImage,
      parentCat: parentCat,
      status: status,
    };
    try {
      const response = await axios.post(
        API_BASE_URL + "admin/add_category",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success(response.data.message);
        setValidated(false);
        setIsSubmitting(false);
        setTimeout(() => {
          navigate("/all_categories");
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
      // setAllOrder(response.data.data);
    } catch (error) {
      console.log("errror occured fetching data", error);
    }
  };

  // Function to handle image removal
  const handleImageRemove = () => {
    setSelectedImage(null);
  };
  return (
    <>
      <Helmet>
        <title>Add Categories | Decorsaga Design</title>
      </Helmet>
      <section className="add_cat_main mt-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 d-flex justify-content-center align-items-center">
              <div className={`card ${style.edit_cat_card}`}>
                <div className="card-body">
                  <h5 className="add_c_t">
                    <MdCategory /> Add Category
                  </h5>
                  <form onSubmit={handleAddCategory}>
                    <div className="row">
                      <div
                        className={`col-lg-4 ${style.edit_cat_label_column}`}
                      >
                        <div className="form-group">
                          <label
                            htmlFor=""
                            className={`${style.edit_cat_common_label}`}
                          >
                            Categories Title *
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group">
                          <input
                            type="text"
                            value={categoryTitle}
                            onChange={(e) => setCategoryTitle(e.target.value)}
                            placeholder={
                              validated && !isNumeric(categoryTitle)
                                ? "category title is require"
                                : "categories title"
                            }
                            className={`form-control ${
                              style.edit_c_cat_input
                            }   ${
                              validated && !isNumeric(categoryTitle)
                                ? `is-invalid ${style.edit_c_cat_input_invalid}`
                                : ""
                            }`}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={`col-lg-4 ${style.edit_cat_label_column}`}
                      >
                        <div className="form-group">
                          <label
                            htmlFor=""
                            className={`${style.edit_cat_common_label}`}
                          >
                            Description *
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group">
                          <input
                            type="text"
                            value={hindiCategoryTitle}
                            onChange={(e) =>
                              setHindiCategoryTitle(e.target.value)
                            }
                            placeholder={
                              validated && !isNumeric(hindiCategoryTitle)
                                ? "category description is require"
                                : "categories description title"
                            }
                            className={`form-control ${
                              style.edit_c_cat_input
                            }   ${
                              validated && !isNumeric(hindiCategoryTitle)
                                ? `is-invalid ${style.edit_c_cat_input_invalid}`
                                : ""
                            }`}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={`col-lg-4 ${style.edit_cat_label_column}`}
                      >
                        <div className="form-group">
                          <label
                            htmlFor=""
                            className={`${style.edit_cat_common_label}`}
                          >
                            Parent Category *
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group">
                          <select
                            name=""
                            id=""
                            value={parentCat}
                            onChange={(e) => setParentCat(e.target.value)}
                            className={`form-control ${style.select_input_cat}`}
                          >
                            <option value="0">No Parent</option>
                            {categoryData.map((elem) => {
                              return (
                                <option key={elem.id} value={elem.id}>
                                  {elem.title}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={`col-lg-4 ${style.edit_cat_label_column}`}
                      >
                        <div className="form-group">
                          <label
                            htmlFor=""
                            className={`${style.edit_cat_common_label}`}
                          >
                            Select Image <br/>
                            (1800w x 435h)
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group">
                          {selectedImage ? (
                            <div className={`${style.selected_img_group}`}>
                              <img
                                src={selectedImage}
                                alt="Selected"
                                className="img-fluid"
                              />
                              <button
                                onClick={handleImageRemove}
                                className={`btn ${style.remove_btn}`}
                              >
                                Remove
                              </button>
                            </div>
                          ) : (
                            <>
                              <input
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                                className={`form-control ${style.edit_c_cat_input}`}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div
                        className={`col-lg-4 ${style.edit_cat_label_column}`}
                      >
                        <div className="form-group">
                          <label
                            htmlFor=""
                            className={`${style.edit_cat_common_label}`}
                          >
                            Status
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group">
                          <div className={`${style.status_group_main}`}>
                            <div className="status-group">
                              <div className="active-sec">
                                <input
                                  type="radio"
                                  name="status"
                                  value="1"
                                  checked={status === "1"}
                                  onChange={handleStatus}
                                  className="me-2"
                                />
                                <span>Active</span>
                              </div>
                              <div className="deactive-sec">
                                <input
                                  type="radio"
                                  name="status"
                                  value="0"
                                  checked={status === "0"}
                                  onChange={handleStatus}
                                  className="me-2"
                                />
                                <span>Deactive</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="Add_btn_group">
                      <button
                        disabled={isSubmitting}
                        className="btn add_to_cat"
                      >
                        {isSubmitting ? "Adding" : "Add Category"}
                      </button>
                    </div>
                    
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position={"top-right"}
          autoClose={2000}
          pauseOnHover={false}
          closeOnClick={true}
        />
      </section>
    </>
  );
};

export default AddCategories;
