/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
// import "./Offer.css";
import style from "../MainSlider/style.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { GiKnightBanner } from "react-icons/gi";
import "../../Offers/Offer.css";
import axios from "axios";
import {
  API_AUTH_PASSWORD,
  API_AUTH_USERNAME,
  API_BASE_URL,
} from "../../../Constant/Global";
import { Helmet } from "react-helmet-async";
import { ToastContainer, toast } from "react-toastify";

const EditCategoryBanner = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImage1, setSelectedImage1] = useState(null);
  const [sliderTitle, setSliderTitle] = useState("");
  const [sliderUrl, setSliderUrl] = useState("");
  const [status, setStatus] = useState("1");
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  // Function to handle file input change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Function to handle image removal
  const handleImageRemove = () => {
    setSelectedImage(null);
    setSelectedImage1(null);
  };

  const handleStatus = (e) => {
    setStatus(e.target.value);
  };

  // edit data of slider
  const handleEditSlider = async (e) => {
    e.preventDefault();
    const finalData = {
      slider_id: id,
      title: sliderTitle,
      slider_url: sliderUrl,
      status: status,
    };
    // Conditionally include the image property
    if (selectedImage) {
      finalData.image = selectedImage;
    }

    console.log("finaldata editslider", finalData);
    try {
      const response = await axios.post(
        API_BASE_URL + "admin/edit_feature_banner",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success(response.data.message);
        setTimeout(() => {
          navigate("/category_banner");
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
      // setAllOrder(response.data.data);
    } catch (error) {
      console.log("errror occured fetching data", error);
    }
  };

  // slider details by id
  useEffect(() => {
    const handleCategoryDetails = async () => {
      const finalData = {
        slider_id: id,
      };
      try {
        const response = await axios.post(
          API_BASE_URL + "admin/feature_banner_detail",
          finalData,
          {
            auth: {
              username: API_AUTH_USERNAME,
              password: API_AUTH_PASSWORD,
            },
          }
        );
        console.log("slider data", response.data.data);
        setSliderTitle(response.data.data.slider_title);
        setSliderUrl(response.data.data.slider_url);

        // Check if the image is available in the response
        if (response.data.data.slider_image) {
          setSelectedImage1(response.data.data.slider_image);
        }

        setStatus(response.data.data.slider_status);
      } catch (error) {
        console.log("errror occured fetching data", error);
      }
    };
    handleCategoryDetails();
  }, []);

  return (
    <>
      <Helmet>
        <title>Update Category Banner | Decorsaga Design</title>
      </Helmet>
      <section className="add_cat_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 d-flex justify-content-center align-items-center">
              <div className={`card ${style.edit_offer_card}`}>
                <div className="card-body">
                  <h5 className="add_c_t">
                    <GiKnightBanner style={{ marginTop: "-.2rem" }} /> Edit
                    Banner
                  </h5>
                  <form onSubmit={handleEditSlider}>
                    <div className="row">
                      <div
                        className={`col-lg-3 ${style.edit_offer_label_column}`}
                      >
                        <div className="form-group">
                          <label htmlFor="">Slider Title : *</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="form-group">
                          <input
                            type="text"
                            value={sliderTitle}
                            onChange={(e) => setSliderTitle(e.target.value)}
                            className={`form-control ${style.offer_input}`}
                            placeholder="Slider Title"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={`col-lg-3 ${style.edit_offer_label_column}`}
                      >
                        <div className="form-group">
                          <label htmlFor="">Slider Url:</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="form-group">
                          <input
                            type="text"
                            value={sliderUrl}
                            onChange={(e) => setSliderUrl(e.target.value)}
                            className={`form-control ${style.offer_input}`}
                            placeholder="Slider Description"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={`col-lg-3 ${style.edit_offer_label_column}`}
                      >
                        <div className="form-group">
                          <label htmlFor="">Image : *</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="form-group">
                          {selectedImage1 && !selectedImage && (
                            <div className={`${style.selected_img_group}`}>
                              <img
                                src={selectedImage1}
                                alt="Selected"
                                className="img-fluid"
                              />
                              <button
                                onClick={handleImageRemove}
                                className={`btn ${style.remove_btn}`}
                              >
                                Remove
                              </button>
                            </div>
                          )}
                          {selectedImage && !selectedImage1 && (
                            <div className={`${style.selected_img_group}`}>
                              <img
                                src={selectedImage}
                                alt="Selected"
                                className="img-fluid"
                              />
                              <button
                                onClick={handleImageRemove}
                                className={`btn ${style.remove_btn}`}
                              >
                                Remove
                              </button>
                            </div>
                          )}
                          {!selectedImage1 && !selectedImage && (
                            <input
                              type="file"
                              accept="image/*"
                              onChange={handleImageChange}
                              className={`form-control ${style.offer_input}`}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    {/* <div className="row">
                    <div
                      className={`col-lg-3 ${style.edit_offer_label_column}`}
                    >
                      <div className="form-group">
                        <label htmlFor="">Select Status : *</label>
                      </div>
                    </div>
                     <div className="col-lg-9">
                      <div className="form-group d-block">
                        <div className="active-sec">
                          <input
                            type="radio"
                            name="status"
                            value="1"
                            checked={status === "1"}
                            onChange={handleStatus}
                            className="me-2"
                          />
                          <span>Active</span>
                        </div>
                        <div className="deactive-sec">
                          <input
                            type="radio"
                            name="status"
                            value="0"
                            checked={status === "0"}
                            onChange={handleStatus}
                            className="me-2"
                          />
                          <span>Deactive</span>
                        </div>
                      </div>
                    </div>
                  </div> */}
                    <div className="Add_btn_group">
                      <button type="submit" className="btn add_to_cat">
                        Update Banner
                      </button>
                    </div>
                    <div className="NoteBox">
                      <p>
                        Note :-{" "}
                        <span>Please Add (580w x 200h) This Size Of Images</span>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position={"top-right"}
          autoClose={2000}
          pauseOnHover={false}
          closeOnClick={true}
        />
      </section>
    </>
  );
};

export default EditCategoryBanner;
