import React, { useEffect, useRef, useState } from "react";
import styles from "../EditAttribute/EditAttribute.module.css";
import ContactsIcon from "@mui/icons-material/Contacts";
import same from "./Add_Image.module.css";
import { useParams } from "react-router-dom";
import "../../Home/Home.css";
import styled from "./ImageGrid.module.css";
import axios from "axios";
import { BsTrash3 } from "react-icons/bs";
import { Helmet } from "react-helmet-async";
import {
  API_BASE_URL,
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
} from "../../../Constant/Global.js";
const AddImageList = ({ toast }) => {
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [product, setProduct] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [lastImage, setLastImage] = useState("");
  const [images, setImages] = useState([]);
  const [error, setError] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    handleAddItem();
  }, []);

  const handleFileChange = (event) => {
    const fileList = event.target.files;
    const fileArray = Array.from(fileList);

    Promise.all(
      fileArray.map((file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });
      })
    )
      .then((base64Images) => {
        setImages([...images, ...base64Images]);
      })
      .catch((error) => {
        console.error("Error reading file:", error);
      });
  };

  const handleAddItem = async () => {
    const finalData = { product_id: id };
    try {
      const response = await axios.post(
        API_BASE_URL + "admin/get_product_image",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      setProduct(response.data.data);
      // console.log(
      //   "last data",
      //   response.data.data[response.data.data.length - 1].id
      // );
      if (response.data.data && response.data.data.length > 0) {
        const lastImageId =
          response.data.data[response.data.data.length - 1].id;
        setLastImage(lastImageId);
      } else {
        // Handle the case where there are no images in the response data
        setLastImage(null);
      }
    } catch (error) {
      console.log("error occured fetching data", error);
    }
  };

  const handleRefresh = async () => {
    await handleAddItem();
  };

  const validateForm = () => {
    let valid = true;
    if (images.length <= 0) {
      toast.error("Image is required");
      valid = false;
    }

    return valid;
  };

  const handleAddProduct = async (e) => {
    e.preventDefault();
    if (!validateForm() || isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    setLoading(true);
    try {
      const data = {
        product_id: id,
        // image: selectedImage,
        image: images || "",
      };
      console.log("add product image finalData", data);
      const response = await axios.post(
        API_BASE_URL + "admin/add_product_image",
        data,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        setIsSubmitting(false);
        setLoading(false);
        toast.success(response.data.message);
        console.log("add image data", response.data.data);
        // setSelectedImage("");
        setImages([]);
        handleRefresh();
        const newImage = response.data.data; // Assuming the API response contains the newly added image data
        // setProduct([newImage, ...product]);
        setProduct([newImage, ...product]);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("errror occured fetching data", error);
    }
  };

  //   Delete product image api

  const handleDelete = async (id) => {
    const confirm = window.confirm("Are you sure want to delete these image ?");
    if (!confirm) {
      return;
    }
    try {
      const finalData = { image_id: id };
      const response = await axios.post(
        API_BASE_URL + "admin/delete_product_image",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      toast.success(response.data.message);
      const updatedImage = product.filter((item) => item.id !== id);
      setProduct(updatedImage);
    } catch (error) {
      console.log("error occuring delete iamge");
    }
  };

  // image

  // const handleImageChange = (e) => {
  //   const file = e.target.files[0];

  //   if (file) {
  //     if (file.type.startsWith("image/")) {
  //       const reader = new FileReader();

  //       reader.onload = function (e) {
  //         setSelectedImage(e.target.result);
  //         setError(null);
  //       };

  //       reader.readAsDataURL(file);
  //     } else {
  //       setError("Selected file is not an image.");
  //     }
  //   }
  // };

  // const handleImageRemove = () => {
  //   setImages([]);
  //   setError(null);
  // };

  const handleImageRemove = (img) => {
    const imgRemove = images.filter((ele) => ele !== img);
    setImages(imgRemove);
    // setImages([]);
    setError(null);
  };

  return (
    <>
      <Helmet>
        <title>Product Images | Decorsaga Design</title>
      </Helmet>
      <section className="container-fluid">
        <div className="row">
          <div className="col-lg-12" style={{ backgroundColor: "#f3f3f3" }}>
            <div className={styles.add_products}>
              <div className={styles.add_products_heading}>
                <div className={styles.main_heading}>
                  <ContactsIcon />{" "}
                  <h6 className={styles.heading_add}>Add Images</h6>
                </div>
                <div className={styles.form_padding}>
                  <div>
                    {/* <Link
                      to={`/add_image/${addImage}`}
                      className={`btn ${styles.btn_add1}`}
                    >
                      Add Image
                    </Link> */}
                    <div className={styles.form_padding}>
                      <div className="">
                        {/* {selectedImage ? (
                          <div className={styles.product_tilte}>
                            <label
                              htmlFor=""
                              className={`mb-2 ${styles.title_heading}`}
                            >
                              Selected Image :{" "}
                            </label>
                            <div className={styled.select_img_sec}>
                              <img
                                src={selectedImage}
                                alt="Selected"
                                className="img-fluid"
                              />
                            </div>
                            <button
                              onClick={handleImageRemove}
                              className={`btn mt-3 ${same.rem_btn}`}
                            >
                              Remove
                            </button>
                          </div>
                        ) : (
                          <div className={styles.product_tilte}>
                            <label htmlFor="" className={styles.title_heading}>
                              Select Image :{" "}
                            </label>
                            <input
                              type="file"
                              accept="image/*"
                              onChange={handleImageChange}
                              className="form-control w-100"
                            />
                          </div>
                        )} */}

                        {/* latest code multi select images */}

                        {images.length > 0 ? (
                          <div className={`${styled.add_multi_img_input_box}`}>
                            <label
                              htmlFor=""
                              className={`mb-2 ${styles.title_heading}`}
                            >
                              Selected Image :{" "}
                            </label>
                            <div className={`${styled.select_multi_img_box}`}>
                              {images.map((img, index) => (
                                <div
                                  className={`${styled.select_multi_img_sub_box}`}
                                >
                                  <div className={styled.select_img_sec}>
                                    <img
                                      src={img}
                                      alt="Selected"
                                      className="img-fluid"
                                    />
                                  </div>
                                  <button
                                    onClick={() => handleImageRemove(img)}
                                    className={`btn mt-3 ms-0 ${same.rem_btn}`}
                                  >
                                    Removes
                                  </button>
                                </div>
                              ))}
                            </div>
                          </div>
                        ) : (
                          <div className={`${styled.add_multi_img_input_box}`}>
                            <label
                              htmlFor=""
                              className={`mb-2 ${styles.title_heading}`}
                            >
                              Selected Image :{" "}
                            </label>
                            <input
                              type="file"
                              onChange={handleFileChange}
                              className="form-control"
                              multiple
                            />
                          </div>
                        )}

                        {/* <div className={`${styled.add_multi_img_input_box}`}>
                          <label
                            htmlFor=""
                            className={`mb-2 ${styles.title_heading}`}
                          >
                            Selected Image :{" "}
                          </label>
                          <input
                            type="file"
                            onChange={handleFileChange}
                            className="form-control"
                            multiple
                          />
                        </div> */}
                        <div
                          // className={styles.product_add_btn}
                          onClick={handleAddProduct}
                          style={{ float: "right", marginBottom: "2rem" }}
                        >
                          <button
                            disabled={isSubmitting}
                            type="submit"
                            className={`btn btn-success `}
                          >
                            {isSubmitting ? "Adding" : " Add Image"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  {loading ? (
                    <div className="text-center">
                      <img
                        src={require("../../../component/assets/images/loading.gif")}
                        alt="loading"
                        height={30}
                        width={30}
                        className="img-fluid"
                      />
                      <p className="text-center">Please Wait </p>
                    </div>
                  ) : (
                    <div className={`${styled.multi_img_box}`}>
                      {product && product.length > 0 ? (
                        product.map((item, index) => (
                          <div
                            key={index}
                            className={`${styled.multi_img_box_sub_sec}`}
                          >
                            <img src={item.image} alt="decorsaga" />
                            <button
                              onClick={() => handleDelete(item.id)}
                              className={`${styled.deleteButton}`}
                            >
                              <BsTrash3 />
                            </button>
                          </div>
                        ))
                      ) : (
                        <p className="ms-5 text-danger">No images available</p>
                      )}
                    </div>
                  )}
                  {/* <div className={styled.container}>
                    {product && product.length > 0 ? (
                      product.map((item) => (
                        <div className={styled.column} key={item.id}>
                          <div className={`${styled.card} card`}>
                            <img
                              src={item.image}
                              className={`card-img-top ${styled["card-img-top"]}`}
                              alt="Product"
                            />
                            <div
                              className={`card-body p-0 ${styled["card-body"]}`}
                            >
                              <button
                                onClick={() => handleDelete(item.id)}
                                className={`${styled.deleteButton}`}
                              >
                                <BsTrash3 />
                              </button>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p className="ms-5 text-danger">No images available</p>
                    )}
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddImageList;
