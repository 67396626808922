import React, { useState } from "react";
import styles from "./Edit_Products.module.css";
import ContactsIcon from "@mui/icons-material/Contacts";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

import {
  API_BASE_URL,
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
} from "../../../Constant/Global";
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
const Edit_Products = ({ toast }) => {
  const { id, pageCount } = useParams("");
  // alert(pageCount);
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImage1, setSelectedImage1] = useState(null);

  const [catId, setCatId] = useState("");
  const [date, setDate] = useState("");
  const [error, setError] = useState(null);
  const [categoryData, setCategoryData] = useState([]);
  const [productDec, setProductDec] = useState("");
  // const [productDecHindi, setProductDecHindi] = useState("");
  const [productDetails, setProductDetails] = useState([]);
  const [editProductData, setEditProductData] = useState({
    p_no: "",
    material: "",
    title: "",
    // title_in: "",
    status: "0",
    instock: "1",
    price: "",
    mrp: "",
    qty: "",
    unit: "",
    // unit_in: "",
    tax: "",
    cashback: "",
    tax: "",
    offer_type: "",
  });
  const [calculationType, setCalculationType] = useState("Normal");

  const [errors, setErrors] = useState({
    p_no: "",
    material: "",
    title: "",
    title_in: "",
    status: "0",
    instock: "1",
    unit: "",
    unit_in: "",
    catId: "",
    calculate_price: "1",
    normal_price: "1",
    tax: "",
  });

  const validateForm = () => {
    let valid = true;
    const newErrors = {
      p_no: "",
      material: "",
      title: "",
      title_in: "",
      status: "0",
      instock: "1",
      unit: "",
      unit_in: "",
      catId: "",
      calculate_price: "1",
      normal_price: "1",
      tax: "",
    };

    // product number validation (required field)
    if (editProductData.p_no === "") {
      newErrors.p_no = "product number is require *";
      valid = false;
    }
    // material validation (required field)
    // if (!/^[a-zA-Z]*$/g.test(editProductData.material)) {
    //   newErrors.material = "material accept only alphabets *";
    //   valid = false;
    // } else
    // if (editProductData.material === "") {
    //   newErrors.material = "material is require *";
    //   valid = false;
    // }
    // title validation (required field)
    // if (!/^[a-zA-Z ]*$/.test(editProductData.title)) {
    //   newErrors.title = "title accept only alphabets *";
    //   valid = false;
    // } else
    if (editProductData.title === "") {
      newErrors.title = "title is require *";
      valid = false;
    }
    // parent category validation (required field)
    if (catId === "") {
      newErrors.catId = "category is require *";
      valid = false;
    }

    // unit validation (required field)
    if (!/^[a-zA-Z]*$/g.test(editProductData.unit)) {
      newErrors.unit = "unit accept only alphabets *";
      valid = false;
    } else if (editProductData.unit === "") {
      newErrors.unit = "please add unit *";
      valid = false;
    }
    // price tax validation (required field)
    if (editProductData.tax === "") {
      newErrors.tax = "tex is require *";
      valid = false;
    } else if (!/^[0-9]+$/.test(editProductData.tax)) {
      newErrors.tax = "tax accept only numbers *";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleProductDescription = (e, editor) => {
    const data = editor.getData();
    setProductDec(data);
  };
  // const handleProductDescriptionHindi = (e, editor) => {
  //   const data = editor.getData();
  //   setProductDecHindi(data);
  // };

  const handleCalculationType = (e) => {
    setCalculationType(e.target.value);
    // console.log(e.target.value);
  };

  // categories api

  useEffect(() => {
    const handleAdd = async () => {
      try {
        const response = await axios.get(API_BASE_URL + "admin/categories", {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        });
        setCategoryData(response.data.data);
      } catch (error) {
        console.log("errror occured fetching data", error);
      }
    };
    handleAdd();
  }, []);

  const handleEditProductChange = (e) => {
    setEditProductData({ ...editProductData, [e.target.name]: e.target.value });
    // const data = editor.getData();
    // console.log(data);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (file.type.startsWith("image/")) {
        const reader = new FileReader();

        reader.onload = function (e) {
          setSelectedImage(e.target.result);
          setError(null); // Clear any previous error
        };

        reader.readAsDataURL(file);
      } else {
        setError("Selected file is not an image.");
      }
    }
  };

  const handleImageRemove = () => {
    setSelectedImage(null);
    setSelectedImage1(null);
    setError(null); // Clear any previous error
  };

  // product details by id

  useEffect(() => {
    handleProductDetails();
  }, []);

  const handleProductDetails = async () => {
    try {
      const data = {
        product_id: id,
      };
      const response = await axios.post(
        API_BASE_URL + "admin/product_details",
        data,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("product details :", response.data.data);
      setEditProductData({
        p_no: response.data.data.product_code,
        material: response.data.data.material,
        title: response.data.data.product_name,
        title_in: response.data.data.product_name_arb,
        instock: response.data.data.in_stock,
        status: "0",
        unit: response.data.data.unit,
        unit_in: response.data.data.unit_in,
        tax: response.data.data.tax,
        price: response.data.data.price,
        mrp: response.data.data.mrp,
        offer_type: response.data.data.offer_type,
      });
      setCatId(response.data.data.category_id);
      setProductDec(response.data.data.product_description);
      // setProductDecHindi(response.data.data.product_description_arb);
      setCalculationType(response.data.data.calculation_type);
      // setSelectedImage(response.data.data.product_image);
      if (response.data.data.product_image) {
        setSelectedImage1(response.data.data.product_image);
      }
    } catch (error) {
      console.log("errror occured fetching data", error);
    }
  };

  // product edit api

  const handleEditProduct = async (e) => {
    e.preventDefault();
    const {
      p_no,
      material,
      title,
      title_in,
      instock,
      status,
      unit,
      unit_in,
      mrp,
      price,
      tax,
      offer_type,
    } = editProductData;

    if (!validateForm()) {
      return;
    }

    try {
      const data = {
        product_id: id,
        title: title,
        title_in: title,
        description: productDec,
        description_in: productDec,
        category_id: catId,
        instock: instock,
        status: status,
        unit: unit,
        unit_in: unit,
        product_code: p_no,
        material: material,
        calculation_type: calculationType,
        tax: tax,
        price: price,
        mrp: mrp,
        offer_type: offer_type,
        // image: selectedImage,
      };

      // Conditionally include the image property
      if (selectedImage) {
        data.image = selectedImage;
      }
      console.log("edit product data", data);
      const response = await axios.post(
        API_BASE_URL + "admin/edit_product",
        data,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success(response.data.message);
        // navigate(`/products/${pageCount}`);
        setTimeout(() => {
          navigate("/products");
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("errror occured fetching data", error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Update Product | Decorsaga Design</title>
      </Helmet>
      <section className="container-fluid mb-5">
        <div className="row">
          <div className="col-lg-12" style={{ backgroundColor: "#f3f3f3" }}>
            <div className={styles.add_products}>
              <div className={styles.add_products_heading}>
                <div className={styles.main_heading}>
                  <ContactsIcon />{" "}
                  <h6 className={styles.heading_add}>Edit Products</h6>
                </div>
                <form
                  className={styles.form_padding}
                  onSubmit={handleEditProduct}
                >
                  <div className={styles.product_add_one_line}>
                    <div
                      className={styles.product_tilte}
                      style={{ width: "50%" }}
                    >
                      <label htmlFor="" className={styles.title_heading}>
                        Product Number :{" "}
                      </label>
                      <input
                        type="text"
                        name="p_no"
                        value={editProductData.p_no}
                        onChange={handleEditProductChange}
                        placeholder="Product Number"
                        className="form-control mt-2"
                        autoComplete="off"
                      />
                      {errors.p_no && (
                        <p className={`${styles.error_text}`}>{errors.p_no}</p>
                      )}
                    </div>
                    <div
                      className={styles.product_tilte}
                      style={{ width: "50%" }}
                    >
                      <label htmlFor="" className={styles.title_heading}>
                        Material :{" "}
                      </label>
                      <input
                        type="text"
                        name="material"
                        value={editProductData.material}
                        onChange={handleEditProductChange}
                        placeholder="Material"
                        className="form-control mt-2"
                        autoComplete="off"
                      />
                      {errors.material && (
                        <p className={`${styles.error_text}`}>
                          {errors.material}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className={styles.product_add_one_line}>
                    <div
                      className={styles.product_tilte}
                      style={{ width: "100%" }}
                    >
                      <label htmlFor="" className={styles.title_heading}>
                        Product Title :{" "}
                      </label>
                      <input
                        type="text"
                        name="title"
                        value={editProductData.title}
                        onChange={handleEditProductChange}
                        placeholder="Product Title"
                        className="form-control mt-2"
                        autoComplete="off"
                      />
                      {errors.title && (
                        <p className={`${styles.error_text}`}>{errors.title}</p>
                      )}
                    </div>
                    {/* <div
                      className={styles.product_tilte}
                      style={{ width: "320px" }}
                    >
                      <label htmlFor="" className={styles.title_heading}>
                        Product Hindi Title :{" "}
                      </label>
                      <input
                        type="text"
                        name="title_in"
                        value={editProductData.title_in}
                        onChange={handleEditProductChange}
                        placeholder="Product Hindi Title"
                        className="form-control mt-2"
                        autoComplete="off"
                      />
                    </div> */}
                  </div>
                  <div className={styles.custom_select}>
                    <label htmlFor="" className={styles.title_heading}>
                      Parent Category :{" "}
                    </label>
                    <select
                      name="parentCategory"
                      id="parentCat"
                      value={catId}
                      onChange={(e) => setCatId(e.target.value)}
                      className="form-control mt-2"
                    >
                      <option value="">Parent Category</option>
                      {categoryData.map((elem, index) => {
                        return (
                          <option key={index} value={elem.id}>
                            {elem.title}
                          </option>
                        );
                      })}
                    </select>
                    {errors.catId && (
                      <p className={`${styles.error_text}`}>{errors.catId}</p>
                    )}
                  </div>
                  <div className={styles.product_tilte}>
                    <label htmlFor="" className={styles.title_heading}>
                      Product Description :{" "}
                    </label>
                    <CKEditor
                      editor={ClassicEditor}
                      data={productDec}
                      onChange={handleProductDescription}
                    />
                  </div>
                  <div className="form-group" style={{ width: "100%" }}></div>
                  <div className="form-group">
                    {selectedImage1 && !selectedImage && (
                      <div
                        className={styles.product_tilte}
                        style={{ width: "100%" }}
                      >
                        <label htmlFor="" className={styles.title_heading}>
                          Selected Thumbnail Image :{" "}
                        </label>
                        <div className={styles.select_img_sec}>
                          <img
                            src={selectedImage1}
                            alt="Selected"
                            className="img-fluid"
                          />
                        </div>
                        <button
                          onClick={handleImageRemove}
                          className={`btn ${styles.rem_btn}`}
                        >
                          Remove
                        </button>
                      </div>
                    )}
                    {selectedImage && !selectedImage1 && (
                      <div
                        className={styles.product_tilte}
                        style={{ width: "100%" }}
                      >
                        <label htmlFor="" className={styles.title_heading}>
                          Selected Thumbnail Image :{" "}
                        </label>
                        <div className={styles.select_img_sec}>
                          <img
                            src={selectedImage}
                            alt="Selected"
                            className="img-fluid"
                          />
                        </div>
                        <button
                          onClick={handleImageRemove}
                          className={`btn ${styles.rem_btn}`}
                        >
                          Remove
                        </button>
                      </div>
                    )}
                    {!selectedImage1 && !selectedImage && (
                      <div
                        className={styles.product_tilte}
                        style={{ width: "100%" }}
                      >
                        <label htmlFor="" className={styles.title_heading}>
                          Select Thumbnail Image :{" "}
                        </label>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleImageChange}
                          className="form-control w-100"
                        />
                      </div>
                    )}
                  </div>
                  <div className={styles.product_add_one_line}>
                    <div
                      className={styles.product_tilte}
                      style={{ width: "50%" }}
                    >
                      <label htmlFor="" className={`${styles.title_heading}`}>
                        MRP :
                      </label>
                      <input
                        type="number"
                        name="mrp"
                        value={editProductData.mrp}
                        onChange={handleEditProductChange}
                        placeholder="00.00"
                        className="form-control mt-2"
                        autoComplete="off"
                      />
                    </div>
                    <div
                      className={styles.product_tilte}
                      style={{ width: "50%" }}
                    >
                      <label htmlFor="" className={`${styles.title_heading}`}>
                        Price :
                      </label>
                      <input
                        type="number"
                        name="price"
                        value={editProductData.price}
                        onChange={handleEditProductChange}
                        placeholder="00.00"
                        className="form-control mt-2"
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className={styles.product_add_one_line}>
                    <div
                      className={styles.product_tilte}
                      style={{ width: "100%" }}
                    >
                      <label htmlFor="" className={`${styles.title_heading}`}>
                        Offer Type:
                      </label>
                      <input
                        type="text"
                        name="offer_type"
                        value={editProductData.offer_type}
                        onChange={handleEditProductChange}
                        placeholder="Offer type"
                        className="form-control text-uppercase  mt-2"
                        autoComplete="off"
                      />
                      {/* {errors.unit && (
                        <p className={`${styles.error_text}`}>{errors.offer_type}</p>
                      )} */}
                    </div>
                  </div>
                  {/* <div className={styles.product_tilte}>
                    <input
                      type="radio"
                      name="calculationType"
                      value="Normal"
                      checked={calculationType === "Normal"}
                      onChange={handleCalculationType}
                      className="mt-2"
                    />
                    <label
                      htmlFor=""
                      className={`ms-2 ${styles.title_heading}`}
                    >
                      Normal Price
                    </label>
                    <input
                      type="radio"
                      name="calculationType"
                      value="Calculated"
                      checked={calculationType === "Calculated"}
                      onChange={handleCalculationType}
                      className="mt-2 ms-4"
                    />
                    <label
                      htmlFor=""
                      className={`ms-2 ${styles.title_heading}`}
                    >
                      Calculate Price
                    </label>
                  </div> */}

                  {/* <div className={styles.product_add_one_line}>
                    <div
                      className={styles.product_tilte}
                      style={{ width: "320px" }}
                    >
                      <label htmlFor="" className={`${styles.title_heading}`}>
                        Price TAX(%):
                      </label>
                      <input
                        type="number"
                        name="tax"
                        value={editProductData.tax}
                        onChange={handleEditProductChange}
                        placeholder="00.00"
                        className="form-control mt-2"
                        autoComplete="off"
                      />
                    </div>
                    <div
                      className={styles.product_tilte}
                      style={{ width: "320px" }}
                    >
                      <label htmlFor="" className={`${styles.title_heading}`}>
                        Qty:
                      </label>
                      <input
                        type="number"
                        name="qty"
                        value={editProductData.qty}
                        onChange={handleEditProductChange}
                        placeholder="00"
                        className="form-control mt-2"
                        autoComplete="off"
                      />
                    </div>{" "}
                  </div> */}
                  <div className={styles.product_add_one_line}>
                    <div
                      className={styles.product_tilte}
                      style={{ width: "50%" }}
                    >
                      <label htmlFor="" className={`${styles.title_heading}`}>
                        Unit:
                      </label>
                      <input
                        type="text"
                        name="unit"
                        value={editProductData.unit}
                        onChange={handleEditProductChange}
                        placeholder="SET/PCS"
                        className="form-control text-uppercase  mt-2"
                        autoComplete="off"
                      />
                      {errors.unit && (
                        <p className={`${styles.error_text}`}>{errors.unit}</p>
                      )}
                    </div>
                    {/* <div
                      className={styles.product_tilte}
                      style={{ width: "320px" }}
                    >
                      <label htmlFor="" className={`${styles.title_heading}`}>
                        Hindi Unit:
                      </label>
                      <input
                        type="text"
                        name="unit_in"
                        value={editProductData.unit_in}
                        onChange={handleEditProductChange}
                        placeholder="SET/PCS"
                        className="form-control text-uppercase mt-2"
                        autoComplete="off"
                      />
                    </div> */}
                    <div
                      className={styles.product_tilte}
                      style={{ width: "50%" }}
                    >
                      <label htmlFor="" className={`${styles.title_heading}`}>
                        Tax:
                      </label>
                      <input
                        type="text"
                        name="tax"
                        value={editProductData.tax}
                        onChange={handleEditProductChange}
                        placeholder="tax"
                        className="form-control text-uppercase  mt-2"
                        autoComplete="off"
                      />
                      {errors.tax && (
                        <p className={`${styles.error_text}`}>{errors.tax}</p>
                      )}
                    </div>
                  </div>
                  {/* <div className={styles.product_add_one_line}>
                    <div
                      className={styles.product_tilte}
                      style={{ width: "50%" }}
                    >
                      <label htmlFor="" className={`${styles.title_heading}`}>
                        Tax:
                      </label>
                      <input
                        type="text"
                        name="tax"
                        value={editProductData.tax}
                        onChange={handleEditProductChange}
                        placeholder="tax"
                        className="form-control text-uppercase  mt-2"
                        autoComplete="off"
                      />
                      {errors.tax && (
                        <p className={`${styles.error_text}`}>{errors.tax}</p>
                      )}
                    </div>
                  </div> */}
                  <div className={styles.product_tilte}>
                    <input
                      type="radio"
                      name="instock"
                      value="1"
                      checked={editProductData.instock === "1"}
                      onChange={handleEditProductChange}
                      placeholder="Product Hindi Title"
                      className="mt-2"
                    />
                    <label
                      htmlFor=""
                      className={`ms-2 ${styles.title_heading}`}
                    >
                      Active
                    </label>
                    <input
                      type="radio"
                      name="instock"
                      value="0"
                      checked={editProductData.instock === "0"}
                      onChange={handleEditProductChange}
                      placeholder="Product Hindi Title"
                      className="mt-2 ms-5"
                    />
                    <label
                      htmlFor=""
                      className={`ms-2 ${styles.title_heading}`}
                    >
                      Deactive
                    </label>
                  </div>
                  {/* <div className={styles.product_add_one_line}>
                    <div
                      className={styles.product_tilte}
                      style={{ width: "320px" }}
                    >
                      <label htmlFor="" className={`${styles.title_heading}`}>
                        Cashback:
                      </label>
                      <input
                        type="text"
                        name="cashback"
                        value={editProductData.cashback}
                        onChange={handleEditProductChange}
                        placeholder="00"
                        className="form-control mt-2"
                        autoComplete="off"
                      />
                    </div>
                    <div
                      className={styles.product_tilte}
                      style={{ width: "320px" }}
                    >
                      <label htmlFor="" className={`${styles.title_heading}`}>
                        Expiry Date:
                      </label>
                      <input
                        type="date"
                        name="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        className="form-control mt-2"
                      />
                    </div>
                  </div> */}

                  <div className={styles.product_add_btn}>
                    <button className={`btn ${styles.btn_add}`}>Save</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Edit_Products;
