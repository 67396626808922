/* eslint-disable react/jsx-pascal-case */
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import "../../Products/ProductAttributeTabs/style.css";
import { ToastContainer, toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import "react-toastify/dist/ReactToastify.css";
import EditPortfolio from "./EditPortfolio";
import PortfolioImages from "./PortfolioImages";
import PortFolioBanner from "./PortFolioBanner";
import PortFolioDescription from "./PortFolioDescription";

const PortfolioTab = () => {
  return (
    <>
      <Helmet>
        <title>Update Product | Decorsaga Design</title>
      </Helmet>
      <Tab.Container id="product-tabs" defaultActiveKey="first">
        <Row className="product-tabs-row">
          {/* <Col sm={2} className="product-tabs-col-3">
          
        </Col> */}
          <Col sm={12} className="product-tabs-12">
            <Nav variant="pills" className="nav-pills-side-tab">
              <Nav.Item className="tabs_nav_links">
                <Nav.Link className="tabs_links" eventKey="first">
                  Data
                  {/* <FaArrowRightLong className="ms-2" /> */}
                </Nav.Link>
              </Nav.Item>
                <Nav.Item className="tabs_nav_links">
                <Nav.Link className="tabs_links" eventKey="second">
                   Banners
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="tabs_nav_links">
                <Nav.Link className="tabs_links" eventKey="third">
                  Info
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="tabs_nav_links">
                <Nav.Link className="tabs_links" eventKey="fourth">
                Gallery Images
                  {/* <FaArrowRightLong className="ms-2" /> */}
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <EditPortfolio toast={toast} />
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <PortFolioBanner toast={toast} />
              </Tab.Pane>
              <Tab.Pane eventKey="third">
                <PortFolioDescription toast={toast} />
              </Tab.Pane>
              <Tab.Pane eventKey="fourth">
                <PortfolioImages toast={toast} />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
        <ToastContainer
          position={"top-right"}
          autoClose={2000}
          pauseOnHover={false}
          closeOnClick={true}
        />
      </Tab.Container>
    </>
  );
};

export default PortfolioTab;
