/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
// import "./Offer.css";
import style from "./style.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { GiKnightBanner } from "react-icons/gi";
import { Helmet } from "react-helmet-async";
import "../Offers/Offer.css";
import axios from "axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import UploadAdapterPlugin from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter';
import {
  API_AUTH_PASSWORD,
  API_AUTH_USERNAME,
  API_BASE_URL,
} from "../../Constant/Global";
import { ToastContainer, toast } from "react-toastify";

const EditBlogs = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImage1, setSelectedImage1] = useState(null);
  const [title, setTitle] = useState("");
  const [startingFrom, setStartingFrom] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("Y");
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  // Function to handle file input change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleProductDescription = (e, editor) => {
    const data = editor.getData();
    setDescription(data);
  };

  // Function to handle image removal
  const handleImageRemove = () => {
    setSelectedImage(null);
    setSelectedImage1(null);
  };

  const handleStatus = (e) => {
    setStatus(e.target.value);
  };

  // const editorConfiguration = {
  //   plugins: [UploadAdapter],
  //   toolbar: ['imageUpload', '|', 'bold', 'italic', 'link', '|', 'undo', 'redo'],
  //   image: {
  //     toolbar: ['imageTextAlternative', '|', 'imageStyle:alignLeft', 'imageStyle:full', 'imageStyle:alignRight'],
  //     upload: {
  //       // Your backend endpoint URL
  //       uploadUrl: 'http://your-backend/upload-image',
  //       // Headers for authentication or other purposes
  //       headers: {
  //         'Authorization': 'Bearer your_token'
  //       }
  //     }
  //   }
  // };

  // details by id api

  useEffect(() => {
    const handleCategoryDetails = async () => {
      const finalData = {
        blog_id: id,
      };
      try {
        const response = await axios.post(
          API_BASE_URL + "admin/blog_detail",
          finalData,
          {
            auth: {
              username: API_AUTH_USERNAME,
              password: API_AUTH_PASSWORD,
            },
          }
        );
        console.log("blog details response", response.data.data);
        setTitle(response.data.data.title);
        setStartingFrom(response.data.data.starting_from);
        setDescription(response.data.data?.blog_description);
        // Check if the image is available in the response
        if (response.data.data.image) {
          setSelectedImage1(response.data.data.image);
        }
        setStatus(response.data.data.status);
      } catch (error) {
        console.log("errror occured fetching data", error);
      }
    };

    handleCategoryDetails();
  }, []);

  // edit data api

  const handleEditSlider = async (e) => {
    e.preventDefault();
    const finalData = {
      blog_id: id,
      title: title,
      blog_description: description,
      starting_from: startingFrom,
      status: status,
    };
    // Conditionally include the image property
    if (selectedImage) {
      finalData.image = selectedImage;
    }
    console.log("finaldata editslider", finalData);
    try {
      const response = await axios.post(
        API_BASE_URL + "admin/edit_blog",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success(response.data.message);
        setTimeout(() => {
          navigate("/blogs");
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
      // setAllOrder(response.data.data);
    } catch (error) {
      console.log("errror occured fetching data", error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Edit Blog | Decorsaga Design</title>
      </Helmet>
      <section className="add_cat_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 d-flex justify-content-center align-items-center">
              <div className={`card ${style.edit_offer_card}`}>
                <div className="card-body">
                  <h5 className="add_c_t">
                    <GiKnightBanner style={{ marginTop: "-.2rem" }} /> Edit Blog
                  </h5>
                  <form onSubmit={handleEditSlider}>
                    <div className="row">
                      <div
                        className={`col-lg-3 ${style.edit_offer_label_column}`}
                      >
                        <div className="form-group">
                          <label htmlFor="">Blog Title : *</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="form-group">
                          <input
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            className={`form-control ${style.offer_input}`}
                            placeholder="Blog Title"
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="row">
                      <div
                        className={`col-lg-3 ${style.edit_offer_label_column}`}
                      >
                        <div className="form-group">
                          <label htmlFor="">Starting From:</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="form-group">
                          <input
                            type="text"
                            value={startingFrom}
                            onChange={(e) => setStartingFrom(e.target.value)}
                            placeholder="price"
                            className={`form-control ${style.offer_input}`}
                          />
                        </div>
                      </div>
                    </div> */}
                    <div className="row">
                      <div
                        className={`col-lg-3 ${style.edit_offer_label_column}`}
                      >
                        <div className="form-group">
                          <label htmlFor="">Blog Description:</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="form-group blog_desc">
                          <CKEditor
                            editor={ClassicEditor}
                            data={description}
                            // config={{
                            //   simpleUpload: {
                            //     uploadUrl: 'YOUR_IMAGE_UPLOAD_ENDPOINT',
                            //   },
                            //   toolbar: {
                            //     items: [
                            //       'heading',
                            //       '|',
                            //       'bold',
                            //       'italic',
                            //       'link',
                            //       '|',
                            //       'bulletedList',
                            //       'numberedList',
                            //       '|',
                            //       'imageUpload',
                            //       'insertTable',
                            //       'blockQuote',
                            //       '|',
                            //       'undo',
                            //       'redo'
                            //     ]
                            //   },
                            // }}
                            onChange={handleProductDescription}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={`col-lg-3 ${style.edit_offer_label_column}`}
                      >
                        <div className="form-group">
                          <label htmlFor="">Image : *</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="form-group">
                          {selectedImage1 && !selectedImage && (
                            <div className={`${style.selected_img_group}`}>
                              <img
                                src={selectedImage1}
                                alt="Selected"
                                className="img-fluid"
                              />
                              <br />
                              <button
                                onClick={handleImageRemove}
                                className={`btn ${style.remove_btn}`}
                              >
                                Remove
                              </button>
                            </div>
                          )}
                          {selectedImage && !selectedImage1 && (
                            <div className={`${style.selected_img_group}`}>
                              <img
                                src={selectedImage}
                                alt="Selected"
                                className="img-fluid"
                              />
                              <br />
                              <button
                                onClick={handleImageRemove}
                                className={`btn ${style.remove_btn}`}
                              >
                                Remove
                              </button>
                            </div>
                          )}
                          {!selectedImage1 && !selectedImage && (
                            <input
                              type="file"
                              accept="image/*"
                              onChange={handleImageChange}
                              className={`form-control ${style.offer_input}`}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={`col-lg-3 ${style.edit_offer_label_column}`}
                      >
                        <div className="form-group">
                          <label htmlFor="">Select Status : *</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="form-group d-block">
                          <div className="active-sec">
                            <input
                              type="radio"
                              name="status"
                              value="Y"
                              checked={status === "Y"}
                              onChange={handleStatus}
                              className="me-2"
                            />
                            <span>Active</span>
                          </div>
                          <div className="deactive-sec">
                            <input
                              type="radio"
                              name="status"
                              value="N"
                              checked={status === "N"}
                              onChange={handleStatus}
                              className="me-2"
                            />
                            <span>Deactive</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="Add_btn_group">
                      <button type="submit" className="btn add_to_cat">
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position={"top-right"}
          autoClose={2000}
          pauseOnHover={false}
          closeOnClick={true}
        />
      </section>
    </>
  );
};

export default EditBlogs;
