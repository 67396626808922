import React, { useEffect, useState } from "react";
import styles from "./EditAttribute.module.css";
import ContactsIcon from "@mui/icons-material/Contacts";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  API_AUTH_PASSWORD,
  API_AUTH_USERNAME,
  API_BASE_URL,
} from "../../../Constant/Global";
import "../../Home/Home.css";
import axios from "axios";

const EditAttribute = ({ toast }) => {
  const [productName, setProductName] = useState("");
  const [tableData, setTableData] = useState([{}]);
  const [value, setValue] = React.useState("one");

  const [finishColor, setFinsihColor] = useState("");
  const [size, setSize] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { id } = useParams();

  const calculatePrice = (mrp, discount) => {
    const discountedPrice = mrp - mrp * (discount / 100);
    return discountedPrice.toFixed(2); // Round the price to 2 decimal places
  };

  // input change field
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedRows = [...tableData];
    updatedRows[index] = { ...updatedRows[index], [name]: value };

    // Calculate price if both MRP and discount are provided
    if (name === "mrp" || name === "discount") {
      const mrp = parseFloat(updatedRows[index].mrp || 0);
      const discount = parseFloat(updatedRows[index].discount || 0);
      updatedRows[index].price = calculatePrice(mrp, discount);
    }

    setTableData(updatedRows);
  };

  // fetch details in product id wise
  const handleFetch = async () => {
    try {
      const finalData = { product_id: id };
      const response = await axios.post(
        API_BASE_URL + "admin/product_attributes",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      setProductName(response.data.product_name);
      setFinsihColor(response.data.first_attribute);
      setSize(response.data.second_attribute);
      console.log("product attribute", response.data);
      const initialRows = response.data.data.map((item) => {
        const mrp = item.mrp;
        const price = item.price;
        const discount = ((mrp - price) / mrp) * 100; // Calculate discount percentage

        return {
          id: item.id,
          product_id: id,
          finish_or_color: item.finish_or_color,
          size: item.size,
          price: item.price,
          mrp: item.mrp,
          discount: discount.toFixed(2), // Round discount to 2 decimal places
          hint: item.hint,
        };
      });

      setTableData(initialRows);
    } catch (error) {
      console.log("error occured fetching data", error);
    }
  };

  useEffect(() => {
    handleFetch();
  }, []);

  const handleAddRow = () => {
    // const lastType =
    //   tableData.length > 0 ? tableData[tableData.length - 1].id : "";
    const newRow = { id: "" }; // Increment type by 1
    setTableData([...tableData, newRow]);
  };

  //
  const handleDataRefresh = async () => {
    await handleFetch();
  };
  // add products
  const handleUpdateData = async (e) => {
    e.preventDefault();
    try {
      const finalData = {
        attribute_data: tableData.map((row) => ({
          attribute_id: row.id ? row.id : "",
          product_id: id,
          finish_or_color: row.finish_or_color,
          size: row.size,
          price: row.price,
          discount: row.discount,
          mrp: row.mrp,
          hint: row.hint,
        })),
        first_attribute: finishColor,
        second_attribute: size,
      };
      console.log("finaldata price", finalData);
      const response = await axios.post(
        API_BASE_URL + "admin/add_product_attribute",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success(response.data.message);
        setTimeout(() => {
          handleDataRefresh();
        }, 2000);
      } else {
        toast.error(response.data.message);
        console.error("Data property not found in API response:", response);
      }
    } catch (error) {
      console.log("error occured updating data", error);
    }
  };

  // delete entry
  const handleDelete = async (id) => {
    let confirm = window.confirm("Are you sure want to delete these !");
    if (!confirm) {
      return;
    }
    try {
      const finalData = {
        attribute_id: id,
      };
      const response = await axios.post(
        API_BASE_URL + "admin/delete_product_attribute",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      const filteredData = tableData.filter((item) => item.id !== id);
      setTableData(filteredData);
      if (response.data.status === true) {
        toast.success("Data Deleted Successfully");
      }
    } catch (error) {
      console.log("error occured deleting date", error);
    }
  };

  // rendered row
  const renderRows = () => {
    return tableData.map((rowData, index) => (
      <tr key={index}>
        <th scope="row">
          <input
            type="readonly"
            name="id"
            className="form-control table-input-box"
            value={index + 1}
            onChange={(e) => handleInputChange(e, index)}
          />
        </th>
        <td>
          <input
            type="text"
            name="finish_or_color"
            value={rowData.finish_or_color || ""}
            onChange={(e) => handleInputChange(e, index)}
            className={`form-control table-input-box`}
            autoFocus
          />
        </td>
        <td>
          <input
            type="text"
            name="size"
            value={rowData.size || ""}
            onChange={(e) => handleInputChange(e, index)}
            className={`form-control table-input-box`}
          />
        </td>
        <td>
          <input
            type="text"
            name="mrp"
            value={rowData.mrp || ""}
            onChange={(e) => handleInputChange(e, index)}
            className={`form-control table-input-box`}
          />
        </td>
        <td>
          <input
            type="text"
            name="discount"
            value={rowData.discount || ""}
            onChange={(e) => handleInputChange(e, index)}
            className={`form-control table-input-box`}
          />
        </td>
        <td>
          <input
            type="text"
            name="price"
            value={rowData.price || ""}
            onChange={(e) => handleInputChange(e, index)}
            className={`form-control table-input-box`}
            readOnly
          />
        </td>

        {/* <td>
          <input
            type="text"
            name="hint"
            value={rowData.hint || ""}
            onChange={(e) => handleInputChange(e, index)}
            className={`form-control table-input-box`}
          />
        </td> */}
        <td>
          <Link onClick={() => handleDelete(rowData.id)}>
            <img
              src={require("../../../component/assets/images/cancel.png")}
              className="img-fluid confirm_img"
              alt=""
            />
          </Link>
        </td>
      </tr>
    ));
  };
  return (
    <>
      <Helmet>
        <title>Update Product | Decorsaga Design</title>
      </Helmet>
      <section className="container-fluid">
        <div className="row">
          <div className="col-lg-12" style={{ backgroundColor: "#f3f3f3" }}>
            <div className={styles.add_products}>
              <div className={styles.add_products_heading}>
                <div className={styles.main_heading}>
                  <ContactsIcon />{" "}
                  <h6 className={styles.heading_add}>Edit Attribute Detail</h6>
                </div>
                <form action="" className={styles.form_padding}>
                  <div className={styles.product_tilte}>
                    <input
                      type="text"
                      placeholder="Product Name"
                      className={`form-control w-100 mt-2 ${styles.product_name_input}`}
                      value={productName}
                      readOnly
                    />
                    {/* <div>
                      <label htmlFor="">Variant</label>
                      <input type="text" placeholder="variant name" />
                      <button>Add Variant</button>
                    </div> */}
                    {/* <div className="">
                      <Box sx={{ width: "100%" }}>
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          textColor="secondary"
                          indicatorColor="secondary"
                          aria-label="secondary tabs example"
                        >
                          <Tab value="one" label="Item One" />
                          <Tab value="two" label="Item Two" />
                          <Tab value="three" label="Item Three" />
                        </Tabs>
                      </Box>
                    </div> */}
                  </div>
                  <div className={styles.product_add_btn}>
                    <button
                      type="button"
                      onClick={handleAddRow}
                      className={`btn ${styles.btn_add1}`}
                    >
                      Add Item
                    </button>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <table className="table table-striped mt-2">
                        <thead>
                          <tr className="main_table_head">
                            <th scope="col">Sr No.</th>
                            <th scope="col">
                              <input
                                type="text"
                                value={finishColor}
                                onChange={(e) => setFinsihColor(e.target.value)}
                              />{" "}
                            </th>
                            <th scope="col">
                              {" "}
                              <input
                                type="text"
                                value={size}
                                onChange={(e) => setSize(e.target.value)}
                              />
                            </th>
                            <th scope="col">Mrp </th>
                            <th scope="col">Disc </th>
                            <th scope="col">Price</th>
                            {/* <th scope="col">Hint </th> */}
                            <th scope="col">Action </th>
                          </tr>
                        </thead>
                        <tbody className={`${styles.main_body_table}`}>
                          {renderRows()}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className={styles.product_add_btn}>
                    <button
                      className={`btn ${styles.btn_add}`}
                      onClick={handleUpdateData}
                    >
                      UPDATE PRODUCT
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EditAttribute;
