/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
// import "./Offer.css";
import style from "../../Slider/MainSlider/style.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { GiKnightBanner } from "react-icons/gi";
import "../../Offers/Offer.css";
import { Helmet } from "react-helmet-async";
import {
  API_AUTH_PASSWORD,
  API_AUTH_USERNAME,
  API_BASE_URL,
} from "../../../Constant/Global";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
const ProductTopBanner = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImage1, setSelectedImage1] = useState(null);
  const [selectedImage2, setSelectedImage2] = useState(null);
  const [selectedImage3, setSelectedImage3] = useState(null);
  const [sliderTitle, setSliderTitle] = useState("");
  const [sliderUrl, setSliderUrl] = useState("");
  const [sliderDesc, setSliderDesc] = useState("");
  const [status, setStatus] = useState("Y");
  const [validated, setValidated] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  // Function to handle file input change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Function to handle image removal
  const handleImageRemove = () => {
    setSelectedImage(null);
    setSelectedImage1(null);
  };

  // Function to handle file input change
  const handleImageChange2 = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        setSelectedImage2(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Function to handle image removal
  const handleImageRemove2 = () => {
    setSelectedImage2(null);
    setSelectedImage3(null);
  };

  // details

  useEffect(() => {
    const handleDetails = async () => {
      // const finalData = {
      //   slider_id: id,
      // };
      // console.log("product top banner",finalData)
      try {
        const response = await axios.post(
          API_BASE_URL + "admin/products_top_banner_detail",
          null,
          {
            auth: {
              username: API_AUTH_USERNAME,
              password: API_AUTH_PASSWORD,
            },
          }
        );
        setSliderTitle(response.data.data.slider_title);
        setSliderUrl(response.data.data.slider_url);
        setSliderDesc(response.data.data.slider_description);
        // Check if the image is available in the response
        if (response.data.data.slider_image) {
          setSelectedImage1(response.data.data.slider_image);
        }
        if (response.data.data.slider_mobile_image) {
          setSelectedImage3(response.data.data.slider_mobile_image);
        }
      } catch (error) {
        console.log("errror occured fetching data", error);
      }
    };
    handleDetails();
  }, []);

  const isNumeric = (value) => {
    return value !== "";
  };

  const validateForm = () => {
    let valid = true;
    if (sliderTitle === "") {
      toast.error("slider title is required");
      valid = false;
    } else if (sliderUrl === "") {
      toast.error("slider Url is required");
      valid = false;
    } else if (sliderDesc === "") {
      toast.error("slider Description is required");
      valid = false;
    } else if (selectedImage === null) {
      toast.error("Desktop View Image is required");
      valid = false;
    } else if (selectedImage2 === null) {
      toast.error("Mobile View Image is required");
      valid = false;
    }

    return valid;
  };

  // add slider

  const handleAddSlider = async (e) => {
    e.preventDefault();
    // if (!isNumeric(sliderTitle) || !isNumeric(sliderUrl)) {
    //   setValidated(true);
    //   return;
    // }
    // if (!validateForm() || isSubmitting) {
    //   return;
    // }
    // setIsSubmitting(true);
    const finalData = {
      title: sliderTitle,
      url: sliderUrl,
      description: sliderDesc,
    };
    // Conditionally include the image property
    if (selectedImage) {
      finalData.image = selectedImage;
    }
    if (selectedImage2) {
      finalData.image_mobile = selectedImage2;
    }
    try {
      const response = await axios.post(
        API_BASE_URL + "admin/update_products_top_banner",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success(response.data.message);
        setValidated(false);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("errror occured fetching data", error);
    }
  };

  const handleStatus = (e) => {
    setStatus(e.target.value);
  };
  return (
    <>
      {/* <Helmet>
        <title>Images Settings | Decorsaga Design</title>
      </Helmet> */}
      <section className="add_cat_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div
                className={`card ${style.edit_offer_card}  `}
                style={{ width: "100%" }}
              >
                <div className="card-body">
                  <h5 className="add_c_t">
                    <GiKnightBanner style={{ marginTop: "-.2rem" }} /> Products
                    Page Top Banner
                  </h5>
                  <form onSubmit={handleAddSlider}>
                    <div className="row" style={{display:"none"}}>
                      <div
                        className={`col-lg-3 ${style.edit_offer_label_column}`}
                      >
                        <div className="form-group">
                          <label htmlFor=""> Title : </label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="form-group">
                          <input
                            type="text"
                            value={sliderTitle}
                            onChange={(e) => setSliderTitle(e.target.value)}
                            placeholder={
                              validated && !isNumeric(sliderTitle)
                                ? " Title is require"
                                : "Title"
                            }
                            className={`form-control ${style.offer_input}   ${
                              validated && !isNumeric(sliderTitle)
                                ? `is-invalid ${style.edit_c_cat_input_invalid}`
                                : ""
                            }`}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={`col-lg-3 ${style.edit_offer_label_column}`}
                      >
                        <div className="form-group">
                          <label htmlFor="">Redirect Url:</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="form-group">
                          <input
                            type="text"
                            value={sliderUrl}
                            onChange={(e) => setSliderUrl(e.target.value)}
                            placeholder={
                              validated && !isNumeric(sliderUrl)
                                ? "Redirect Url is require"
                                : "Redirect Url"
                            }
                            className={`form-control ${style.offer_input}   ${
                              validated && !isNumeric(sliderUrl)
                                ? `is-invalid ${style.edit_c_cat_input_invalid}`
                                : ""
                            }`}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row" style={{display:"none"}}>
                      <div
                        className={`col-lg-3 ${style.edit_offer_label_column}`}
                      >
                        <div className="form-group">
                          <label htmlFor=""> Description:</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="form-group">
                          <input
                            type="text"
                            value={sliderDesc}
                            onChange={(e) => setSliderDesc(e.target.value)}
                            placeholder={
                              validated && !isNumeric(sliderUrl)
                                ? "Description is require"
                                : " Description"
                            }
                            className={`form-control ${style.offer_input}   ${
                              validated && !isNumeric(sliderDesc)
                                ? `is-invalid ${style.edit_c_cat_input_invalid}`
                                : ""
                            }`}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={`col-lg-3 ${style.edit_offer_label_column}`}
                      >
                        <div className="form-group">
                          <label htmlFor="">
                            Image For WebView (3840w x 275h) : 
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="form-group">
                          {selectedImage1 && !selectedImage && (
                            <div className={`${style.selected_img_group}`}>
                              <img
                                src={selectedImage1}
                                alt="Selected"
                                className="img-fluid"
                              />
                              <button
                                onClick={handleImageRemove}
                                className={`btn d-block ${style.remove_btn}`}
                              >
                                Remove
                              </button>
                            </div>
                          )}
                          {selectedImage && !selectedImage1 && (
                            <div className={`${style.selected_img_group}`}>
                              <img
                                src={selectedImage}
                                alt="Selected"
                                className="img-fluid"
                              />
                              <button
                                onClick={handleImageRemove}
                                className={`btn d-block ${style.remove_btn}`}
                              >
                                Remove
                              </button>
                            </div>
                          )}
                          {!selectedImage1 && !selectedImage && (
                            <input
                              type="file"
                              accept="image/*"
                              onChange={handleImageChange}
                              className={`form-control ${style.offer_input}`}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={`col-lg-3 ${style.edit_offer_label_column}`}
                      >
                        <div className="form-group">
                          <label htmlFor="">
                            Image For MobileView(640w x 100h) : 
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="form-group">
                          {selectedImage3 && !selectedImage2 && (
                            <div className={`${style.selected_img_group}`}>
                              <img
                                src={selectedImage3}
                                alt="Selected"
                                className="img-fluid"
                              />
                              <button
                                onClick={handleImageRemove2}
                                className={`btn d-block ${style.remove_btn}`}
                              >
                                Remove
                              </button>
                            </div>
                          )}
                          {selectedImage2 && !selectedImage3 && (
                            <div className={`${style.selected_img_group}`}>
                              <img
                                src={selectedImage2}
                                alt="Selected"
                                className="img-fluid"
                              />
                              <button
                                onClick={handleImageRemove2}
                                className={`btn d-block ${style.remove_btn}`}
                              >
                                Remove
                              </button>
                            </div>
                          )}
                          {!selectedImage3 && !selectedImage2 && (
                            <input
                              type="file"
                              accept="image/*"
                              onChange={handleImageChange2}
                              className={`form-control ${style.offer_input}`}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="Add_btn_group">
                      <button
                        disabled={isSubmitting}
                        type="submit"
                        className="btn add_to_cat"
                      >
                       {isSubmitting ? "Submitting" : "Submit"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductTopBanner;
