/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import { FaClipboardList } from "react-icons/fa";
import SearchIcon from "@mui/icons-material/Search";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import { Helmet } from "react-helmet-async";
import "../../Home/Home.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  API_AUTH_PASSWORD,
  API_AUTH_USERNAME,
  API_BASE_URL,
} from "../../../Constant/Global";
import { ToastContainer, toast } from "react-toastify";
const PortfolioCategoryList = () => {
  const navigate = useNavigate("");
  const [isImage1Visible, setImage1Visible] = useState({});
  const [listData, setListData] = useState([]);
  const [sorting, setSorting] = useState({
    column: "sale_id",
    ascending: true,
  });
  const [search, setSearch] = useState("");
  const ArrowBackIcon = () => <span>Previous</span>;
  const ArrowForwardIcon = () => <span>Next</span>;
  const handleVisible = () => {
    setImage1Visible(!isImage1Visible);
  };

  // slider data
  useEffect(() => {
    const handlePageSlider = async () => {
      try {
        const response = await axios.get(
          API_BASE_URL + "admin/portfolio_category",
          {
            auth: {
              username: API_AUTH_USERNAME,
              password: API_AUTH_PASSWORD,
            },
          }
        );
        console.log("Portfolio list response", response.data.data);
        setListData(response.data.data);
      } catch (error) {
        console.log("error occured fetching data", error);
      }
    };
    handlePageSlider();
  }, []);

  const handleEdit = (id) => {
    navigate(`/edit_portfolio_category/${id}`);
  };

  const handleAddBanner = () => {
    navigate("/add_portfolio_category");
  };

  // filtered the data
  const filtered = listData.filter((data) =>
    data.name.toLowerCase().includes(search.toLowerCase())
  );

  // delete slider
  const handleDelete = async (id) => {
    var check = window.confirm("Are You Sure Want To Delete This Item ?");
    if (!check) {
      return;
    }
    const finalData = {
      portfolio_category_id: id,
    };
    try {
      const response = await axios.post(
        API_BASE_URL + "admin/delete_portfolio_category",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success(response.data.message);
        const updateProducts = listData.filter((item) => item.id !== id);
        setListData(updateProducts);
      } else {
        toast.error(response.data.message);
      }
      // setAllOrder(response.data.data);
    } catch (error) {
      console.log("errror occured fetching data", error);
    }
  };

  const handleSort = (columnName) => {
    if (columnName === sorting.column) {
      setSorting({ ...sorting, ascending: !sorting.ascending });
    } else {
      setSorting({ column: columnName, ascending: true });
    }

    // Sort the data
    const isAscending = sorting.column === columnName && sorting.ascending;
    const sortedData = [...listData].sort((a, b) => {
      if (columnName === "order_date") {
        // Sort based on the initialRegistration date
        const dateA = a.on_date ? a.on_date.split("/").reverse().join("") : "";
        const dateB = b.on_date ? b.on_date.split("/").reverse().join("") : "";

        return dateA.localeCompare(dateB) * (sorting.ascending ? 1 : -1);
      } else {
        // Apply your existing logic for other columns
        const aValue = (a[columnName] || "").toLowerCase();
        const bValue = (b[columnName] || "").toLowerCase();

        return isAscending
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }
    });

    setListData(sortedData);
  };

  const renderArrow = (columnName) => {
    if (sorting.column === columnName) {
      return sorting.ascending ? (
        <img
          src={require("../../../component/assets/images/tables-icon/icon_sorting1.png")}
          className="img-fluid"
          alt=""
        />
      ) : (
        <img
          src={require("../../../component/assets/images/tables-icon/icon_sorting2.png")}
          className="img-fluid"
          alt=""
        />
      );
    }
    return (
      <>
        <img
          src={require("../../../component/assets/images/tables-icon/icon_sorting2.png")}
          className="img-fluid"
          alt=""
        />
      </>
    ); // Display the up arrow by default
  };

  return (
    <>
      <Helmet>
        <title>Portfolio | Decorsaga Design</title>
      </Helmet>
      <section className="today_order_main">
        <div className="container-fluid">
          <div className="row  px-2">
            <div className="col-lg-12">
              <div className="today_order_table">
                <div className="table_body_order">
                  <div className="row">
                    <div className="col-lg-7 d-flex align-items-center">
                      <div className="cart_heading">
                        <h6 className="today_heading">
                          {" "}
                          <FaClipboardList style={{ marginTop: "-5px" }} />{" "}
                          Portfolio Category List
                        </h6>
                        <div className="show_entreis">
                          {listData.length > 0 ? listData.length : "0"}
                        </div>
                        <h6 className="show_entry_heading">Show entries</h6>
                      </div>
                    </div>
                    <div className="col-lg-5 pe-4 d-flex justify-content-end align-items-center">
                      <div className="today_order_Search">
                        <input
                          type="search"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                          className="form-control search_input1"
                          placeholder="Search"
                        />
                        {search ? "" : <SearchIcon className="search_icon1" />}
                      </div>
                      <div className={`${style.add_banner_sec}`}>
                        <button
                          onClick={handleAddBanner}
                          className={`btn ${style.add_banner_btn}`}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 py-1">
              <div className="card p-0 pb-4 home_table_card">
                <div className="card-body p-0">
                  <div className="table-responsive">
                    <table className="table home_table_main table-striped">
                      <thead>
                        <tr className="main_table_head">
                          <th scope="col" onClick={() => handleSort("id")}>
                            Sr No {renderArrow("id")}
                          </th>
                          <th scope="col" onClick={() => handleSort("name")}>
                           Category Name
                            {renderArrow("name")}
                          </th>
                          {/* <th
                            scope="col"
                            onClick={() => handleSort("description")}
                          >
                            Description
                            {renderArrow("description")}
                          </th> */}
                          <th scope="col" onClick={() => handleSort("image")}>
                            Image
                            {renderArrow("image")}
                          </th>
                          <th scope="col" onClick={() => handleSort("status")}>
                            Status
                            {renderArrow("status")}
                          </th>
                          <th scope="col">Action </th>
                        </tr>
                      </thead>
                      <tbody className="main_body_table">
                        {filtered.length > 0 ? (
                          filtered.map((ele, index) => {
                            return (
                              <tr key={ele.id}>
                                <th scope="row">
                                  <div className="table_middle_text_box">
                                    {index + 1}
                                  </div>
                                </th>
                                <td>
                                  <div className="table_middle_text_box">
                                    {ele.name}
                                  </div>
                                </td>
                                {/* <td className={style.slider_eclipse}>
                                  <div className="table_middle_text_box">
                                    {ele.description}
                                  </div>
                                </td> */}
                                <td className="d-flex justify-content-center  align-items-center ">
                                  <div className={`${style.table_img_sec}`}>
                                    {" "}
                                    <img
                                      src={ele.image}
                                      className="img-fluid"
                                      height={50}
                                      width={50}
                                      alt="offer-img"
                                    />{" "}
                                  </div>
                                </td>
                                <td>
                                  <div className="table_middle_text_box">
                                    {ele.status === "Y" ? (
                                      <span className="text-success fw-bold">
                                        Active
                                      </span>
                                    ) : (
                                      <span className="text-danger fw-bold">
                                        InActive
                                      </span>
                                    )}
                                  </div>
                                </td>
                                <td>
                                  <div className="table_middle_text_box">
                                    <div
                                      className="dispatch-table"
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="bottom"
                                      title="edit"
                                      onClick={() => handleEdit(ele.id)}
                                    >
                                      <img
                                        src={require("../../../component/assets/images/edit-icon.png")}
                                        className="img-fluid me-2"
                                        alt="edit"
                                        width={22}
                                      />
                                    </div>
                                    <div
                                      className="order-assign-table"
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="bottom"
                                      title="Cancel"
                                      onClick={() => handleDelete(ele.id)}
                                    >
                                      <img
                                        src={require("../../../component/assets/images/cancel.png")}
                                        className="img-fluid"
                                        alt="cancel"
                                        width={22}
                                      />
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="7">
                              <img
                                src={require("../../../component/assets/images/nodatafound.png")}
                                className="img-fluid"
                                alt="noDataFound"
                                width={200}
                              />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="pagination_table">
                    <Stack spacing={2}>
                      <Pagination
                        count={1}
                        renderItem={(item) => (
                          <PaginationItem
                            slots={{
                              previous: ArrowBackIcon,
                              next: ArrowForwardIcon,
                            }}
                            {...item}
                          />
                        )}
                      />
                    </Stack>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position={"top-right"}
          autoClose={2000}
          pauseOnHover={false}
          closeOnClick={true}
        />
      </section>
    </>
  );
};

export default PortfolioCategoryList;
