/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import "../services/style.css";
import { Helmet } from "react-helmet-async";
import { MdCategory } from "react-icons/md";
import { AiOutlineSearch } from "react-icons/ai";
// import Table from "../../Table";
import axios from "axios";
import Skeleton from "../../component/skeleton/Skeleton";
// import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  API_BASE_URL,
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
} from "../../Constant/Global";

const Faq = () => {
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate("");
  const [categoryData, setCategoryData] = useState([]);

  // categories api

  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    try {
      const response = await axios.get(API_BASE_URL + "admin/faq_list", {
        auth: {
          username: API_AUTH_USERNAME,
          password: API_AUTH_PASSWORD,
        },
      });
      console.log("response faq list", response.data);
      setIsLoading(false);
      setCategoryData(response.data.data);
    } catch (error) {
      console.log("errror occured fetching data", error);
    }
  };

  const filterCat = categoryData.filter((elem) => {
    return elem.question.toLowerCase().includes(search.toLowerCase());
  });

  const handleRefresh = async () => {
    await getList();
  };

  const handleEdit = (id) => {
    navigate(`/edit-faq/${id}`);
  };

  // delete category
  const handleDelete = async (id) => {
    var check = window.confirm("Are Your Sure To Delete This Faq ?");
    if (!check) {
      return;
    }
    const finalData = {
      faq_id: id,
    };
    try {
      const response = await axios.post(
        API_BASE_URL + "admin/delete_faq",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success(response.data.message);
        handleRefresh();
      } else {
        toast.error(response.data.message);
      }
      // setAllOrder(response.data.data);
    } catch (error) {
      console.log("errror occured fetching data", error);
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title></title>
      </Helmet>
      {isLoading ? (
        <Skeleton />
      ) : (
        <div className="categories-main">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card category_main_card position-relative">
                  <div className="card-header category_header mb-1 cat-title-img-g">
                    <h6 className="card-title mb-0">
                      <MdCategory style={{ marginTop: "-5px" }} /> FAQ
                    </h6>
                    <div className="search-input-btn-main">
                      <form className="search-input-btn-sub">
                        <div className="form-group mb-0 search-input-cat">
                          <input
                            type="text"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder="Search"
                            className="categories_search_input"
                          />
                          <div className="search-icon-cat">
                            <AiOutlineSearch color="#868686" />
                          </div>
                        </div>
                        <div className="form-group mb-0">
                          <Link to="/add-faq" className="category_add_link">
                            Add
                          </Link>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <div className="table-responsive">
                      <table className="table table-striped align-middle">
                        <thead className="cat_thead">
                          <tr>
                            <th scope="col">Sr No</th>
                            <th scope="col">Question</th>
                            <th scope="col">Category</th>
                            {/* <th scope="col">Image</th>
                            <th scope="col">Status</th> */}
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody className="categories-tbody">
                          {filterCat.length <= 0 ? (
                            <tr>
                              <td colSpan="5">
                                <img
                                  src={require("../../component/assets/images/nodatafound.png")}
                                  className="img-fluid"
                                  alt="noDataFound"
                                  width={200}
                                />
                              </td>
                            </tr>
                          ) : (
                            <>
                              {filterCat.map((ele, index) => {
                                return (
                                  <tr key={ele.id}>
                                    <td>{index + 1}</td>
                                    <td>{ele.question}</td>
                                    <td>{ele.category_name}</td>
                                    {/* <td>
                                      {ele.status == "1" ? (
                                        <span className="active_status">
                                          Active
                                        </span>
                                      ) : (
                                        <span className="dective_status">
                                          Deactive
                                        </span>
                                      )}
                                    </td> */}
                                    <td>
                                      <div className="action_group d-flex justify-content-center align-items-center">
                                        <div
                                          className="dispatch-table"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="bottom"
                                          title="edit"
                                          onClick={() => handleEdit(ele.id)}
                                        >
                                          <img
                                            src={require("../../component/assets/images/edit-icon.png")}
                                            className="img-fluid me-2"
                                            alt="edit"
                                            width={23}
                                          />
                                        </div>
                                        <div
                                          className="order-assign-table"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="bottom"
                                          title="Cancel"
                                          onClick={() => handleDelete(ele.id)}
                                        >
                                          <img
                                            src={require("../../component/assets/images/cancel.png")}
                                            className="img-fluid"
                                            alt="cancel"
                                            width={23}
                                          />
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer
            position={"top-right"}
            autoClose={2000}
            pauseOnHover={false}
            closeOnClick={true}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default Faq;
