/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
// import "./Offer.css";
import style from "../Slider/MainSlider/style.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { GiKnightBanner } from "react-icons/gi";
import "../Offers/Offer.css";
import { Helmet } from "react-helmet-async";
import {
  API_AUTH_PASSWORD,
  API_AUTH_USERNAME,
  API_BASE_URL,
} from "../../Constant/Global";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import CategoryBanner from "../Slider/CategoryBanner/CategoryBanner";
import HomePageBanner from "./homePageBanner/HomePageBanner";
import ProductTopBanner from "./productsTopBanner/ProductTopBanner";
import Add_Coupon from "../Products/AddCoupon/Add_Coupon";
import HomePopUpImg from "../homePopUpImg/HomePopUpImg";
import ProductDetailsBanner from "./ProductDetailsBanner/ProductDetailsBanner";
import ContactPageBanner from "./contactPageBanner/ContactPageBanner";
import LoginPopUpBanner from "./loginPopUpBanner/LoginPopUpBanner";
import CheckOutPageBanner from "./checkOutPageBanner/CheckOutPageBanner";

const ImagesSetting = () => {
  return (
    <>
      <Helmet>
        <title>Images Settings | Decorsaga Design</title>
      </Helmet>
      <section>
        {/* home banner */}
        <HomePageBanner />
        {/* products page top banner */}
        <ProductTopBanner />
        {/* CategoryBanner */}
        <CategoryBanner />
        {/* products page side banner*/}
        <Add_Coupon />

        {/* product details page banner */}
        <ProductDetailsBanner/>

        {/* contact page banner */}
        <ContactPageBanner/>

        {/*checkout page banner  */}
        <CheckOutPageBanner/>
        {/* login pop up banner */}
        <LoginPopUpBanner/>
        {/* login before & after */}
        <HomePopUpImg />
        <ToastContainer
          position={"top-right"}
          autoClose={2000}
          pauseOnHover={false}
          closeOnClick={true}
        />
      </section>
    </>
  );
};

export default ImagesSetting;
