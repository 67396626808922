/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
// import "./Offer.css";
import style from "./style.module.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { GiKnightBanner } from "react-icons/gi";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Helmet } from "react-helmet-async";
import "../Offers/Offer.css";
import "../AllCategories/Categories.css";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  API_AUTH_PASSWORD,
  API_AUTH_USERNAME,
  API_BASE_URL,
} from "../../Constant/Global";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { MdCategory } from "react-icons/md";
import { AiOutlineSearch } from "react-icons/ai";
const About = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [video, setVideo] = useState("");
  const [startingFrom, setStartingFrom] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("Y");
  const [validated, setValidated] = useState(false);
  const [teamData, setTeamData] = useState([]);
  const [aboutData, setAboutData] = useState([]);
  const [search, setSearch] = useState("");
  const [isDialogOpen, setDialogOpen] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  // Function to handle file input change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(null);
  };

  const handleViewAboutUs = (orderId) => {
    setDialogOpen(orderId);
  };

  const handleProductDescription = (e, editor) => {
    const data = editor.getData();
    setDescription(data);
  };

  // Function to handle image removal
  const handleImageRemove = () => {
    setSelectedImage(null);
  };

  const validateForm = () => {
    let valid = true;
    if (video === "") {
      toast.error("video link is required");
      valid = false;
    } else if (description === "") {
      toast.error("Description is required");
      valid = false;
    }

    return valid;
  };

  // get team list

  useEffect(() => {
    const handleGetAboutData = async () => {
      try {
        const response = await axios.get(API_BASE_URL + "admin/about_us", {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        });
        setIsLoading(false);
        console.log("about data", response.data.data);
        setAboutData(response.data.data);
        setVideo(response.data.data.video_link);
        setDescription(response.data.data.our_story);
        setStatus(response.data.data.status);
      } catch (error) {
        console.log("errror occured fetching data", error);
      }
    };
    handleGetAboutData();
  }, []);

  // add slider

  const handleUpdateAboutData = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    const finalData = {
      video_link: video,
      our_story: description,
      status: status,
    };
    console.log("finalData update about", finalData);
    try {
      const response = await axios.post(
        API_BASE_URL + "admin/update_about_us",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success(response.data.message);
        setValidated(false);
        setTimeout(() => {
          navigate("/about");
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
      // setAllOrder(response.data.data);
    } catch (error) {
      console.log("errror occured fetching data", error);
    }
  };

  // get team list

  useEffect(() => {
    const handleGetTeamData = async () => {
      try {
        const response = await axios.get(API_BASE_URL + "admin/team_list", {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        });
        setIsLoading(false);
        console.log("all team data", response.data.data);
        setTeamData(response.data.data);
      } catch (error) {
        console.log("errror occured fetching data", error);
      }
    };
    handleGetTeamData();
  }, []);

  const filterTeamData = teamData.filter((elem) => {
    return elem.name.toLowerCase().includes(search.toLowerCase());
  });

  // delete team list
  const handleDelete = async (id) => {
    var check = window.confirm(
      "Are you sure you want to delete this team member?"
    );
    if (!check) {
      return;
    }
    const finalData = {
      team_id: id,
    };
    try {
      const response = await axios.post(
        API_BASE_URL + "admin/delete_team",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success(response.data.message);
        const updateProducts = teamData.filter((item) => item.id !== id);
        setTeamData(updateProducts);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("errror occured fetching data", error);
    }
  };

  // edit data team member
  const handleEdit = (id) => {
    navigate(`/edit_team/${id}`);
  };

  const columns = [
    {
      name: "Sr No",
      selector: "id",
      sortable: true,
      width: "100px",
      cell: (row, index) => index + 1,
      style: { fontSize: "15px", color: "black"},
    },
    {
      name: "Name",
      selector: "title",
      sortable: true,
      width:"30%",
      cell: (row) => (
        <div className={style.team_name_div}>
          <div className="cat_img_sec">
            <img src={row.image} alt="img" className="img-fluid h-100 w-100" />
          </div>
          <div>
            <p className={style.name_para_team}>{row.name} </p>
            <span className="categroy_text text-center ">
              {row.designation}
            </span>
          </div>
        </div>
      ),
      style: { fontSize: "15px", color: "black" },
    },
    {
      name: "About Us",
      selector: "about",
      sortable: true,
      cell: (row) => (
        <div>
          <Link onClick={() => handleViewAboutUs(row.about)}>View about</Link>

          {row.about && (
            <Dialog
              open={isDialogOpen === row.about}
              onClose={handleCloseDialog}
            >
              <DialogTitle>About Us</DialogTitle>
              <DialogContent>
                <div
                  className="Quick_pro"
                  dangerouslySetInnerHTML={{
                    __html: row.about,
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </div>
      ),
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      cell: (row) => (
        <>
          {row.status === "Y" ? (
            <span className="active_status">Active</span>
          ) : (
            <span className="dective_status">Deactive</span>
          )}
        </>
      ),
      style: { fontSize: "15px", color: "black" },
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="action_group d-flex justify-content-center align-items-center">
          <div
            className="dispatch-table"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title="edit"
            onClick={() => handleEdit(row.id)}
          >
            <img
              src={require("../../component/assets/images/edit-icon.png")}
              className="img-fluid me-2"
              alt="edit"
              width={23}
            />
          </div>
          <div
            className="order-assign-table"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title="Cancel"
            onClick={() => handleDelete(row.id)}
          >
            <img
              src={require("../../component/assets/images/cancel.png")}
              className="img-fluid"
              alt="cancel"
              width={23}
            />
          </div>
        </div>
      ),
    },
  ];

  const handleStatus = (e) => {
    setStatus(e.target.value);
  };

  const customStyles = {
    table: {
      style: {
        border: "1px solid #dddd",
        fontFamily: "Poppins",
      },
    },
    headRow: {
      style: {
        border: "none", // Set border style for the header row
        fontFamily: "Poppins",
        fontWeight: "500",
        backgroundColor: "#625C5C",
      },
    },
    rows: {
      style: {
        border: "1px solid #dddd", // Set border style for the rows
        fontFamily: "Poppins",
      },
    },
   
  };
  return (
    <>
      <Helmet>
        <title>About Us | Decorsaga Design</title>
      </Helmet>
      <section className="add_cat_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 ">
              <div className={`card w-100  ${style.edit_offer_card}`}>
                <div className="card-body">
                  <h5 className="add_c_t">
                    <GiKnightBanner style={{ marginTop: "-.2rem" }} /> About Us
                  </h5>
                  <form onSubmit={handleUpdateAboutData}>
                    <div className="row">
                      <div
                        className={`col-lg-3 ${style.edit_offer_label_column}`}
                      >
                        <div className="form-group">
                          <label htmlFor="">Video Link : *</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="form-group">
                          <input
                            type="text"
                            value={video}
                            onChange={(e) => setVideo(e.target.value)}
                            placeholder="Video link"
                            className={`form-control ${style.offer_input}`}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={`col-lg-3 ${style.edit_offer_label_column}`}
                      >
                        <div className="form-group">
                          <label htmlFor="">Our Story:</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className={`form-group blog_desc`}>
                          <CKEditor
                            editor={ClassicEditor}
                            data={description}
                            onChange={handleProductDescription}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div
                        className={`col-lg-3 ${style.edit_offer_label_column}`}
                      >
                        <div className="form-group">
                          <label htmlFor="">Select Status : *</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="form-group d-block">
                          <div className="active-sec">
                            <input
                              type="radio"
                              name="status"
                              value="Y"
                              checked={status === "Y"}
                              onChange={handleStatus}
                              className="me-2"
                            />
                            <span>Active</span>
                          </div>
                          <div className="deactive-sec">
                            <input
                              type="radio"
                              name="status"
                              value="N"
                              checked={status === "N"}
                              onChange={handleStatus}
                              className="me-2"
                            />
                            <span>Deactive</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="Add_btn_group justify-content-end ">
                      <button type="submit" className="btn add_to_cat text-end">
                        Submit
                      </button>
                    </div>
                    <hr />
                  </form>
                  <div className="card category_main_card position-relative">
                    <div className="card-header category_header mb-1 cat-title-img-g">
                      <h6 className="card-title mb-0">
                        <MdCategory style={{ marginTop: "-5px" }} /> Our Team
                      </h6>
                      <div className="search-input-btn-main">
                        <form className="search-input-btn-sub">
                          <div className="form-group mb-0 search-input-cat">
                            <input
                              type="text"
                              value={search}
                              onChange={(e) => setSearch(e.target.value)}
                              placeholder="Search"
                              className="categories_search_input"
                            />
                            <div className="search-icon-cat">
                              <AiOutlineSearch color="#868686" />
                            </div>
                          </div>
                          <div className="form-group mb-0">
                            <Link to="/add_team" className="category_add_link">
                              Add
                            </Link>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="card-body p-0">
                      <div className="table-responsive">
                        <DataTable
                          columns={columns}
                          data={filterTeamData}
                          pagination
                          paginationPerPage={10}
                          highlightOnHover
                          customStyles={customStyles}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ToastContainer
          position={"top-right"}
          autoClose={2000}
          pauseOnHover={false}
          closeOnClick={true}
        />
      </section>
    </>
  );
};

export default About;
