import axios from "axios";
import React, { useState } from "react";
import {
  API_AUTH_PASSWORD,
  API_AUTH_USERNAME,
  API_BASE_URL,
} from "../../Constant/Global";
import { ToastContainer, toast } from "react-toastify";
import ContactsIcon from "@mui/icons-material/Contacts";
import styles from "../Products/EditAttribute/EditAttribute.module.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Helmet } from "react-helmet-async";

const SendMailToCustomer = () => {
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleMessageOnchange = (e, editor) => {
    const data = editor.getData();
    setMessage(data);
  };

  const handleSend = async (e) => {
    e.preventDefault();
    const data = {
      subject: subject,
      message_content: message,
    };
    console.log("finalData", data);
    try {
      const response = await axios.post(
        API_BASE_URL + "admin/send_offer",
        data,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("response :", response.data);

      if (response.data.status === true) {
        toast.success(response.data.message);
        setSubject("");
        setMessage("");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("errror occured fetching data", error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Send Mail To Customer| Decorsaga Design</title>
      </Helmet>
      <section className="container-fluid mt-5">
        <div className="row">
          <div className="col-lg-12">
            <div className={styles.add_products}>
              <div className={styles.add_products_heading}>
                <div className={styles.main_heading}>
                  <ContactsIcon />{" "}
                  <h6 className={styles.heading_add}>Send Mail To Customer</h6>
                </div>
                <div className={styles.form_padding}>
                  <div>
                    {/* <Link
                    to={`/add_image/${addImage}`}
                    className={`btn ${styles.btn_add1}`}
                  >
                    Add Image
                  </Link> */}
                    <form onSubmit={handleSend} className={styles.form_padding}>
                      <div className="form-group d-block">
                        <div>
                          <label htmlFor="" className={styles.title_heading}>
                            Subject :{" "}
                          </label>
                          <input
                            type="text"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                            className="form-control bg-white rounded-0"
                          />
                        </div>
                        <div className={styles.product_tilte}>
                          <label htmlFor="" className={styles.title_heading}>
                            Message :{" "}
                          </label>
                          <CKEditor
                            editor={ClassicEditor}
                            data={message}
                            onChange={handleMessageOnchange}
                          />
                        </div>
                        <div className={styles.product_add_btn}>
                          <button className={`btn btn-success `}>Send</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position={"top-right"}
          autoClose={2000}
          pauseOnHover={false}
          closeOnClick={true}
        />
      </section>
    </>
  );
};

export default SendMailToCustomer;
