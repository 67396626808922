/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
// import "./Offer.css";
import style from "./style.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { GiKnightBanner } from "react-icons/gi";
import "../../Offers/Offer.css";
import {
  API_AUTH_PASSWORD,
  API_AUTH_USERNAME,
  API_BASE_URL,
} from "../../../Constant/Global";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import { ToastContainer, toast } from "react-toastify";
const AddPortfolio = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [status, setStatus] = useState("Y");
  const [validated, setValidated] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [catId, setCatId] = useState("");
  const navigate = useNavigate();

  const [errors, setErrors] = useState({
    name: "",
    location: "",
  });

  const validateForm = () => {
    let valid = true;
    const newErrors = {
      name: "",
      location: "",
    };

    // name validation
    if (name === "") {
      toast.error("Name is Require *");
      // newErrors.name = "Name is Require *";
      valid = false;
    } else if (!/^[A-Za-z\s.]+$/.test(name)) {
      toast.error("Name can only contain letters");
      valid = false;
    } else if (location === "") {
      toast.error("Location is Require *");
      // newErrors.name = "Name is Require *";
      valid = false;
    } else if (!/^[A-Za-z\s.]+$/.test(location)) {
      toast.error("Location can only contain letters");
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  useEffect(() => {
    const handleAdd = async () => {
      try {
        const response = await axios.get(
          API_BASE_URL + "admin/portfolio_category",
          {
            auth: {
              username: API_AUTH_USERNAME,
              password: API_AUTH_PASSWORD,
            },
          }
        );
        setCategoryData(response.data.data);
      } catch (error) {
        console.log("errror occured fetching data", error);
      }
    };
    handleAdd();
  }, []);

  // add slider

  const handleAddTestimonial = async (e) => {
    e.preventDefault();

    if (!validateForm() || isSubmitting) {
      return;
    }
    if (catId === "") {
      toast.error("category is required");
      return;
    }
    setIsSubmitting(true);
    const finalData = {
      category_id:catId,
      name: name,
      location:location,
      status: status,
    };
    try {
      const response = await axios.post(
        API_BASE_URL + "admin/add_portfolio",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success(response.data.message);
        setValidated(false);
        // setIsSubmitting(false);
        console.log("add portfolio data", response.data);
        let portfolio_id = response.data.data.id;
        setTimeout(() => {
          navigate(`/portfolio_tab/${portfolio_id}`);
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
      // setAllOrder(response.data.data);
    } catch (error) {
      console.log("errror occured fetching data", error);
    }
  };

  const handleStatus = (e) => {
    setStatus(e.target.value);
  };
  return (
    <>
      <Helmet>
        <title>Add Portfolio | Decorsaga Design</title>
      </Helmet>
      <section className="add_cat_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 d-flex justify-content-center align-items-center">
              <div className={`card ${style.edit_offer_card}`}>
                <div className="card-body">
                  <h5 className="add_c_t">
                    <GiKnightBanner style={{ marginTop: "-.2rem" }} /> Add
                    Portfolio
                  </h5>
                  <form onSubmit={handleAddTestimonial}>
                    <div className="row">
                      <div
                        className={`col-lg-3 ${style.edit_offer_label_column}`}
                      >
                        <div className="form-group">
                          <label htmlFor="">Name : *</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className={`${style.form_box}`}>
                          <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Name Title"
                            className={`form-control ${style.offer_input}`}
                          />
                          {errors.name && (
                            <p className={`${style.error_text}`}>
                              {errors.name}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={`col-lg-3 ${style.edit_offer_label_column}`}
                      >
                        <div className="form-group">
                          <label htmlFor="">Location:</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className={`${style.form_box}`}>
                          <input
                            type="text"
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                            placeholder="Location"
                            className={`form-control ${style.offer_input}`}
                          />
                          {errors.location && (
                            <p className={`${style.error_text}`}>
                              {errors.location}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={`col-lg-3 ${style.edit_offer_label_column}`}
                      >
                        <div className="form-group">
                          <label htmlFor="">Category:</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className={`${style.form_box}`}>
                          <select
                            name="parentCategory"
                            id="parentCat"
                            value={catId}
                            onChange={(e) => setCatId(e.target.value)}
                            className={`form-control ${style.offer_input}`}
                            style={{ appearance: "auto" }}
                          >
                            <option value="">Parent Category</option>
                            {categoryData.map((elem, index) => {
                              return (
                                <option key={index} value={elem.id}>
                                  {elem.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={`col-lg-3 ${style.edit_offer_label_column}`}
                      >
                        <div className="form-group">
                          <label htmlFor="">Select Status : *</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className={`${style.form_box}`}>
                          <div className="active-sec">
                            <input
                              type="radio"
                              name="status"
                              value="Y"
                              checked={status === "Y"}
                              onChange={handleStatus}
                              className="me-2"
                            />
                            <span>Active</span>
                          </div>
                          <div className="deactive-sec">
                            <input
                              type="radio"
                              name="status"
                              value="N"
                              checked={status === "N"}
                              onChange={handleStatus}
                              className="me-2"
                            />
                            <span>Deactive</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="Add_btn_group">
                      <button
                        disabled={isSubmitting}
                        type="submit"
                        className="btn add_to_cat"
                      >
                        {isSubmitting ? "Adding" : "Add"}
                      </button>
                    </div>
                    <div className="NoteBox">
                      <p>
                        Note :-{" "}
                        <span>
                          Please Add (430w x 445h) This Size Of Images
                        </span>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position={"top-right"}
          autoClose={2000}
          pauseOnHover={false}
          closeOnClick={true}
        />
      </section>
    </>
  );
};

export default AddPortfolio;
