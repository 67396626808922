import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  API_AUTH_PASSWORD,
  API_AUTH_USERNAME,
  API_BASE_URL,
} from "../../Constant/Global";
import { ToastContainer, toast } from "react-toastify";
import ContactsIcon from "@mui/icons-material/Contacts";
import styles from "../Products/EditAttribute/EditAttribute.module.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Helmet } from "react-helmet-async";

const CareInstruction = () => {
  const [productDec, setProductDec] = useState("");

  const handleProductDescription = (e, editor) => {
    const data = editor.getData();
    setProductDec(data);
  };

  useEffect(() => {
    const handleAddItem = async () => {
      try {
        const response = await axios.post(
          API_BASE_URL + "admin/product_care_instruction",
          null,
          {
            auth: {
              username: API_AUTH_USERNAME,
              password: API_AUTH_PASSWORD,
            },
          }
        );
        console.log("Response:", response.data);
        // Check if response.data.data is defined before accessing its properties
        if (response.data && response.data.data) {
          setProductDec(response.data.data.description || "");
        } else {
          console.log("No data returned from the API");
        }
      } catch (error) {
        console.log("Error occurred fetching data:", error);
      }
    };
    handleAddItem();
  }, []);

  const handleAddProduct = async (e) => {
    e.preventDefault();
    try {
      const data = {
        description: productDec,
      };
      console.log("data add", data);
      const response = await axios.post(
        API_BASE_URL + "admin/update_product_care_instruction",
        data,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("errror occured fetching data", error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Care & Instruction | Decorsaga Design</title>
      </Helmet>
      <section className="container-fluid mt-5">
        <div className="row">
          <div className="col-lg-12">
            <div className={styles.add_products}>
              <div className={styles.add_products_heading}>
                <div className={styles.main_heading}>
                  <ContactsIcon />{" "}
                  <h6 className={styles.heading_add}>Add Care & Instruction</h6>
                </div>
                <div className={styles.form_padding}>
                  <div>
                    {/* <Link
                    to={`/add_image/${addImage}`}
                    className={`btn ${styles.btn_add1}`}
                  >
                    Add Image
                  </Link> */}
                    <form
                      action=""
                      onSubmit={handleAddProduct}
                      className={styles.form_padding}
                    >
                      <div className="form-group d-block">
                        <div className={styles.product_tilte}>
                          <label htmlFor="" className={styles.title_heading}>
                            Product Warranty Description :{" "}
                          </label>
                          <CKEditor
                            editor={ClassicEditor}
                            data={productDec}
                            onChange={handleProductDescription}
                          />
                        </div>

                        <div className={styles.product_add_btn}>
                          <button className={`btn btn-success `}>Save</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position={"top-right"}
          autoClose={2000}
          pauseOnHover={false}
          closeOnClick={true}
        />
      </section>
    </>
  );
};

export default CareInstruction;
