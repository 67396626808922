import axios from "axios";
import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ContactsIcon from "@mui/icons-material/Contacts";
import styles from "../../Products/EditAttribute/EditAttribute.module.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  API_AUTH_PASSWORD,
  API_AUTH_USERNAME,
  API_BASE_URL,
} from "../../../Constant/Global";

const PortFolioDescription = () => {
  const [productDec, setProductDec] = useState("");
  const { id } = useParams();

  const handleProductDescription = (e, editor) => {
    const data = editor.getData();
    setProductDec(data);
  };
 

  useEffect(() => {
    const handleAddItem = async () => {
      try {
        const finalData = { portfolio_id: id };
        const response = await axios.post(
          API_BASE_URL + "admin/get_portfolio_info",
          finalData,
          {
            auth: {
              username: API_AUTH_USERNAME,
              password: API_AUTH_PASSWORD,
            },
          }
        );
        console.log("respons portfolio info", response.data);
        if (response.data && response.data.data) {
          setProductDec(response.data.data.info || "");
        } else {
          console.log("No data returned from the API");
        }
      } catch (error) {
        console.log("error occured fetching data", error);
      }
    };
    handleAddItem();
  }, []);

  const handleAddProduct = async (e) => {
    e.preventDefault();
    try {
      const data = {
        portfolio_id: id,
        info: productDec,
      };
      const response = await axios.post(
        API_BASE_URL + "admin/update_portfolio_info",
        data,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("errror occured fetching data", error);
    }
  };

  return (
    <>
      <section className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col_custom">
            <div className={styles.add_products}>
              <div className={styles.add_products_heading}>
                <div className={styles.main_heading}>
                  <ContactsIcon />{" "}
                  <h6 className={styles.heading_add}>Project Information</h6>
                </div>
                <div className={styles.form_padding}>
                  <div>
                    <form
                      action=""
                      onSubmit={handleAddProduct}
                      className={styles.form_padding}
                    >
                      <div className="form-group d-block">
                        <div className={styles.product_tilte}>
                          <label htmlFor="" className={styles.title_heading}>
                            Portfolio description :{" "}
                          </label>
                          <CKEditor
                            editor={ClassicEditor}
                            data={productDec}
                            onChange={handleProductDescription}
                          />
                        </div>

                        <div className={styles.product_add_btn}>
                          <button className={`btn btn-success `}>Save</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PortFolioDescription;
