/* eslint-disable react/jsx-pascal-case */
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import EditAttribute from "../EditAttribute/EditAttribute";
import AddImageList from "../AddImage/AddImageList";
import Edit_Products from "../EditProducts/Edit_Products";
import Product_Info from "../ProductInfo/Product_Info";
import "./style.css";
import { ToastContainer, toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import "react-toastify/dist/ReactToastify.css";
import AddVariant from "../AddVariant/AddVariant";
import SEO from "../seo/SEO";
// import AddRelatedProduct from "../AddRelatedProduct/AddRelatedProduct";

const ProductTab = () => {
  return (
    <>
      <Helmet>
        <title>Update Product | Decorsaga Design</title>
      </Helmet>
      <Tab.Container id="product-tabs" defaultActiveKey="first">
        <Row className="product-tabs-row">
          {/* <Col sm={2} className="product-tabs-col-3">
          
        </Col> */}
          <Col sm={12} className="product-tabs-12">
            <Nav variant="pills" className="nav-pills-side-tab">
              <Nav.Item className="tabs_nav_links">
                <Nav.Link className="tabs_links" eventKey="first">
                  Data
                  {/* <FaArrowRightLong className="ms-2" /> */}
                </Nav.Link>
              </Nav.Item>
              {/* <Nav.Item className="tabs_nav_links">
                <Nav.Link className="tabs_links" eventKey="third">
                  Attributes
                </Nav.Link>
              </Nav.Item> */}
              <Nav.Item className="tabs_nav_links">
                <Nav.Link className="tabs_links" eventKey="fifth">
                  Product Info
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="tabs_nav_links">
                <Nav.Link className="tabs_links" eventKey="fourth">
                  Images
                  {/* <FaArrowRightLong className="ms-2" /> */}
                </Nav.Link>
              </Nav.Item>
              {/* <Nav.Item className="tabs_nav_links">
                <Nav.Link className="tabs_links" eventKey="second">
                  Variant
                </Nav.Link>
              </Nav.Item> */}
              {/* <Nav.Item className="tabs_nav_links">
              <Nav.Link className="tabs_links" eventKey="Fifth">
                Related Product
                
              </Nav.Link>
            </Nav.Item> */}

              <Nav.Item className="tabs_nav_links">
                <Nav.Link className="tabs_links" eventKey="sixth">
                  SEO
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <Edit_Products toast={toast} />
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <AddVariant toast={toast} />
              </Tab.Pane>
              <Tab.Pane eventKey="third">
                <EditAttribute toast={toast} />
              </Tab.Pane>
              <Tab.Pane eventKey="fourth">
                <AddImageList toast={toast} />
              </Tab.Pane>
              {/* <Tab.Pane eventKey="Fifth">
              <AddRelatedProduct toast={toast} />
            </Tab.Pane> */}
              <Tab.Pane eventKey="fifth">
                <Product_Info toast={toast} />
              </Tab.Pane>
              <Tab.Pane eventKey="sixth">
                <SEO toast={toast} />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
        <ToastContainer
          position={"top-right"}
          autoClose={2000}
          pauseOnHover={false}
          closeOnClick={true}
        />
      </Tab.Container>
    </>
  );
};

export default ProductTab;
