import React, { useEffect, useState } from "react";
import "../AllCategories/AddCategories/AddCategories";
import style from "../AllCategories/EditCategories/EditCategories.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { MdCategory } from "react-icons/md";
import { Helmet } from "react-helmet-async";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  API_BASE_URL,
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
} from "../../Constant/Global";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../Offers/Offer.css";
const EditTeam = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImage1, setSelectedImage1] = useState(null);
  const [name, setName] = useState("");
  const [designation, setDesignation] = useState("");
  const [aboutUs, setAboutUs] = useState("");
  const [status, setStatus] = useState("Y");
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate("");
  const {id} = useParams();

  const handleStatus = (e) => {
    setStatus(e.target.value);
  };

  const handleAboutDescription = (e, editor) => {
    const data = editor.getData();
    setAboutUs(data);
  };

  // Function to handle file input change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const isNumeric = (value) => {
    return /^[A-Za-z ]+$/.test(value);
  };


  const onScrollTop =()=>{
    window.scrollTo(0,0)
  }
  const validateForm = () => {
    let valid = true;
    if (aboutUs === "") {
      toast.error("about us is required");
      valid = false;
    }
    // else if (selectedImage === null) {
    //   toast.error("image is required");
    //   valid = false;
    // }
    return valid;
  };

  // add category api

  const handleEditTeam = async (e) => {
    e.preventDefault();

    if (!isNumeric(name) || !isNumeric(designation)  || !validateForm() || isSubmitting) {
      setValidated(true);
      onScrollTop();
      return;
    }
    setIsSubmitting(true);
    const finalData = {
      team_id:id,
      name: name,
      designation: designation,
      about: aboutUs,
      status: status,
    };
    if (selectedImage) {
        finalData.image = selectedImage;
      }
      console.log("update data",finalData);
    try {
      const response = await axios.post(
        API_BASE_URL + "admin/update_team",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success(response.data.message);
        setValidated(false);
        setIsSubmitting(false);
        setTimeout(() => {
          navigate("/about");
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
      // setAllOrder(response.data.data);
    } catch (error) {
      console.log("errror occured fetching data", error);
    }
  };

   // edit category details api

   useEffect(() => {
    const handleTeamDetails = async () => {
      const finalData = {
        team_id: id,
      };
      try {
        const response = await axios.post(
          API_BASE_URL + "admin/team_details",
          finalData,
          {
            auth: {
              username: API_AUTH_USERNAME,
              password: API_AUTH_PASSWORD,
            },
          }
        );
        // setEditDetails(response.data.data);
        setName(response.data.data.name);
        setDesignation(response.data.data.designation);
        setAboutUs(response.data.data.about);
        // Check if the image is available in the response
        if (response.data.data.image) {
          setSelectedImage1(response.data.data.image);
        }
        console.log("edit id wise team details data", response.data.data);
        setStatus(response.data.data.status);
      } catch (error) {
        console.log("errror occured fetching data", error);
      }
    };
    handleTeamDetails();
  }, []);

  // Function to handle image removal
  const handleImageRemove = () => {
    setSelectedImage(null);
  };
  return (
    <>
      <Helmet>
        <title>Edit Team | Decorsaga Design</title>
      </Helmet>
      <section className="add_cat_main mt-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 d-flex justify-content-center align-items-center">
              <div className={`card ${style.edit_cat_card}`}>
                <div className="card-body">
                  <h5 className="add_c_t">
                    <MdCategory /> Edit Our Team
                  </h5>
                  <form onSubmit={handleEditTeam}>
                    <div className="row">
                      <div
                        className={`col-lg-4 ${style.edit_cat_label_column}`}
                      >
                        <div className="form-group">
                          <label
                            htmlFor=""
                            className={`${style.edit_cat_common_label}`}
                          >
                            Name *
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group">
                          <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder={
                              validated && !isNumeric(name)
                                ? "name is required"
                                : ""
                            }
                            className={`form-control ${
                              style.edit_c_cat_input
                            }   ${
                              validated && !isNumeric(name)
                                ? `is-invalid ${style.edit_c_cat_input_invalid}`
                                : ""
                            }`}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={`col-lg-4 ${style.edit_cat_label_column}`}
                      >
                        <div className="form-group">
                          <label
                            htmlFor=""
                            className={`${style.edit_cat_common_label}`}
                          >
                            Designation *
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group">
                          <input
                            type="text"
                            value={designation}
                            onChange={(e) =>
                              setDesignation(e.target.value)
                            }
                            placeholder={
                              validated && !isNumeric(designation)
                                ? "designation is required"
                                : ""
                            }
                            className={`form-control ${
                              style.edit_c_cat_input
                            }   ${
                              validated && !isNumeric(designation)
                                ? `is-invalid ${style.edit_c_cat_input_invalid}`
                                : ""
                            }`}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={`col-lg-4 ${style.edit_cat_label_column}`}
                      >
                        <div className="form-group">
                          <label
                            htmlFor=""
                            className={`${style.edit_cat_common_label}`}
                          >
                            About Us *
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className={`form-group blog_desc`}>
                          <CKEditor
                            editor={ClassicEditor}
                            data={aboutUs}
                            onChange={handleAboutDescription}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={`col-lg-4 ${style.edit_cat_label_column}`}
                      >
                        <div className="form-group">
                          <label
                            htmlFor=""
                            className={`${style.edit_cat_common_label}`}
                          >
                            Select Image *
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group">
                        {selectedImage1 && !selectedImage && (
                            <div className={`${style.selected_img_group}`}>
                              <img
                                src={selectedImage1}
                                alt="Selected"
                                className="img-fluid"
                              />
                              <button
                                onClick={handleImageRemove}
                                className={`btn ${style.remove_btn}`}
                              >
                                Remove
                              </button>
                            </div>
                          )}
                          {selectedImage && !selectedImage1 && (
                            <div className={`${style.selected_img_group}`}>
                              <img
                                src={selectedImage}
                                alt="Selected"
                                className="img-fluid"
                              />
                              <button
                                onClick={handleImageRemove}
                                className={`btn ${style.remove_btn}`}
                              >
                                Remove
                              </button>
                            </div>
                          )}
                          {!selectedImage1 && !selectedImage && (
                            <input
                              type="file"
                              accept="image/*"
                              onChange={handleImageChange}
                              className={`form-control ${style.edit_c_cat_input}`}
                            />
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div
                        className={`col-lg-4 ${style.edit_cat_label_column}`}
                      >
                        <div className="form-group">
                          <label
                            htmlFor=""
                            className={`${style.edit_cat_common_label}`}
                          >
                            Status
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group">
                          <div className={`${style.status_group_main}`}>
                            <div className="status-group">
                              <div className="active-sec">
                                <input
                                  type="radio"
                                  name="status"
                                  value="Y"
                                  checked={status === "Y"}
                                  onChange={handleStatus}
                                  className="me-2"
                                />
                                <span>Active</span>
                              </div>
                              <div className="deactive-sec">
                                <input
                                  type="radio"
                                  name="status"
                                  value="N"
                                  checked={status === "N"}
                                  onChange={handleStatus}
                                  className="me-2"
                                />
                                <span>Deactive</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="Add_btn_group">
                      <button
                        disabled={isSubmitting}
                        className="btn add_to_cat"
                      >
                        {isSubmitting ? "Saving" : "Save"}
                      </button>
                    </div>
                    <div className="NoteBox">
                      <p>
                        Note :-{" "}
                        <span>Please Add (200 x 200) This Size Of Images</span>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position={"top-right"}
          autoClose={2000}
          pauseOnHover={false}
          closeOnClick={true}
        />
      </section>
    </>
  );
};

export default EditTeam;
