/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
// import "./TodayOrder.css";
import style from "../MainSlider/style.module.css";
import { FaClipboardList } from "react-icons/fa";
import SearchIcon from "@mui/icons-material/Search";
import Pagination from "@mui/material/Pagination";
import { Helmet } from "react-helmet-async";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import "../../Home/Home.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  API_AUTH_PASSWORD,
  API_AUTH_USERNAME,
  API_BASE_URL,
} from "../../../Constant/Global";
import { ToastContainer, toast } from "react-toastify";
const CategoryBanner = () => {
  //   const [offerListData, setOfferListData] = useState(OfferList);
  const navigate = useNavigate("");
  const [isImage1Visible, setImage1Visible] = useState({});
  const [sliderData, setSliderData] = useState([]);
  const [sorting, setSorting] = useState({
    column: "sale_id",
    ascending: true,
  });
  const [search, setSearch] = useState("");
  const ArrowBackIcon = () => <span>Previous</span>;
  const ArrowForwardIcon = () => <span>Next</span>;
  const handleVisible = () => {
    setImage1Visible(!isImage1Visible);
  };
  // const handleVisible = (key) => {
  //   setImageVisible((prevState) => ({
  //     ...prevState,
  //     [key]: !prevState[key],
  //   }));
  // };

  // slider data
  useEffect(() => {
    const handlePageSlider = async () => {
      try {
        const response = await axios.get(
          API_BASE_URL + "admin/feature_banner",
          {
            auth: {
              username: API_AUTH_USERNAME,
              password: API_AUTH_PASSWORD,
            },
          }
        );
        console.log("category banner data", response.data.data);
        setSliderData(response.data.data);
      } catch (error) {
        console.log("error occured fetching data", error);
      }
    };
    handlePageSlider();
  }, []);

  const handleEdit = (id) => {
    navigate(`/edit_category_banner/${id}`);
  };

  const handleAddBanner = () => {
    navigate("/add_category_banner");
  };

  // filtered the data
  const filtered = sliderData.filter((data) =>
    data.slider_title.toLowerCase().includes(search.toLowerCase())
  );
  // delete slider
  const handleDelete = async (id) => {
    var check = window.confirm("Are You Sure Want To Delete Banner ?");
    if (!check) {
      return;
    }
    const finalData = {
      slider_id: id,
    };
    try {
      const response = await axios.post(
        API_BASE_URL + "admin/delete_feature_banner",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success(response.data.message);
        const updateProducts = sliderData.filter((item) => item.id !== id);
        setSliderData(updateProducts);
      } else {
        toast.error(response.data.message);
      }
      // setAllOrder(response.data.data);
    } catch (error) {
      console.log("errror occured fetching data", error);
    }
  };

  const handleSort = (columnName) => {
    if (columnName === sorting.column) {
      setSorting({ ...sorting, ascending: !sorting.ascending });
    } else {
      setSorting({ column: columnName, ascending: true });
    }

    // Sort the data
    const isAscending = sorting.column === columnName && sorting.ascending;
    const sortedData = [...sliderData].sort((a, b) => {
      if (columnName === "order_date") {
        // Sort based on the initialRegistration date
        const dateA = a.on_date ? a.on_date.split("/").reverse().join("") : "";
        const dateB = b.on_date ? b.on_date.split("/").reverse().join("") : "";

        return dateA.localeCompare(dateB) * (sorting.ascending ? 1 : -1);
      } else {
        // Apply your existing logic for other columns
        const aValue = (a[columnName] || "").toLowerCase();
        const bValue = (b[columnName] || "").toLowerCase();

        return isAscending
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }
    });

    setSliderData(sortedData);
  };

  const renderArrow = (columnName) => {
    if (sorting.column === columnName) {
      return sorting.ascending ? (
        <img
          src={require("../../../component/assets/images/tables-icon/icon_sorting1.png")}
          className="img-fluid"
          alt=""
        />
      ) : (
        <img
          src={require("../../../component/assets/images/tables-icon/icon_sorting2.png")}
          className="img-fluid"
          alt=""
        />
      );
    }
    return (
      <>
        <img
          src={require("../../../component/assets/images/tables-icon/icon_sorting2.png")}
          className="img-fluid"
          alt=""
        />
      </>
    ); // Display the up arrow by default
  };

  return (
    <>
      {/* <Helmet>
        <title>Category Banner | Decorsaga Design</title>
      </Helmet> */}
      <section className="today_order_main">
        <div className="container-fluid">
          <div className="row  px-2">
            <div className="col-lg-12">
              <div className="today_order_table">
                <div className="table_body_order">
                  <div className="row">
                    <div className="col-lg-7 d-flex align-items-center">
                      <div className="cart_heading align-items-center">
                        <h6 className="today_heading">
                          {" "}
                          <FaClipboardList style={{ marginTop: "-5px" }} />{" "}
                          Products Page Bottom Banner
                        </h6>
                        <div className="show_entreis">
                          {sliderData.length > 0 ? sliderData.length : "0"}
                        </div>
                        <h6 className="show_entry_heading">Show entries</h6>
                        <span
                          className="text-danger ms-3"
                          style={{ fontSize: "14px" }}
                        >
                          Only Two Banner Are Allow !
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-5 pe-4 d-flex justify-content-end align-items-center">
                      <div className="today_order_Search">
                        <input
                          type="search"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                          className="form-control search_input1"
                          placeholder="Search"
                        />
                        {search ? "" : <SearchIcon className="search_icon1" />}
                      </div>
                      {/* <div className={`${style.add_banner_sec}`}>
                      <button
                        onClick={handleAddBanner}
                        className={` btn ${style.add_banner_btn}`}
                      >
                        Add
                      </button>
                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 py-1">
              <div className="card p-0 pb-4 home_table_card">
                <div className="card-body p-0">
                  <div className="table-responsive">
                    <table className="table home_table_main table-striped">
                      <thead>
                        <tr className="main_table_head">
                          <th scope="col" onClick={() => handleSort("id")}>
                            Slider Id
                            {renderArrow("coupon_name")}
                          </th>
                          <th
                            scope="col"
                            onClick={() => handleSort("slider_title")}
                          >
                            Slider Title
                            {renderArrow("slider_title")}
                          </th>
                          <th
                            scope="col"
                            onClick={() => handleSort("slider_url")}
                          >
                            Slider Url
                            {renderArrow("slider_url")}
                          </th>
                          <th
                            scope="col"
                            onClick={() => handleSort("slider_image")}
                          >
                            Image
                            {renderArrow("slider_image")}
                          </th>
                          <th
                            scope="col"
                            onClick={() => handleSort("slider_status")}
                          >
                            Status
                            {renderArrow("slider_status")}
                          </th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody className="main_body_table">
                        {filtered.map((ele, index) => {
                          return (
                            <tr>
                              <th scope="row">{index + 1}</th>
                              <td>{ele.slider_title}</td>
                              <td className={style.slider_eclipse}>
                                {ele.slider_url}
                              </td>
                              <td>
                                <Link to={ele.slider_image} target="_blank">
                                  {" "}
                                  <img
                                    src={ele.slider_image}
                                    className="img-fluid"
                                    height={50}
                                    width={50}
                                    alt="offer-img"
                                  />{" "}
                                </Link>
                              </td>
                              <td>
                                {ele.slider_status === "1" ? (
                                  <span className="text-success">Active</span>
                                ) : (
                                  <span className="text-danger">InActive</span>
                                )}
                              </td>
                              <td className="d-flex justify-content-center align-items-center">
                                <div
                                  className="dispatch-table"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="bottom"
                                  title="edit"
                                  onClick={() => handleEdit(ele.id)}
                                >
                                  <img
                                    src={require("../../../component/assets/images/edit-icon.png")}
                                    className="img-fluid me-2"
                                    alt="edit"
                                    width={22}
                                  />
                                </div>
                                {/* <div
                                className="order-assign-table"
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                title="Cancel"
                                onClick={() => handleDelete(ele.id)}
                              >
                                <img
                                  src={require("../../../component/assets/images/cancel.png")}
                                  className="img-fluid"
                                  alt="cancel"
                                  width={22}
                                />
                              </div> */}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="pagination_table">
                    <Stack spacing={2}>
                      <Pagination
                        count={1}
                        renderItem={(item) => (
                          <PaginationItem
                            slots={{
                              previous: ArrowBackIcon,
                              next: ArrowForwardIcon,
                            }}
                            {...item}
                          />
                        )}
                      />
                    </Stack>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position={"top-right"}
          autoClose={2000}
          pauseOnHover={false}
          closeOnClick={true}
        />
      </section>
    </>
  );
};

export default CategoryBanner;
