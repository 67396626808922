/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
// import "./Offer.css";
import style from "../MainSlider/style.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { GiKnightBanner } from "react-icons/gi";
import "../../Offers/Offer.css";
import {
  API_AUTH_PASSWORD,
  API_AUTH_USERNAME,
  API_BASE_URL,
} from "../../../Constant/Global";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Helmet } from "react-helmet-async";

const AddCategoryBanner = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [sliderTitle, setSliderTitle] = useState("");
  const [sliderUrl, setSliderUrl] = useState("");

  const [status, setStatus] = useState("1");
  const [validated, setValidated] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  // Function to handle file input change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Function to handle image removal
  const handleImageRemove = () => {
    setSelectedImage(null);
  };

  const isNumeric = (value) => {
    return value !== "";
  };

  // add slider

  const handleAddSlider = async (e) => {
    e.preventDefault();
    if (!isNumeric(sliderTitle) || !isNumeric(sliderUrl)) {
      setValidated(true);
      return;
    }
    const finalData = {
      title: sliderTitle,
      slider_url: sliderUrl,
      image: selectedImage,
      status: status,
    };
    console.log("finalData slider", finalData);
    try {
      const response = await axios.post(
        API_BASE_URL + "admin/add_feature_banner",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success(response.data.message);
        setValidated(false);
        setTimeout(() => {
          navigate("/category_banner");
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
      // setAllOrder(response.data.data);
    } catch (error) {
      console.log("errror occured fetching data", error);
    }
  };

  const handleStatus = (e) => {
    setStatus(e.target.value);
  };
  return (
    <>
      <Helmet>
        <title>Add Category Banner | Decorsaga Design</title>
      </Helmet>
      <section className="add_cat_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 d-flex justify-content-center align-items-center">
              <div className={`card ${style.edit_offer_card}`}>
                <div className="card-body">
                  <h5 className="add_c_t">
                    <GiKnightBanner style={{ marginTop: "-.2rem" }} /> Add
                    Banner
                  </h5>
                  <form onSubmit={handleAddSlider}>
                    <div className="row">
                      <div
                        className={`col-lg-3 ${style.edit_offer_label_column}`}
                      >
                        <div className="form-group">
                          <label htmlFor="">Slider Title : *</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="form-group">
                          <input
                            type="text"
                            value={sliderTitle}
                            onChange={(e) => setSliderTitle(e.target.value)}
                            placeholder={
                              validated && !isNumeric(sliderTitle)
                                ? "Slider Title is require"
                                : "Slider Title"
                            }
                            className={`form-control ${style.offer_input}   ${
                              validated && !isNumeric(sliderTitle)
                                ? `is-invalid ${style.edit_c_cat_input_invalid}`
                                : ""
                            }`}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={`col-lg-3 ${style.edit_offer_label_column}`}
                      >
                        <div className="form-group">
                          <label htmlFor="">Slider Url:</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="form-group">
                          <input
                            type="text"
                            value={sliderUrl}
                            onChange={(e) => setSliderUrl(e.target.value)}
                            placeholder={
                              validated && !isNumeric(sliderUrl)
                                ? "Slider Url is require"
                                : "Slider Url"
                            }
                            className={`form-control ${style.offer_input}   ${
                              validated && !isNumeric(sliderUrl)
                                ? `is-invalid ${style.edit_c_cat_input_invalid}`
                                : ""
                            }`}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={`col-lg-3 ${style.edit_offer_label_column}`}
                      >
                        <div className="form-group">
                          <label htmlFor="">Image : *</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="form-group">
                          {selectedImage ? (
                            <div className="selected_img_group">
                              <img
                                src={selectedImage}
                                alt="Selected"
                                className="img-fluid"
                              />
                              <button
                                onClick={handleImageRemove}
                                className={`btn ${style.remove_btn}`}
                              >
                                Remove
                              </button>
                            </div>
                          ) : (
                            <>
                              <input
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                                className={`form-control ${style.offer_input}`}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={`col-lg-3 ${style.edit_offer_label_column}`}
                      >
                        <div className="form-group">
                          <label htmlFor="">Select Status : *</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="form-group d-block">
                          <div className="active-sec">
                            <input
                              type="radio"
                              name="status"
                              value="1"
                              checked={status === "1"}
                              onChange={handleStatus}
                              className="me-2"
                            />
                            <span>Active</span>
                          </div>
                          <div className="deactive-sec">
                            <input
                              type="radio"
                              name="status"
                              value="0"
                              checked={status === "0"}
                              onChange={handleStatus}
                              className="me-2"
                            />
                            <span>Deactive</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="row">
                    <div
                      className={`col-lg-3 ${style.edit_offer_label_column}`}
                    >
                      <div className="form-group">
                        <label htmlFor="">Sub Category : *</label>
                      </div>
                    </div>
                    <div className="col-lg-9">
                      <div className="form-group">
                        <select
                          name=""
                          id=""
                          className={`form-control ${style.offer_input}`}
                        >
                          <option value="">Sub Category</option>
                        </select>
                      </div>
                    </div>
                  </div> */}
                    <div className="Add_btn_group">
                      <button type="submit" className="btn add_to_cat">
                        Add Banner
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position={"top-right"}
          autoClose={2000}
          pauseOnHover={false}
          closeOnClick={true}
        />
      </section>
    </>
  );
};

export default AddCategoryBanner;
