import React, { useEffect, useState } from "react";
import styles from "../Products/EditAttribute/EditAttribute.module.css";
import ContactsIcon from "@mui/icons-material/Contacts";
import same from "../Products/AddImage/Add_Image.module.css";
import { Helmet } from "react-helmet-async";
import { ToastContainer, toast } from "react-toastify";
import "../Home/Home.css";
import styled from "../Products/AddImage/ImageGrid.module.css";
import axios from "axios";
// import { BsTrash3 } from "react-icons/bs";
import {
  API_BASE_URL,
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
} from "../../Constant/Global.js";
const HomePopUpImg = () => {
  const [product, setProduct] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImage1, setSelectedImage1] = useState(null);
  const [beforeLoginUrl, setBeforeLoginUrl] = useState("");
  const [selectedImage2, setSelectedImage2] = useState(null);
  const [selectedImageTwo, setSelectedImageTwo] = useState(null);
  const [afterLoginUrl, setAfterLoginUrl] = useState("");
  const [error, setError] = useState(null);

  const handleAddItem = async () => {
    try {
      const response = await axios.get(
        API_BASE_URL + "admin/get_home_popup_image",
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      //   console.log("get home popup image response", response.data.data);
      setProduct(response.data.data);
      setBeforeLoginUrl(response.data.data.before_login_image_url);
      setAfterLoginUrl(response.data.data.after_login_image_url);
    } catch (error) {
      console.log("error occured fetching data", error);
    }
  };

  useEffect(() => {
    handleAddItem();
  }, []);

  const handleRefresh = async () => {
    await handleAddItem();
  };

  const handleAddProduct = async (e) => {
    e.preventDefault();
    try {
      const data = {
        // before_login_image: selectedImage,
        // after_login_image: selectedImage2,
        before_login_image_url: beforeLoginUrl,
        after_login_image_url: afterLoginUrl,
      };
      // Conditionally include the image property
      if (selectedImage) {
        data.before_login_image = selectedImage;
      }
      if (selectedImage2) {
        data.after_login_image = selectedImage2;
      }
      console.log("finalData", data);
      const response = await axios.post(
        API_BASE_URL + "admin/home_popup_image_update",
        data,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success(response.data.message);
        handleRefresh();
        const newImage = response.data.data; // Assuming the API response contains the newly added image data
        setProduct(newImage); // Update local state with the new image
        setSelectedImage("");
        setSelectedImage2("");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("errror occured fetching data", error);
    }
  };

  //   Delete product image api

  // const handleDelete = async (id) => {
  //   const confirm = window.confirm("Are you sure want to delete these image");
  //   if (!confirm) {
  //     return;
  //   }
  //   try {
  //     const finalData = { image_id: id };
  //     const response = await axios.post(
  //       API_BASE_URL + "admin/delete_product_image",
  //       finalData,
  //       {
  //         auth: {
  //           username: API_AUTH_USERNAME,
  //           password: API_AUTH_PASSWORD,
  //         },
  //       }
  //     );
  //     toast.success(response.data.message);
  //     const updatedImage = product.filter((item) => item.id !== id);
  //     setProduct(updatedImage);
  //   } catch (error) {
  //     console.log("error occuring delete iamge");
  //   }
  // };

  // image

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (file.type.startsWith("image/")) {
        const reader = new FileReader();

        reader.onload = function (e) {
          setSelectedImage(e.target.result);
          setError(null); // Clear any previous error
        };

        reader.readAsDataURL(file);
      } else {
        setError("Selected file is not an image.");
      }
    }
  };

  const handleImageRemove = () => {
    setSelectedImage(null);
    setSelectedImage1(null);
    setError(null); // Clear any previous error
  };

  const handleImageChange2 = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (file.type.startsWith("image/")) {
        const reader = new FileReader();

        reader.onload = function (e) {
          setSelectedImage2(e.target.result);
          setError(null); // Clear any previous error
        };

        reader.readAsDataURL(file);
      } else {
        setError("Selected file is not an image.");
      }
    }
  };

  const handleImageRemove2 = () => {
    setSelectedImage2(null);
    setSelectedImageTwo(null);
    setError(null); // Clear any previous error
  };

  return (
    <>
      {/* <Helmet>
        <title> Home Pop Up Images | Decorsaga Design</title>
      </Helmet> */}
      <section className="container-fluid mt-5">
        <div className="row">
          <div className="col-lg-12">
            <div className={styles.add_products}>
              <div
                className={styles.add_products_heading}
                style={{ margin: "0 10px" }}
              >
                <div
                  className={styles.main_heading}
                  style={{ background: "#fff", color: "#282828" }}
                >
                  <ContactsIcon />{" "}
                  <h6 className={styles.heading_add}>Home Pop Up Images</h6>
                </div>
                <div className={`${styles.form_padding} bg-white`}>
                  <div>
                    {/* <Link
                      to={`/add_image/${addImage}`}
                      className={`btn ${styles.btn_add1}`}
                    >
                      Add Image
                    </Link> */}
                    <form
                      action=""
                      onSubmit={handleAddProduct}
                      className={styles.form_padding}
                    >
                      <div className="row">
                        <div className="col-lg-6">
                          <h6 className="h_pop_up_main_title">Before Login</h6>
                          <div className="home_pop_up_form_g">
                            {/* before login images */}
                            {/* {selectedImage ? (
                              <div className={styles.product_tilte}>
                                <label
                                  htmlFor=""
                                  className={`mb-2 ${styles.title_heading}`}
                                >
                                  Before Login Image :{" "}
                                </label>
                                <div className={styles.select_img_sec}>
                                  <img
                                    src={selectedImage}
                                    alt="Selected"
                                    className="img-fluid"
                                  />
                                </div>
                                <button
                                  onClick={handleImageRemove}
                                  className={`btn mt-3 ${same.rem_btn}`}
                                >
                                  Remove
                                </button>
                              </div>
                            ) : (
                              <div className={styles.product_tilte}>
                                <label
                                  htmlFor=""
                                  className={styles.title_heading}
                                >
                                  Before Login Image :{" "}
                                </label>
                                <input
                                  type="file"
                                  accept="image/*"
                                  onChange={handleImageChange}
                                  className="form-control w-100"
                                />
                              </div>
                            )} */}

                            {selectedImage1 && !selectedImage && (
                              <div className={styles.product_tilte}>
                                <label
                                  htmlFor=""
                                  className={`mb-2 ${styles.title_heading}`}
                                >
                                  Image :{" "}
                                </label>
                                <br />
                                <img
                                  src={selectedImage1}
                                  alt="Selected"
                                  className="img-fluid"
                                />
                                <br />
                                <button
                                  onClick={handleImageRemove}
                                  className={`btn mt-3 ${same.rem_btn}`}
                                >
                                  Remove
                                </button>
                              </div>
                            )}
                            {selectedImage && !selectedImage1 && (
                              <div className={styles.product_tilte}>
                                <label
                                  htmlFor=""
                                  className={`mb-2 ${styles.title_heading}`}
                                >
                                  Image :{" "}
                                </label>
                                <br />
                                <img
                                  src={selectedImage}
                                  alt="Selected"
                                  className="img-fluid"
                                />
                                <br />
                                <button
                                  onClick={handleImageRemove}
                                  className={`btn mt-3 ${same.rem_btn}`}
                                >
                                  Remove
                                </button>
                              </div>
                            )}
                            {!selectedImage1 && !selectedImage && (
                              <div className={styles.product_tilte}>
                                <label
                                  htmlFor=""
                                  className={`mb-2 ${styles.title_heading}`}
                                >
                                  Image :{" "}
                                </label>
                                <br />
                                <input
                                  type="file"
                                  accept="image/*"
                                  onChange={handleImageChange}
                                  className="form-control w-100"
                                />
                              </div>
                            )}
                            <div>
                              <p className="text-danger mb-0">
                                (Note: Please Add (498w x 398h) This Size Of
                                Images)
                              </p>
                            </div>

                            <div className={styles.product_tilte}>
                              <label
                                htmlFor=""
                                className={styles.title_heading}
                              >
                                Redirect Url :{" "}
                              </label>
                              <input
                                type="text"
                                value={beforeLoginUrl}
                                onChange={(e) =>
                                  setBeforeLoginUrl(e.target.value)
                                }
                                className="form-control w-100"
                              />
                            </div>
                            {product && Object.keys(product).length > 0 ? (
                              <div
                                className="h_pop_up_img_box"
                                key={product.id}
                              >
                                <img
                                  src={product.before_login_image}
                                  className={`img-fluid`}
                                  alt="Product"
                                />
                                {/* <p>{product.before_login_image_url}</p> */}
                              </div>
                            ) : (
                              <p className="ms-5 text-danger">
                                No coupon banner available
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <h6 className="h_pop_up_main_title">After Login</h6>
                          <div className="home_pop_up_form_g">
                            {/* after login image */}
                            {selectedImageTwo && !selectedImage2 && (
                              <div className={styles.product_tilte}>
                                <label
                                  htmlFor=""
                                  className={`mb-2 ${styles.title_heading}`}
                                >
                                  Image :{" "}
                                </label>
                                <br />
                                <img
                                  src={selectedImage2}
                                  alt="Selected"
                                  className="img-fluid"
                                />
                                <br />
                                <button
                                  onClick={handleImageRemove2}
                                  className={`btn mt-3 ${same.rem_btn}`}
                                >
                                  Remove
                                </button>
                              </div>
                            )}
                            {selectedImage2 && !selectedImageTwo && (
                              <div className={styles.product_tilte}>
                                <label
                                  htmlFor=""
                                  className={`mb-2 ${styles.title_heading}`}
                                >
                                  Image :{" "}
                                </label>
                                <br />
                                <img
                                  src={selectedImage2}
                                  alt="Selected"
                                  className="img-fluid"
                                />
                                <br />
                                <button
                                  onClick={handleImageRemove2}
                                  className={`btn mt-3 ${same.rem_btn}`}
                                >
                                  Remove
                                </button>
                              </div>
                            )}
                            {!selectedImageTwo && !selectedImage2 && (
                              <div className={styles.product_tilte}>
                                <label
                                  htmlFor=""
                                  className={`mb-2 ${styles.title_heading}`}
                                >
                                  Image :{" "}
                                </label>
                                <br />
                                <input
                                  type="file"
                                  accept="image/*"
                                  onChange={handleImageChange2}
                                  className="form-control w-100"
                                />
                              </div>
                            )}
                            <div>
                              <p className="text-danger mb-0">
                                (Note: Please Add (498w x 398h) This Size Of
                                Images)
                              </p>
                            </div>
                            <div className={styles.product_tilte}>
                              <label
                                htmlFor=""
                                className={styles.title_heading}
                              >
                                Redirect Url :{" "}
                              </label>
                              <input
                                type="text"
                                value={afterLoginUrl}
                                onChange={(e) =>
                                  setAfterLoginUrl(e.target.value)
                                }
                                className="form-control w-100"
                              />
                            </div>
                            {product && Object.keys(product).length > 0 ? (
                              <div
                                className="h_pop_up_img_box"
                                key={product.id}
                              >
                                <img
                                  src={product.after_login_image}
                                  className={`img-fluid`}
                                  alt="Product"
                                />
                                {/* <p>{product.after_login_image_url}</p> */}
                              </div>
                            ) : (
                              <p className="ms-5 text-danger">
                                No coupon banner available
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-12 text-center">
                          <div className={styles.product_add_btn}>
                            <button className={`btn btn-success `}>Save</button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  {/* <div className="row">
                    <div className={`col-lg-6 ${styled.container}`}></div>
                    <div className={`col-lg-6 ${styled.container}`}></div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position={"top-right"}
          autoClose={2000}
          pauseOnHover={false}
          closeOnClick={true}
        />
      </section>
    </>
  );
};

export default HomePopUpImg;
