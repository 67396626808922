import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import "./component/Dashboard/Dasboard.css";
import AdminLogin from "./component/admin-login/AdminLogin";
import Skeleton from "./component/skeleton/Skeleton";
import ProductTab from "./pages/Products/ProductAttributeTabs/ProductTab";
import Invoice from "./component/Invoice/Invoice";
import AddVariant from "./pages/Products/AddVariant/AddVariant";
import CategoryBanner from "./pages/Slider/CategoryBanner/CategoryBanner";
import AddCategoryBanner from "./pages/Slider/CategoryBanner/AddCategoryBanner";
import EditCategoryBanner from "./pages/Slider/CategoryBanner/EditCategoryBanner";
import BlogsList from "./pages/blogs/BlogsList";
import AddBlogs from "./pages/blogs/AddBlogs";
import EditBlogs from "./pages/blogs/EditBlogs";
import TestimonialList from "./pages/testimonial/TestimonialList";
import AddTestimonial from "./pages/testimonial/AddTestimonial";
import EditTestimonial from "./pages/testimonial/EditTestimonial";
import HomePopUpImg from "./pages/homePopUpImg/HomePopUpImg";
import MobileSliderList from "./pages/Slider/mobileSlider/MobileSliderList";
import AddMobileSlider from "./pages/Slider/mobileSlider/AddMobileSlider";
import EditMobileSlider from "./pages/Slider/mobileSlider/EditMobileSlider";
import CareInstruction from "./pages/careInstruction/CareInstruction";
import About from "./pages/aboutUs/About";
import AddTeam from "./pages/aboutUs/AddTeam";
import EditTeam from "./pages/aboutUs/EditTeam";
import SendMailToCustomer from "./pages/sendMailToCustomer/SendMailToCustomer";
import AddFaq from "./pages/faq/AddFaq";
import Faq from "./pages/faq/Faq";
import EditFaq from "./pages/faq/EditFaq";
import ImagesSetting from "./pages/imagesSetting/ImagesSetting";
import PortfolioList from "./pages/Portfolio/PortfolioDetails/PortfolioList";
import AddPortfolio from "./pages/Portfolio/PortfolioDetails/AddPortfolio";
import EditPortfolio from "./pages/Portfolio/PortfolioDetails/EditPortfolio";
import PortfolioTab from "./pages/Portfolio/PortfolioDetails/PortfolioTab";
import PortfolioCategoryList from "./pages/Portfolio/PortfolioCategory/PortfolioCategoryList";
import AddPortfolioCategory from "./pages/Portfolio/PortfolioCategory/AddPortfolioCategory";
import EditPortfolioCategory from "./pages/Portfolio/PortfolioCategory/EditPortfolioCategory";


const EditAttribute = lazy(() =>
  import("./pages/Products/EditAttribute/EditAttribute")
);
const Warratny_Return = lazy(() => import("./pages/Warranty/Warratny_Return"));
const TimeSlot = lazy(() =>
  import("./pages/DeliverySchedule/TimeSlot/TimeSlot")
);
const ClosingHours = lazy(() =>
  import("./pages/DeliverySchedule/ClosingHours/ClosingHours")
);
const Stock_Update = lazy(() => import("./pages/StockUpdate/Stock_Update"));
const Edit_Stock = lazy(() =>
  import("./pages/StockUpdate/EditStock/Edit_Stock")
);
const ListDeliveryBoy = lazy(() =>
  import("./pages/DeliveryBoy/ListDeliveryBoy")
);
const EditDeliveryBoy = lazy(() =>
  import("./pages/DeliveryBoy/EditDeliveryBoy/EditDeliveryBoy")
);
const Coupon = lazy(() => import("./pages/Coupon/Coupon"));
const AddCoupon = lazy(() => import("./pages/Coupon/AddCoupon/AddCoupon"));
const EditCoupon = lazy(() => import("./pages/Coupon/EditCoupon/EditCoupon"));
const Cashback = lazy(() => import("./pages/Cashback/Cashback"));
const Deal_Products = lazy(() => import("./pages/Deal_Products/Deal_Products"));
const AddDealProducts = lazy(() =>
  import("./pages/Deal_Products/AddDealProduct/AddDealProducts")
);
const EditDealProducts = lazy(() =>
  import("./pages/Deal_Products/EditDealProducts/EditDealProducts")
);
const RaiseTicket = lazy(() => import("./pages/RaiseTicket/RaiseTicket"));

// lazy loading for this pages only
const Home = lazy(() => import("./pages/Home/Home"));
const Categories = lazy(() => import("./pages/AllCategories/Categories"));
const AddCategories = lazy(() =>
  import("./pages/AllCategories/AddCategories/AddCategories")
);
const EditCategories = lazy(() =>
  import("./pages/AllCategories/EditCategories/EditCategories")
);
const AllOrder = lazy(() => import("./pages/Orders/AllOrders/AllOrder"));
const TodayOrder = lazy(() => import("./pages/Orders/TodayOrder/TodayOrder"));
const PendingOrders = lazy(() =>
  import("./pages/Orders/PendingOrders/PendingOrders")
);
const ConfirmOrder = lazy(() =>
  import("./pages/Orders/ConfirmOrder/ConfirmOrder")
);
const DispatchOrder = lazy(() =>
  import("./pages/Orders/DispatchOrder/DispatchOrder")
);
const CompleteOrder = lazy(() =>
  import("./pages/Orders/CompleteOrder/CompleteOrder")
);
const CancelOrder = lazy(() =>
  import("./pages/Orders/CancelOrder/CancelOrder")
);
const RefundOrder = lazy(() =>
  import("./pages/Orders/RefundOrder/RefundOrder")
);
const Area = lazy(() => import("./pages/Area/Area"));
const AreaList = lazy(() => import("./pages/Area/AreaList/AreaList"));
const EditArea = lazy(() => import("./pages/Area/EditArea/EditArea"));
const ProductOrder = lazy(() => import("./pages/PurchesOrder/ProductOrder"));
const AllPage = lazy(() => import("./pages/SubPages/List"));
const ListEdit = lazy(() => import("./pages/SubPages/ListEdit"));
const Declared_Reward = lazy(() =>
  import("./pages/Declared_Reward/Declared_Reward")
);
const ReferalAmount = lazy(() => import("./pages/ReferalAmount/ReferalAmount"));
const WelcomeBonus = lazy(() => import("./pages/WelcomeBonus/WelcomeBonus"));
const OrderLimitSetting = lazy(() =>
  import("./pages/OrderLimitSetting/OrderLimitSetting")
);
const EditOrderLimit = lazy(() =>
  import("./pages/OrderLimitSetting/EditOrderLimit/EditOrderLimit")
);
const Offers = lazy(() => import("./pages/Offers/Offers"));
const EditOffer = lazy(() => import("./pages/Offers/EditOffer"));
const Stock = lazy(() => import("./pages/Stock/Stock"));
const Notification = lazy(() => import("./pages/Notification/Notification"));
const MainSlider = lazy(() => import("./pages/Slider/MainSlider/MainSlider"));
const EditMainSlider = lazy(() =>
  import("./pages/Slider/MainSlider/EditMainSlider")
);
const AddMainSlider = lazy(() =>
  import("./pages/Slider/MainSlider/AddMainSlider")
);
const FeatureBanner = lazy(() =>
  import("./pages/Slider/FeatureBrand/FeatureBanner")
);
const AddFeatureBanner = lazy(() =>
  import("./pages/Slider/FeatureBrand/AddFeatureBanner")
);
const EditFeatureBanner = lazy(() =>
  import("./pages/Slider/FeatureBrand/EditFeatureBanner")
);
const WebBanner = lazy(() => import("./pages/Slider/WebsiteBanner/WebBanner"));
const Promotion = lazy(() =>
  import("./pages/Slider/PromotionSection/Promotion")
);
const AppUser = lazy(() => import("./pages/AppUser/AppUser"));
const User_action = lazy(() => import("./pages/AppUser/User_action"));
const Products = lazy(() => import("./pages/Products/Products"));
const AddProducts = lazy(() =>
  import("./pages/Products/AddProducts/AddProducts")
);
const Bulk_Upload = lazy(() =>
  import("./pages/Products/BulkUpload/Bulk_Upload")
);
const Edit_Products = lazy(() =>
  import("./pages/Products/EditProducts/Edit_Products")
);
const AddImageList = lazy(() =>
  import("./pages/Products/AddImage/AddImageList")
);
const Product_Category = lazy(() =>
  import("./pages/Products/Product_Category/Product_Category")
);

const Add_Coupon = lazy(() => import("./pages/Products/AddCoupon/Add_Coupon"));

// services

const Service = lazy(() => import("./pages/services/Services"));
const AddServices = lazy(() =>
  import("./pages/services/addServices/AddServices")
);
const EditServices = lazy(() =>
  import("./pages/services/editServices/EditServices")
);

// catalogue
const Catalogue = lazy(() => import("./pages/Catalogue/Catalogue"));
const AddCatalogue = lazy(() =>
  import("./pages/Catalogue/AddCatalogue/AddCatalogue")
);
const EditCatalogue = lazy(() =>
  import("./pages/Catalogue/EditCatalogue/EditCatalogue")
);

// manage services
const ManageServices = lazy(() =>
  import("./pages/manageServices/ManageServices")
);
const AddService = lazy(() =>
  import("./pages/manageServices/AddService/AddService")
);
const Edit_Service = lazy(() =>
  import("./pages/manageServices/EditServices/Edit_Service")
);
const Category_Services = lazy(() =>
  import("./pages/manageServices/CategoryServices/Category_Services")
);

// manage catalogue
const Manage_Catalogue = lazy(() =>
  import("./pages/manageCatalogue/Manage_Catalogue")
);
const AddManageCatalogue = lazy(() =>
  import("./pages/manageCatalogue/AddManageCatalogue/AddManageCatalogue")
);
const EditManageCatalogue = lazy(() =>
  import("./pages/manageCatalogue/EditManageCatalogue/EditManageCatalogue")
);
const CategoryCatalogue = lazy(() =>
  import("./pages/manageCatalogue/CategoryCatalogue/CategoryCatalogue")
);
const Our_Achievements = lazy(() =>
  import("./pages/Our_Achievements/Our_Achievements")
);
// setting pages
const EditProfile = lazy(() => import("./component/Profile/EditProfile"));
const ThemeSetting = lazy(() => import("./pages/thirdPartySetting/themeSetting/ThemeSetting"));
const SmsIntegration = lazy(() => import("./pages/thirdPartySetting/smsIntegration/SmsIntegration"));
const EmailSetting = lazy(() => import("./pages/thirdPartySetting/emailSetting/EmailSetting"));
const ProfileSetting = lazy(() => import("./pages/thirdPartySetting/profileSetting/ProfileSetting"));
const FireBaseSetting = lazy(() => import("./pages/thirdPartySetting/fireBaseSetting/FireBaseSetting"));

const Routing = () => {
  return (
    <Suspense
      fallback={
        <Skeleton />
      }
    >
      <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route path="/login" element={<AdminLogin />}></Route>
        <Route path="/all_categories" element={<Categories />}></Route>
        <Route path="/add_categories" element={<AddCategories />}></Route>
        <Route path="/edit_categories/:id" element={<EditCategories />}></Route>
        {/* orders */}
        <Route path="/all_orders" element={<AllOrder />}></Route>
        <Route path="/today_order" element={<TodayOrder />}></Route>
        <Route path="/pending_orders" element={<PendingOrders />}></Route>
        <Route path="/confirm_orders" element={<ConfirmOrder />}></Route>
        <Route path="/dispatch_orders" element={<DispatchOrder />}></Route>
        <Route path="/complete_orders" element={<CompleteOrder />}></Route>
        <Route path="/cancel_orders" element={<CancelOrder />}></Route>
        <Route path="/refund_orders" element={<RefundOrder />}></Route>
        {/* app users */}
        <Route path="/app_user" element={<AppUser />}></Route>
        <Route path="/user_action/:id" element={<User_action />}></Route>
        {/* Area */}
        <Route path="/area" element={<Area />}></Route>
        <Route path="/area_list" element={<AreaList />}></Route>
        {/* <Route path="/area_edit/:id" element={<EditArea />}></Route> */}
        <Route path="/edit_area/:id" element={<EditArea />}></Route>
        {/* product order */}
        <Route path="/product_order" element={<ProductOrder />}></Route>

        {/* manage product */}
        <Route path="/products" element={<Products />}></Route>
        <Route path="/products_tab/:id" element={<ProductTab />}></Route>
        <Route path="/add_products" element={<AddProducts />}></Route>
        <Route
          path="/edit_products/:id"
          // path="/edit_products/:id/:pageCount"
          element={<Edit_Products />}
        ></Route>
        <Route path="/edit_attribute/:id" element={<EditAttribute />}></Route>
        <Route path="/bulk_upload" element={<Bulk_Upload />}></Route>
        <Route path="/add_image_list/:id" element={<AddImageList />}></Route>
        <Route
          path="/product_category/:id"
          element={<Product_Category />}
        ></Route>
        <Route path="/add_coupon" element={<Add_Coupon />}></Route>

        <Route path="/home_popup_img" element={<HomePopUpImg />}></Route>

        {/* manage services */}
        <Route path="/services" element={<ManageServices />}></Route>
        <Route path="/add_service" element={<AddService />}></Route>
        <Route path="/edit_service/:id" element={<Edit_Service />}></Route>
        <Route
          path="/services_category/:id"
          element={<Category_Services />}
        ></Route>

        {/* Manage Catalogue */}
        <Route path="/manage_catalogue" element={<Manage_Catalogue />}></Route>
        <Route
          path="/add_manage_catalogue"
          element={<AddManageCatalogue />}
        ></Route>
        <Route
          path="/edit_manage_catalogue/:id"
          element={<EditManageCatalogue />}
        ></Route>
        <Route
          path="/catalogue_category/:id"
          element={<CategoryCatalogue />}
        ></Route>

        {/* delivery schedule hours */}
        <Route path="/time_slot" element={<TimeSlot />}></Route>
        <Route path="/closing_hours" element={<ClosingHours />}></Route>

        {/* stock update */}
        <Route path="/stock_update" element={<Stock_Update />}></Route>
        <Route path="/edit_stock" element={<Edit_Stock />}></Route>

        {/* Delivery boy list */}
        <Route path="/delivery_boy_list" element={<ListDeliveryBoy />}></Route>
        <Route path="/edit_boy_list" element={<EditDeliveryBoy />}></Route>

        {/* All Page App */}
        <Route path="/list" element={<AllPage />}></Route>
        <Route path="/list_edit/:id" element={<ListEdit />}></Route>

        <Route path="/declared_reward" element={<Declared_Reward />}></Route>
        <Route path="/refer" element={<ReferalAmount />}></Route>
        <Route path="/welcome_bonus" element={<WelcomeBonus />}></Route>
        <Route
          path="/order_limit_setting"
          element={<OrderLimitSetting />}
        ></Route>
        <Route path="/edit_order_setting" element={<EditOrderLimit />}></Route>
        {/* offers */}
        <Route path="/offer" element={<Offers />}></Route>
        <Route path="/edit_offer/:id" element={<EditOffer />}></Route>
        {/* stock */}
        <Route path="/stock" element={<Stock />}></Route>
        <Route path="/notification" element={<Notification />}></Route>
        {/* blogs pages */}
        <Route path="/blogs" element={<BlogsList />}></Route>
        <Route path="/add_blogs" element={<AddBlogs />}></Route>
        <Route path="/edit_blog/:id" element={<EditBlogs />}></Route>
        {/* blogs pages */}
        <Route path="/testimonial" element={<TestimonialList />}></Route>
        <Route path="/add_testimonial" element={<AddTestimonial />}></Route>
        <Route
          path="/edit_testimonial/:id"
          element={<EditTestimonial />}
        ></Route>
        {/* slider pages */}
        <Route path="/main_slider" element={<MainSlider />}></Route>
        <Route
          path="/edit_main_slider/:id"
          element={<EditMainSlider />}
        ></Route>
        <Route path="/add_main_slider" element={<AddMainSlider />}></Route>
        {/* mobile slider */}
        <Route path="/mobile_slider" element={<MobileSliderList />}></Route>
        <Route path="/add_mobile_slider" element={<AddMobileSlider />}></Route>
        <Route
          path="/edit_mobile_slider/:id"
          element={<EditMobileSlider />}
        ></Route>
        <Route path="/feature_brand_slider" element={<FeatureBanner />}></Route>
        <Route
          path="/add_feature_banner"
          element={<AddFeatureBanner />}
        ></Route>
        <Route
          path="/edit_feature_banner"
          element={<EditFeatureBanner />}
        ></Route>
        <Route path="/web_banner" element={<WebBanner />}></Route>
        <Route path="/promotion_section" element={<Promotion />}></Route>

        {/* category banner */}
        <Route path="/category_banner" element={<CategoryBanner />}></Route>
        <Route
          path="/edit_category_banner/:id"
          element={<EditCategoryBanner />}
        ></Route>
        <Route
          path="/add_category_banner"
          element={<AddCategoryBanner />}
        ></Route>

        {/* coupon */}
        <Route path="/coupons" element={<Coupon />}></Route>
        <Route path="/addCoupon" element={<AddCoupon />}></Route>
        <Route path="/edit_coupon/:id" element={<EditCoupon />}></Route>
        <Route path="/cashback" element={<Cashback />}></Route>
        <Route path="/deal_products" element={<Deal_Products />}></Route>
        <Route path="/add_deal_products" element={<AddDealProducts />}></Route>
        <Route
          path="/edit_deal_products"
          element={<EditDealProducts />}
        ></Route>
        <Route path="/raise_ticket" element={<RaiseTicket />}></Route>
        <Route path="/ske" element={<Skeleton />}></Route>

        {/* services */}
        <Route path="/all_services" element={<Service />}></Route>
        <Route path="/add_services" element={<AddServices />}></Route>
        <Route path="/edit_services/:id" element={<EditServices />}></Route>

        {/*Catalogue  */}
        <Route path="/catalogue" element={<Catalogue />}></Route>
        <Route path="/add_catalogue" element={<AddCatalogue />}></Route>
        <Route path="/edit_catalogue/:id" element={<EditCatalogue />}></Route>
        {/* profile */}
        <Route path="/edit_profile" element={<EditProfile />}></Route>
        {/* order details */}
        <Route path="/order_details/:id" element={<Invoice />}></Route>
        <Route path="/add_variant/:id" element={<AddVariant />}></Route>
        <Route
          path="/warranty_return_policy"
          element={<Warratny_Return />}
        ></Route>
        <Route path="/care-instruction" element={<CareInstruction />}></Route>
        <Route path="/our_achievements" element={<Our_Achievements />}></Route>

        {/* about us */}
        <Route path="/about" element={<About />}></Route>
        <Route path="/add_team" element={<AddTeam />}></Route>
        <Route path="/edit_team/:id" element={<EditTeam />}></Route>

        <Route
          path="/send-mail-to-customer"
          element={<SendMailToCustomer />}
        ></Route>
        <Route path="/faq" element={<Faq />}></Route>
        <Route path="/add-faq" element={<AddFaq />}></Route>
        <Route path="/edit-faq/:id" element={<EditFaq />}></Route>

        <Route path="/img-setting" element={<ImagesSetting />}></Route>


        {/* portfolio category */}
        <Route path="/portfolio_category" element={<PortfolioCategoryList />}></Route>
        <Route path="/add_portfolio_category" element={<AddPortfolioCategory />}></Route>
        <Route
          path="/edit_portfolio_category/:id"
          element={<EditPortfolioCategory />}
        ></Route>

        {/* portfolio */}
        <Route path="/portfolio" element={<PortfolioList />}></Route>
        <Route path="/add_portfolio" element={<AddPortfolio />}></Route>
        <Route
          path="/edit_portfolio/:id"
          element={<EditPortfolio />}
        ></Route>
        <Route
          path="/portfolio_tab/:id"
          element={<PortfolioTab />}
        ></Route>

        {/* third party pages */}
        <Route
          path="/theme-setting"
          element={<ThemeSetting />}
        ></Route>
        <Route
          path="/sms-integration"
          element={<SmsIntegration />}
        ></Route>
        <Route
          path="/email-setting"
          element={<EmailSetting />}
        ></Route>
        <Route
          path="/profile-setting"
          element={<ProfileSetting />}
        ></Route>
        <Route
          path="/firebase-setting"
          element={<FireBaseSetting />}
        ></Route>
      </Routes>
    </Suspense>
  );
};

export default Routing;
