/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
// import "./Offer.css";
import style from "./style.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { GiKnightBanner } from "react-icons/gi";
import "../../Offers/Offer.css";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import {
  API_AUTH_PASSWORD,
  API_AUTH_USERNAME,
  API_BASE_URL,
} from "../../../Constant/Global";
import { toast } from "react-toastify";

const EditPortfolio = () => {
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [status, setStatus] = useState("Y");
  const [categoryData, setCategoryData] = useState([]);
  const [catId, setCatId] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  const [errors, setErrors] = useState({
    name: "",
    location: "",
  });

  const validateForm = () => {
    let valid = true;
    const newErrors = {
      name: "",
      location: "",
    };

    // name validation
    if (name === "") {
      newErrors.name = "Name is Require *";
      valid = false;
    } else if (!/^[A-Za-z\s.]+$/.test(name)) {
      newErrors.name = "Name can only contain letters";
      valid = false;
    }

    if (location === "") {
      newErrors.location = "location is Require *";
      valid = false;
    } else if (!/^[A-Za-z\s.]+$/.test(location)) {
      newErrors.location = "location can only contain letters";
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };

  const handleStatus = (e) => {
    setStatus(e.target.value);
  };

  // get  portfolio category
  useEffect(() => {
    const handleAdd = async () => {
      try {
        const response = await axios.get(
          API_BASE_URL + "admin/portfolio_category",
          {
            auth: {
              username: API_AUTH_USERNAME,
              password: API_AUTH_PASSWORD,
            },
          }
        );
        setCategoryData(response.data.data);
      } catch (error) {
        console.log("errror occured fetching data", error);
      }
    };
    handleAdd();
  }, []);
  // details by id api

  useEffect(() => {
    const handleCategoryDetails = async () => {
      const finalData = {
        portfolio_id: id,
      };
      try {
        const response = await axios.post(
          API_BASE_URL + "admin/portfolio_details",
          finalData,
          {
            auth: {
              username: API_AUTH_USERNAME,
              password: API_AUTH_PASSWORD,
            },
          }
        );
        console.log("portfolio_details response", response.data.data);
        setName(response.data.data.name);
        setLocation(response.data.data.location);
        setCatId(response.data.data.category_id);
        setStatus(response.data.data.status);
      } catch (error) {
        console.log("errror occured fetching data", error);
      }
    };

    handleCategoryDetails();
  }, []);

  // edit data api

  const handleEditSlider = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    if (catId === "") {
      toast.error("category is required");
      return;
    }
    const finalData = {
      portfolio_id: id,
      category_id: catId,
      name: name,
      location: location,
      status: status,
    };
    try {
      const response = await axios.post(
        API_BASE_URL + "admin/edit_portfolio",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
      // setAllOrder(response.data.data);
    } catch (error) {
      console.log("errror occured fetching data", error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Update Portfolio | Decorsaga Design</title>
      </Helmet>
      <section className="add_cat_main pt-0">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 d-flex justify-content-center align-items-center">
              <div
                className={`card ${style.edit_offer_card}`}
                style={{ width: "100%", marginInline: "2rem" }}
              >
                <div className="card-body">
                  <h5 className="add_c_t">
                    <GiKnightBanner style={{ marginTop: "-.2rem" }} /> Edit
                    Portfolio
                  </h5>
                  <form onSubmit={handleEditSlider}>
                    <div className="row">
                      <div
                        className={`col-lg-3 ${style.edit_offer_label_column}`}
                      >
                        <div className="form-group">
                          <label htmlFor="">Name : *</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className={`${style.form_box}`}>
                          <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Name Title"
                            className={`form-control ${style.offer_input}`}
                          />
                          {errors.name && (
                            <p className={`${style.error_text}`}>
                              {errors.name}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={`col-lg-3 ${style.edit_offer_label_column}`}
                      >
                        <div className="form-group">
                          <label htmlFor="">location:</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className={`${style.form_box}`}>
                          <input
                            type="text"
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                            placeholder="location"
                            className={`form-control ${style.offer_input}`}
                          />
                          {errors.location && (
                            <p className={`${style.error_text}`}>
                              {errors.location}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={`col-lg-3 ${style.edit_offer_label_column}`}
                      >
                        <div className="form-group">
                          <label htmlFor="">Category:</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className={`${style.form_box}`}>
                          <select
                            name="parentCategory"
                            id="parentCat"
                            value={catId}
                            onChange={(e) => setCatId(e.target.value)}
                            className={`form-control ${style.offer_input}`}
                            style={{ appearance: "auto" }}
                          >
                            <option value="">Parent Category</option>
                            {categoryData.map((elem, index) => {
                              return (
                                <option key={index} value={elem.id}>
                                  {elem.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={`col-lg-3 ${style.edit_offer_label_column}`}
                      >
                        <div className="form-group">
                          <label htmlFor="">Select Status : *</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="form-group d-block">
                          <div className="active-sec">
                            <input
                              type="radio"
                              name="status"
                              value="Y"
                              checked={status === "Y"}
                              onChange={handleStatus}
                              className="me-2"
                            />
                            <span>Active</span>
                          </div>
                          <div className="deactive-sec">
                            <input
                              type="radio"
                              name="status"
                              value="N"
                              checked={status === "N"}
                              onChange={handleStatus}
                              className="me-2"
                            />
                            <span>Deactive</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="Add_btn_group">
                      <button type="submit" className="btn add_to_cat">
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EditPortfolio;
