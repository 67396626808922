import React from "react";
import style from "./Invoice.module.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PrintIcon from "@mui/icons-material/Print";

import { Link, useNavigate, useParams } from "react-router-dom";
import numberToWords from "number-to-words";
import axios from "axios";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global.js";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useState } from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
const Invoice = () => {
  let totalCGST = 0;
  let totalSGST = 0;
  let totalIGST = 0;
  const [orderDetailsData, setOrderDetailsData] = useState([]);
  const [userDetailsData, setUserDetailsData] = useState([]);
  const isSpecificPage = true;
  const [orderProductData, setOrderProductData] = useState([]);
  const [orderAddressData, setOrderAddressData] = useState("");
  const { id } = useParams();

  // date in address
  let defaultDate = new Date();
  defaultDate.setDate(defaultDate.getDate());

  // const onSetDate = (event) => {
  //   setDate(new Date(event.target.value));
  // };

  // order details

  useEffect(() => {
    const handleOrderDetails = async () => {
      try {
        const finalData = { sale_id: id };
        const response = await axios.post(
          API_BASE_URL + "user/order_details",
          finalData,
          {
            auth: {
              username: API_AUTH_USERNAME,
              password: API_AUTH_PASSWORD,
            },
          }
        );
        console.log("invoice response", response.data.data);
        setOrderAddressData(response.data.data);
        setOrderDetailsData(response.data.data.order_details);
        setUserDetailsData(response.data.data.user_details);
        setOrderProductData(response.data.data.order_items);
      } catch (error) {
        console.log("error cancel order", error);
        throw error;
      }
    };
    handleOrderDetails();
  }, [id]);

  function calculatePrice(lengthOfProduct, widthOfProduct, unitPrice) {
    return (
      (parseFloat(Math.ceil(lengthOfProduct)) || 0) *
      (parseFloat(Math.ceil(widthOfProduct)) || 0) *
      (parseFloat(unitPrice) || 0)
    );
  }

  const subTotal = (price, qty) => {
    return parseFloat(price) * parseFloat(qty);
  };

  const handlePrintClick = () => {
    const elementsToHide = document.querySelectorAll(
      ".btn_invoice, .navbar , .top-navbar, .footer-main, .invoice_text_title"
    );

    // Hide elements before printing
    elementsToHide.forEach((element) => {
      element.style.display = "none";
    });

    // Add print-specific styles
    const style = document.createElement("style");
    style.innerHTML = `
      @media print {
        body {
          size: A4 portrait; /* You can use 'portrait' instead of 'landscape' if needed */
        }
        /* Add more print styles as needed */
      table {
        page-break-inside: auto;
      }
      th, td {
        page-break-inside: avoid;
      }
      @page {
        margin-top: 10mm; /* Adjust the value as needed */
      }
      .stamp_size{
        margin-left:0px !important;
      }
  
        /* Add more print styles as needed */
      }
    `;
    document.head.appendChild(style);

    // Trigger the print dialog
    window.print();

    // Show elements back after printing
    elementsToHide.forEach((element) => {
      element.style.display = "block";
    });

    // Remove the print-specific styles
    document.head.removeChild(style);

    // Reload the page
    window.location.reload();
  };

  function capitalizeWords(input) {
    return input
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  const totalPrice = orderDetailsData.total_amount || 0;

  const totalPriceInWords = numberToWords.toWords(totalPrice);

  const totalPriceInWordsCapitalized = capitalizeWords(totalPriceInWords);

  // generate pdf

  const generatePDF = () => {
    const input = document.getElementById("invoice-content");
    const elementsToHide = document.querySelectorAll(
      ".btn_invoice, .navbar, .top-navbar, .footer-main, .invoice_text_title"
    );

    elementsToHide.forEach((element) => {
      element.style.display = "none";
    });
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      const pdf = new jsPDF({
        orientation: "portrait", // or "landscape"
        unit: "mm",
        format: "a4", // standard page size
      });

      pdf.addImage(imgData, "JPEG", 0, 0, 210, 297); // adjust width and height as needed
      pdf.save("download.pdf");
    });
    elementsToHide.forEach((element) => {
      element.style.display = "";
    });
  };

  
  return (
    <>
      <Helmet>
        <title>Order Details | Decorsaga Design</title>
        {/* Conditionally set the viewport scale to 0.5 */}
        {isSpecificPage && (
          <meta
            name="viewport"
            content="width=device-width, initial-scale=0.4"
          />
        )}
      </Helmet>
      <div
        id="invoice-content"
        className={`container bg-white ${style.main_invoice}`}
      >
        <div className="row">
          <header>
            <h5 className={`fw-bold fs-5 ${style.invoice_title}`}>
              {orderDetailsData.status === "4"
                ? "Tax Invoice"
                : "Order Details"}
            </h5>
          </header>
          <div className="col-lg-12">
            <table className={style.table_main}>
              <tr>
                <th
                  className={`ms-2 border-bottom-0 ${style.border_left}`}
                  colSpan={1}
                >
                  <img
                    src={require("../../component/assets/images/logo.png")}
                    height="60"
                    alt="Company Logo"
                  />
                </th>
                <th className="border-bottom-0" colSpan={9}>
                  <p className="bd_weight fs-6 fw-bold ">
                    {orderAddressData?.company_name}{" "}
                  </p>
                  <p className="bd_weight">
                    {orderAddressData?.company_address}
                  </p>
                  <div className={`${style.invoice_col}`}>
                    <p> Phone: {orderAddressData?.company_phone} </p>
                    {orderAddressData?.gst_no !== "" ? (
                      <p>GST:{orderAddressData?.gst_no} </p>
                    ) : (
                      ""
                    )}
                    <p>Email: {orderAddressData?.company_email}</p>
                  </div>
                  <p>Website : www.decorsagdesign.com</p>
                </th>
              </tr>
            </table>
            <table className={`mt-0 border-bottom-0 ${style.table_main}`}>
              <tr>
                <td className={style.border_left} colSpan={3}>
                  Bill To
                  <p className="fw-bold">{userDetailsData.user_fullname}</p>
                  <p>{orderDetailsData.delivery_address}</p>
                  {/* <p>GSTIN: </p> */}
                  <p>Phone: {userDetailsData.user_phone}</p>
                </td>
                <th
                  colSpan={8}
                  className={`${style.border_left} ${style.invoice_background}`}
                  id={style.invoice_form}
                  rowSpan={2}
                >
                  <p className="text-start mt-4">
                    Invoice No : {orderDetailsData.sale_id}
                  </p>
                  <p className="text-start">
                    Date:{" "}
                    {
                      orderDetailsData.on_date &&
                        new Date(orderDetailsData.on_date)
                          .toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                          })
                          .replace(/\//g, "-") // Replace slashes with dashes
                    }
                  </p>
                  <p className="text-start">
                    Payment Method : {orderDetailsData.payment_method}
                  </p>
                  {/* <p className="text-start ">
                    Time : {orderDetailsData.delivery_time_from}{" "}
                    {orderDetailsData.delivery_time_to}
                  </p> */}
                </th>
              </tr>
              <tr></tr>
              <tr className={`${style.total_row} ${style.width_row}`}>
                <td className={style.border_left}>#</td>
                <td className={style.border_left}>Product name</td>

                {/* <td className={style.border_left}>Quantity</td>
              <td className={style.border_left}>Price/ Unit</td> */}
                <td className={style.border_left}>Quantity</td>
                <td className={style.border_left}>Mrp</td>
                <td className={style.border_left}>Disc.</td>
                {/* <td className={style.border_left}>IGST(18%)</td>
                <td className={style.border_left}>CGST(9%)</td>
                <td className={style.border_left}>SGST(9%)</td> */}
                <td className={style.border_left}>IGST</td>
                <td className={style.border_left}>CGST</td>
                <td className={style.border_left}>SGST</td>
                <td className={style.border_left}>Amount</td>
              </tr>

              {orderProductData.map((item, index) => (
                <tr key={`${item.product_id}`} className="border-bottom-0">
                  <td className={`${style.border_left} ${style.idColumn}`}>
                    {index + 1}
                  </td>
                  <td className={style.border_left}>
                    {item.product_name} <br />
                    SKU ID: {item.product_code}
                    {/* Size: {item.size} */}
                    {parseFloat(item.tax) > 0 && (
                      <>
                        <br />
                        <span>GST(Included): {item.tax}%</span>
                      </>
                    )}
                  </td>
                  <td className={style.border_left}>
                    {item.qty} {item.unit}
                  </td>
                  <td
                    className={style.border_left}
                    style={{ fontFamily: "sans-serif" }}
                  >
                    {parseFloat(item.mrp).toFixed(2)}
                  </td>
                  <td
                    className={style.border_left}
                    style={{ fontFamily: "sans-serif" }}
                  >
                    {parseFloat(item.mrp - item.price).toFixed(2)}
                  </td>
                  <td className={style.border_left}>{item.isgt.toFixed(2)}</td>
                  <td className={style.border_left}>{item.csgt.toFixed(2)}</td>
                  <td className={style.border_left}>{item.ssgt.toFixed(2)}</td>
                  <td className={style.border_left}>
                    {subTotal(item.price, item.qty).toFixed(2)}
                  </td>
                </tr>
              ))}

              <tr></tr>
              <tr>{/* <td colSpan={5}></td>  */}</tr>
            </table>

            <table
              className={`mt-0 border-top-0 ${style.table_main} ${style.table_main1}`}
            >
              <tr className={style.tax_row}>
                <td className={style.border_left}>Taxable Amount</td>
                {/* <td className={style.border_left}>IGST (18%)</td>
                <td className={style.border_left}>CGST (9%)</td>
                <td className={style.border_left}>SGST (9%)</td> */}
                <td className={style.border_left}>IGST</td>
                <td className={style.border_left}>CGST</td>
                <td className={style.border_left}>SGST</td>
                <td className={style.border_left} colSpan={5}>
                  Tax Amount:
                </td>
              </tr>
              {orderProductData.map((item, index) => {
                // Accumulate CGST, SGST, and IGST for each product
                totalCGST += item.csgt || 0;
                totalSGST += item.ssgt || 0;
                totalIGST += item.isgt || 0;
                return null;
              })}
              <tr>
                <td className={style.border_left}>
                  ₹{" "}
                  {orderAddressData?.taxableAmount}
                </td>
                <td className={style.border_left}>₹ {orderAddressData?.totalIGST}</td>
                <td className={style.border_left}>₹ {orderAddressData?.totalCGST}</td>
                <td className={style.border_left} rowSpan={1}>
                  ₹ {orderAddressData?.totalSGST}
                </td>
                {/* <td className={style.border_left}></td> */}
                <td colSpan={4}>
                  ₹ {orderAddressData?.totalTax}
                </td>
              </tr>

              <tr>
                <td
                  rowSpan={10}
                  colSpan={3}
                  className={`fw-bold  ${style.border_left} ${style.amount_pad}`}
                >
                  Amount in Words : Indian Rupee {totalPriceInWordsCapitalized}
                  <span className="ms-1">Only</span>
                </td>
                {/* <td ></td> */}
                <td
                  className={` fw-bold  ${style.border_left}`}
                  style={{ width: "12%" }}
                >
                  Cart Value
                </td>
                <td
                  colSpan={4}
                  className="fw-bold fs-6"
                  style={{ textAlign: "right" }}
                >
                  ₹{" "}
                  {(
                    parseFloat(orderDetailsData.order_amount) -
                    parseFloat(orderDetailsData.delivery_charge)
                  ).toFixed(2)}
                </td>
              </tr>
              <tr>
                <td className={style.border_left}> Shipping</td>
                <td colSpan={4} style={{ textAlign: "right" }}>
                  {orderDetailsData.delivery_charge === null ||
                  orderDetailsData.delivery_charge === "0"
                    ? "Free"
                    : `
                    ₹ ${parseFloat(orderDetailsData.delivery_charge).toFixed(2)}
                  `}
                </td>
              </tr>
              <tr>
                <td
                  className={` fw-bold  ${style.border_left}`}
                  style={{ width: "12%" }}
                >
                  SubTotal
                </td>
                <td
                  colSpan={4}
                  className="fw-bold fs-6"
                  style={{ textAlign: "right" }}
                >
                  ₹ {parseFloat(orderDetailsData.order_amount).toFixed(2)}
                </td>
              </tr>
              {parseFloat(orderDetailsData.wallet_used).toFixed(2) > 0 && (
                <tr>
                  <td className={style.border_left}> Used Wallet</td>
                  <td colSpan={4} style={{ textAlign: "right" }}>
                    ₹ {parseFloat(orderDetailsData.wallet_used).toFixed(2)}
                  </td>
                </tr>
              )}

              {(
                parseFloat(orderDetailsData.order_amount) -
                parseFloat(orderDetailsData.wallet_used) -
                parseFloat(orderDetailsData.total_amount)
              ).toFixed(2) > 0 && (
                <tr>
                  <td className={style.border_left}> Coupon</td>
                  <td colSpan={4} style={{ textAlign: "right" }}>
                    ₹{" "}
                    {Math.round(
                      parseFloat(orderDetailsData.order_amount) -
                        parseFloat(orderDetailsData.wallet_used) -
                        parseFloat(orderDetailsData.total_amount)
                    )}
                  </td>
                </tr>
              )}

              {(parseFloat(orderDetailsData.wallet_used).toFixed(2) > 0 ||
                (
                  parseFloat(orderDetailsData.order_amount) -
                  parseFloat(orderDetailsData.wallet_used) -
                  parseFloat(orderDetailsData.total_amount)
                ).toFixed(2) > 0) && (
                <tr style={{ fontWeight: "bold" }}>
                  <td className={style.border_left}> Net Amount</td>
                  <td
                    colSpan={4}
                    className="fs-6"
                    style={{ textAlign: "right", fontWeight: "bold" }}
                  >
                    ₹ {parseFloat(orderDetailsData.total_amount).toFixed(2)}
                  </td>
                </tr>
              )}
              {orderDetailsData.payment_method !== "Final Payment" && (
                <>
                  <tr style={{ fontWeight: "bold" }}>
                    <td className={style.border_left}> Paid Amount</td>
                    <td
                      colSpan={4}
                      className="fs-6"
                      style={{ textAlign: "right", fontWeight: "bold" }}
                    >
                      ₹ {parseFloat(orderDetailsData.paid_amount).toFixed(2)}
                    </td>
                  </tr>
                  <tr style={{ fontWeight: "bold" }}>
                    <td className={style.border_left}>Balance</td>
                    <td
                      colSpan={4}
                      className="fs-6"
                      style={{ textAlign: "right", fontWeight: "bold" }}
                    >
                      ₹{" "}
                      {(
                        parseFloat(orderDetailsData.total_amount) -
                        parseFloat(orderDetailsData.paid_amount)
                      ).toFixed(2)}
                    </td>
                  </tr>
                </>
              )}

              {/* <tr>
                <td className={style.border_left}> Paid Amount</td>
                <td colSpan={4} style={{ textAlign: "right" }}>
                  ₹ {parseFloat(orderDetailsData.paid_amount).toFixed(2)}
                </td>
              </tr>
              {(
                parseFloat(orderDetailsData.total_amount) -
                parseFloat(orderDetailsData.paid_amount)
              ).toFixed(2) > 0 && (
                <tr>
                  <td className={style.border_left}>Balance</td>
                  <td colSpan={4} style={{ textAlign: "right" }}>
                    ₹{" "}
                    {(
                      parseFloat(orderDetailsData.total_amount) -
                      parseFloat(orderDetailsData.paid_amount)
                    ).toFixed(2)}
                  </td>
                </tr>
              )} */}

              {/* <tr>
                <td className={` ${style.border_left}`} colSpan={10}>
                  <span className="fw-bold"> Terms and Conditions: </span>
                  <p className="">
                    1. Payment is due within 30 days of the invoice date.
                  </p>
                  <p className="">
                    2. Late payments may incur a penalty fee of 5% per month.
                  </p>
                </td>
              </tr> */}
              {/* <tr>
                <td
                  style={{ borderTop: "none" }}
                  className={`border-top-0 ${style.border_left}`}
                  colSpan={4}
                >
                  <div className="d-flex">
                    <div>
                      <span className="fw-bold"> Bank Account Details : </span>
                      <p className="">
                        Bank Account Number : Microdynamic Software Pvt Ltd
                      </p>
                      <p className="">Bank Name : Kotak Mahindra Bank Ltd.</p>
                      <p className="">Account No : 3245964113</p>
                      <p className="">IFSC Code : KKBK0000721</p>
                      <p className="">
                        Branch : M-4 Virwani Plaza Avenue,11,East Street, Pune.
                      </p>
                    </div>

                    <div className={`stamp_size ${style.stampp_size}`}>
                      <img
                        src={require("../../assets/images/stampp.jpeg")}
                        alt="Authorized Signatory"
                        height={90}
                        width={125}
                        className="img-fluid"
                        id={`${style.height_img}`}
                      />
                    </div>
                  </div>
                  <img
                    src={require("../../assets/images/qr_code.jpeg")}
                    alt="Authorized Signatory"
                    height={90}
                    width={125}
                    className="ml-0 img-fluid"
                  />
                </td>

                <td
                  style={{ borderTop: "none" }}
                  rowSpan={2}
                  className={style.text_align}
                  colSpan={5}
                >
                  <img
                    src={require("../../assets/images/sign.jpeg")}
                    alt="Authorized Signatory"
                    height={90}
                    width={125}
                    className="  img-fluid"
                    id={` ${style.height_img}`}
                  />
                  <p className={style.text_align}>Authorized Signatory</p>
                </td>
              </tr>

              <tr>
                <td
                  style={{ borderTop: "none" }}
                  className={` ${style.border_left}`}
                  colSpan={10}
                >
                  <p className={`text-center mt-4 `}>
                    Thanks for doing business with us!
                  </p>
                </td>
              </tr> */}
            </table>
            <table
              className={`mt-0 border-top-0 ${style.table_main} ${style.table_main1}`}
            >
              <tr>
                <td
                  // rowSpan={7}
                  // colSpan={3}
                  className={`fw-bold p-3  ${style.border_left}`}
                >
                  <span className="fw-bold"> Terms and Conditions: </span>
                  <p style={{ fontWeight: "500" }}>
                    1. Shipping charges are calculated at checkout and displayed
                    before payment confirmation.
                  </p>
                  <p style={{ fontWeight: "500" }}>
                    2. Orders are delivered within 20 to 30 working days,
                    excluding public holidays.
                  </p>
                  <p style={{ fontWeight: "500" }}>
                    3. Slight variations in handcrafted wooden products are not
                    considered defects and are not eligible for return.
                  </p>
                  <p style={{ fontWeight: "500" }}>
                    4. Customized orders cannot be returned, and refunds will be
                    processed within 48 hours of claim acceptance.
                  </p>
                  <p style={{ fontWeight: "500" }}>
                    5. Cancellation requests are accepted within 3 hours of
                    booking if the order has not been dispatched.
                  </p>
                </td>
              </tr>
            </table>
          </div>
          <div className="col-lg-12 mb-5 mt-5">
            <div className="invoice_text d-flex justify-content-end mb-3 "></div>
            <div className="btn_invoice">
              {/* <Link
                to="/"
                className="btn btn-danger back_btn me-2"
                style={{ height: "40px" }}
              >
                {" "}
                <ArrowBackIcon /> Back To Home
              </Link> */}
              {orderDetailsData.status === "4" ? (
                <button
                  className="btn btn-primary print_invoice_btn"
                  style={{ height: "40px" }}
                  onClick={handlePrintClick}
                >
                  <PrintIcon /> Print Invoice
                </button>
              ) : (
                ""
              )}
              <button
                className="btn btn-success ms-2 print_invoice_btn"
                style={{ height: "40px" }}
                onClick={generatePDF}
              >
                <PrintIcon /> Download
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Invoice;
