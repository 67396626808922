/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-unused-vars */
import * as React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import GridViewIcon from "@mui/icons-material/GridView";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import "./Dasboard.css";
import { AiFillHome } from "react-icons/ai";
import { GrClose } from "react-icons/gr";
import { Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Routing from "../../Routing";
import { useEffect } from "react";
import {
  API_AUTH_PASSWORD,
  API_AUTH_USERNAME,
  API_BASE_URL,
} from "../../Constant/Global";
import axios from "axios";
import { useState } from "react";

const drawerWidth = 240;

const formatDateTime = (dateTime) => {
  const options = {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  const formattedDateTime = new Date(dateTime).toLocaleDateString(
    "en-US",
    options
  );
  return formattedDateTime.toUpperCase();
};

export default function ResponsiveDrawer(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const location = useLocation();
  const isLoginPage = location.pathname === "/login";
  const hideClasses = location.pathname === "/login";
  const isPrintPage = location.pathname === "/user_details";
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [allOpen, setAllOpen] = React.useState(false);
  const [Open, setOpen] = React.useState(false);
  const [masterOpen, setMasterOpen] = React.useState(false);
  const [catOpen, setCatOpen] = React.useState(false);
  const [productOpen, setProductOpen] = React.useState(false);
  const [PagesOpen, setPagesOpen] = React.useState(false);
  const [slideOpen, setSlideOpen] = React.useState(false);
  const [faqOpen, setFaqOpen] = React.useState(false);
  const [portfolioOpen, setPortfolioOpen] = React.useState(false);
  const [thirdPartyOpen, setThirdPartyOpen] = React.useState(false);
  const [DeliveryScheduleOpen, setDeliveryScheduleOpen] = React.useState(false);
  const [DeliveryBoyOpen, setDeliveryBoyOpen] = React.useState(false);
  const [activeItem, setActiveItem] = React.useState("item1");
  const navigate = useNavigate("");
  const [currentDateTime, setCurrentDateTime] = React.useState(new Date());
  const [selectedImage1, setSelectedImage1] = useState(null);

  const userDetails = sessionStorage.getItem("info");
  const useInfo = JSON.parse(userDetails);

  // logo for admin
  useEffect(() => {
    const handleGetDetailsById = async () => {
      const finalData = { user_id: useInfo?.user_id };
      try {
        const response = await axios.post(
          API_BASE_URL + "admin/profile_details",
          finalData,
          {
            auth: {
              username: API_AUTH_USERNAME,
              password: API_AUTH_PASSWORD,
            },
          }
        );
        if (response.data.status === true) {
          if (response.data.data.user_image) {
            setSelectedImage1(response.data.data.user_image);
          }
        }
      } catch (error) {
        console.log("error occured getting data", error);
      }
    };
    handleGetDetailsById();
  }, []);

  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("info") === null) {
      console.log("please login first");
      navigate("/login");
    }
  }, []);

  const handleCloseProfile = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    sessionStorage.removeItem("info");
    setAnchorEl(null);
    navigate("/login");
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleCloseMenu = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClick = (item) => {
    setActiveItem(item);
  };
  const drawer = (
    <div style={{ paddingBottom: "5rem", display: isPrintPage ? "none" : "" }}>
      <style>
        {`
        /* Style the scrollbar */
        ::-webkit-scrollbar {
          width: 1px;
        }

        
      `}
      </style>
      <Toolbar
        className="ps-0 d-flex justify-content-between align-items-center"
        sx={{ height: "40px" }}
      >
        <Link className="admin-logo-sec" to="/">
          <img
            className="admin-logo"
            src={selectedImage1 !== null && selectedImage1}
            alt=""
          />
        </Link>
        <button className="close_menu_btn" onClick={handleCloseMenu}>
          <GrClose color="#625C5C" />
        </button>
      </Toolbar>
      <Divider></Divider>
      <List>
        {/* <ListItem disablePadding>
          <ListItemButton>
            <SearchIcon className="search_icon" />
            <input
              type="search"
              placeholder="Search"
              className="form-control search_input"
            />
          </ListItemButton>
        </ListItem> */}
        <ListItem disablePadding>
          <ListItemButton onClick={() => setAllOpen(!allOpen)}>
            <span className="me-3 text-dark">Menu</span>
          </ListItemButton>
        </ListItem>
        <Link to="/" className="link_tag">
          <ListItem
            className={location.pathname === "/" ? "active" : "bg_dashboard"}
            onClick={() => handleClick("item1")}
            disablePadding
          >
            {" "}
            <ListItemButton>
              <ListItemIcon className="icon_bg">
                <GridViewIcon />
              </ListItemIcon>
              <ListItemText className="text-dark ">Dashboard</ListItemText>
            </ListItemButton>
          </ListItem>
        </Link>
        {/* start master dropdown */}
        <ListItem disablePadding>
          <ListItemButton onClick={() => setMasterOpen(!masterOpen)}>
            <ListItemIcon>
              <img
                src={require("../assets/images/tables-icon/master.png")}
                className="img-fluid"
                alt=""
              />
            </ListItemIcon>
            <ListItemText>Master</ListItemText>
            <span className="pe-3">
              {" "}
              {masterOpen ? (
                <KeyboardArrowRightIcon className="fs-6" />
              ) : (
                <KeyboardArrowDownIcon className="fs-6" />
              )}
            </span>
          </ListItemButton>
        </ListItem>
        {masterOpen && (
          <List>
            <Link to="/area_list" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/area_list" ? "active" : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item11")}
              >
                <ListItemButton>
                  <ListItemIcon className="icon_bg">
                    {/* <img
                      src={require("../assets/images/tables-icon/area.png")}
                      className="img-fluid"
                      alt=""
                    /> */}
                  </ListItemIcon>
                  <ListItemText>Area</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to="/blogs" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/blogs" ? "active" : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item110")}
              >
                <ListItemButton>
                  <ListItemIcon className="icon_bg">
                    {/* <img
                      src={require("../assets/images/tables-icon/blog.png")}
                      className="img-fluid"
                      alt=""
                    /> */}
                  </ListItemIcon>
                  <ListItemText>Blogs</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to="/all_categories" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/all_services"
                    ? "active"
                    : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item2")}
              >
                <ListItemButton>
                  <ListItemIcon>
                    {/* <img
                      src={require("../assets/images/tables-icon/categorises.png")}
                      className="img-fluid"
                      alt=""
                    /> */}
                  </ListItemIcon>
                  <ListItemText>Categories</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
            {/* <Link to="/our_achievements" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/our_achievements"
                    ? "active"
                    : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item70")}
              >
                <ListItemButton>
                  <ListItemIcon className="icon_bg">
                  </ListItemIcon>
                  <ListItemText>Our Achievements</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link> */}
            <Link to="/testimonial" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/testimonial"
                    ? "active"
                    : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item111")}
              >
                <ListItemButton>
                  <ListItemIcon className="icon_bg">
                    {/* <img
                  src={require("../assets/images/tables-icon/testimonial.png")}
                  className="img-fluid"
                  alt=""
                /> */}
                  </ListItemIcon>
                  <ListItemText>Testimonial</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to="/warranty_return_policy" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/warranty_return_policy"
                    ? "active"
                    : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item55")}
              >
                <ListItemButton>
                  <ListItemIcon>
                    {/* <img
                  src={require("../assets/images/tables-icon/warranty.png")}
                  className="img-fluid"
                  alt=""
                /> */}
                  </ListItemIcon>
                  <ListItemText> Warranty & Return Policy</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to="/care-instruction" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/care-instruction"
                    ? "active"
                    : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item55")}
              >
                <ListItemButton>
                  <ListItemIcon>
                    {/* <img
                  src={require("../assets/images/tables-icon/warranty.png")}
                  className="img-fluid"
                  alt=""
                /> */}
                  </ListItemIcon>
                  <ListItemText> Care & Instruction</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to="/send-mail-to-customer" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/send-mail-to-customer"
                    ? "active"
                    : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item55")}
              >
                <ListItemButton>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText>Send Mail To Customer</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to="/img-setting" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/img-setting"
                    ? "active"
                    : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item55")}
              >
                <ListItemButton>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText>Images Setting</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to="/welcome_bonus" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/welcome_bonus"
                    ? "active"
                    : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item20")}
              >
                <ListItemButton>
                  {/* <ListItemIcon className="icon_bg">
                <img
                  src={require("../assets/images/tables-icon/welcome_bonus.png")}
                  className="img-fluid"
                  alt=""
                />
              </ListItemIcon> */}
                  <ListItemIcon></ListItemIcon>
                  <ListItemText>Welcome Bonus</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
          </List>
        )}
        {/* end master dropdown */}
        <ListItem disablePadding>
          <ListItemButton onClick={() => setOpen(!Open)}>
            <ListItemIcon>
              <img
                src={require("../assets/images/tables-icon/Group.png")}
                className="img-fluid"
                alt=""
              />
            </ListItemIcon>
            <ListItemText>Orders</ListItemText>
            <span className="pe-3">
              {" "}
              {Open ? (
                <KeyboardArrowRightIcon className="fs-6" />
              ) : (
                <KeyboardArrowDownIcon className="fs-6" />
              )}
            </span>
          </ListItemButton>
        </ListItem>
        {Open && (
          <List>
            <Link to="/all_orders" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/all_orders"
                    ? "active"
                    : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item2")}
              >
                <ListItemButton>
                  <ListItemIcon>{/* Icon for All orders */}</ListItemIcon>
                  <ListItemText>All Orders</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to="/today_order" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/today_order"
                    ? "active"
                    : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item3")}
              >
                <ListItemButton>
                  <ListItemIcon>{/* Icon for Today orders */}</ListItemIcon>
                  <ListItemText>Today Orders</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to="/pending_orders" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/pending_orders"
                    ? "active"
                    : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item4")}
              >
                <ListItemButton>
                  <ListItemIcon>{/* Icon for Pending orders */}</ListItemIcon>
                  <ListItemText>Pending Orders</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to="/confirm_orders" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/confirm_orders"
                    ? "active"
                    : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item5")}
              >
                <ListItemButton>
                  <ListItemIcon>{/* Icon for confirmed orders */}</ListItemIcon>
                  <ListItemText>Confirmed Orders</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to="/dispatch_orders" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/dispatch_orders"
                    ? "active"
                    : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item6")}
              >
                <ListItemButton>
                  <ListItemIcon>
                    {/* Icon for dispatched orders */}
                  </ListItemIcon>
                  <ListItemText>Delivered Orders</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>

            <Link to="/complete_orders" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/complete_orders"
                    ? "active"
                    : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item7")}
              >
                <ListItemButton>
                  <ListItemIcon>{/* Icon for delivered orders */}</ListItemIcon>
                  <ListItemText>Completed Orders</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to="/cancel_orders" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/cancel_orders"
                    ? "active"
                    : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item8")}
              >
                <ListItemButton>
                  <ListItemIcon>{/* Icon for cancelled orders */}</ListItemIcon>
                  <ListItemText>Cancelled Orders</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
            {/* <Link to="/refund_orders" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/refund_orders"
                    ? "active"
                    : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("refunded1")}
              >
                <ListItemButton>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText>Refunded Orders</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link> */}
          </List>
        )}


        <Link to="/app_user" className="link_tag">
          <ListItem
            className={
              location.pathname === "/app_user" ? "active" : "bg_dashboard"
            }
            disablePadding
            onClick={() => handleClick("item9")}
          >
            <ListItemButton>
              <ListItemIcon className="icon_bg">
                <img
                  src={require("../assets/images/tables-icon/app_users.png")}
                  className="img-fluid"
                  alt=""
                />
              </ListItemIcon>
              <ListItemText>Customers</ListItemText>
            </ListItemButton>
          </ListItem>
        </Link>

        {/* <Link to="/all_categories" className="link_tag">
          <ListItem
            className={
              location.pathname === "/all_services" ? "active" : "bg_dashboard"
            }
            disablePadding
            onClick={() => handleClick("item2")}
          >
            <ListItemButton>
              <ListItemIcon>
                <img
                  src={require("../assets/images/tables-icon/categorises.png")}
                  className="img-fluid"
                  alt=""
                />
              </ListItemIcon>
              <ListItemText>Categories</ListItemText>
            </ListItemButton>
          </ListItem>
        </Link> */}

        {/* <ListItem disablePadding>
          <ListItemButton onClick={() => setCatOpen(!catOpen)}>
            <ListItemIcon>
              <img
                src={require("../assets/images/tables-icon/categorises.png")}
                className="img-fluid"
                alt=""
              />
            </ListItemIcon>
            <ListItemText>Categories</ListItemText>
            <span className="pe-3">
              {" "}
              {catOpen ? (
                <KeyboardArrowRightIcon className="fs-6" />
              ) : (
                <KeyboardArrowDownIcon className="fs-6" />
              )}
            </span>
          </ListItemButton>
        </ListItem>
        {catOpen && (
          <List>
            <Link to="/all_categories" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/all_categories"
                    ? "active"
                    : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item2")}
              >
                <ListItemButton>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText>Product Category</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to="/all_services" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/all_services"
                    ? "active"
                    : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item3")}
              >
                <ListItemButton>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText>Service Category</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to="/catalogue" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/catalogue" ? "active" : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item4")}
              >
                <ListItemButton>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText>Catalogue Category</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
          </List>
        )} */}
        {/* <Link to="/area_list" className="link_tag">
          <ListItem
            className={
              location.pathname === "/area_list" ? "active" : "bg_dashboard"
            }
            disablePadding
            onClick={() => handleClick("item11")}
          >
            <ListItemButton>
              <ListItemIcon className="icon_bg">
                <img
                  src={require("../assets/images/tables-icon/area.png")}
                  className="img-fluid"
                  alt=""
                />
              </ListItemIcon>
              <ListItemText>Area</ListItemText>
            </ListItemButton>
          </ListItem>
        </Link> */}
        {/* <Link to="/blogs" className="link_tag">
          <ListItem
            className={
              location.pathname === "/blogs" ? "active" : "bg_dashboard"
            }
            disablePadding
            onClick={() => handleClick("item110")}
          >
            <ListItemButton>
              <ListItemIcon className="icon_bg">
                <img
                  src={require("../assets/images/tables-icon/blog.png")}
                  className="img-fluid"
                  alt=""
                />
              </ListItemIcon>
              <ListItemText>Blogs</ListItemText>
            </ListItemButton>
          </ListItem>
        </Link> */}

        <Link to="/products" className="link_tag">
          <ListItem
            className={
              location.pathname === "/products" ? "active" : "bg_dashboard"
            }
            disablePadding
            onClick={() => handleClick("item2")}
          >
            <ListItemButton>
              <ListItemIcon>
                <img
                  src={require("../assets/images/tables-icon/products.png")}
                  className="img-fluid"
                  alt=""
                />
              </ListItemIcon>
              <ListItemText>Products</ListItemText>
            </ListItemButton>
          </ListItem>
        </Link>

        {/* <ListItem disablePadding>
          <ListItemButton onClick={() => setProductOpen(!productOpen)}>
            <ListItemIcon>
              <img
                src={require("../assets/images/tables-icon/products.png")}
                className="img-fluid"
                alt=""
              />
            </ListItemIcon>
            <ListItemText>Products</ListItemText>
            <span className="pe-3">
              {" "}
              {productOpen ? (
                <KeyboardArrowRightIcon className="fs-6" />
              ) : (
                <KeyboardArrowDownIcon className="fs-6" />
              )}
            </span>
          </ListItemButton>
        </ListItem>
        {productOpen && (
          <List>
            <Link to="/products" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/products" ? "active" : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item2")}
              >
                <ListItemButton>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText>Manage Product</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to="/services" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/services" ? "active" : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item3")}
              >
                <ListItemButton>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText>Manage Service</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to="/manage_catalogue" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/manage_catalogue"
                    ? "active"
                    : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item4")}
              >
                <ListItemButton>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText>Manage Catalogue</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to="/add_coupon" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/add_coupon"
                    ? "active"
                    : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item40")}
              >
                <ListItemButton>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText>Coupon Banner</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
          </List>
        )} */}

        {/* <Link to="/add_coupon" className="link_tag">
          <ListItem
            className={
              location.pathname === "/add_coupon" ? "active" : "bg_dashboard"
            }
            disablePadding
            onClick={() => handleClick("item40")}
          >
            <ListItemButton>
              <ListItemIcon className="icon_bg">
                <img
                  src={require("../assets/images/tables-icon/purchase_order.png")}
                  className="img-fluid"
                  alt=""
                />
              </ListItemIcon>
              <ListItemText>Coupon Banner</ListItemText>
            </ListItemButton>
          </ListItem>
        </Link> */}

        {/* <Link to="/our_achievements" className="link_tag">
          <ListItem
            className={
              location.pathname === "/our_achievements"
                ? "active"
                : "bg_dashboard"
            }
            disablePadding
            onClick={() => handleClick("item70")}
          >
            <ListItemButton>
              <ListItemIcon className="icon_bg">
                <img
                  src={require("../assets/images/tables-icon/accivement.png")}
                  className="img-fluid"
                  alt=""
                />
              </ListItemIcon>
              <ListItemText>Our Achievements</ListItemText>
            </ListItemButton>
          </ListItem>
        </Link> */}

        {/* <Link to="/product_order" className="link_tag">
          <ListItem
            className={
              location.pathname === "/product_order" ? "active" : "bg_dashboard"
            }
            disablePadding
            onClick={() => handleClick("item13")}
          >
            <ListItemButton>
              <ListItemIcon className="icon_bg">
                <img
                  src={require("../assets/images/tables-icon/purchase_order.png")}
                  className="img-fluid"
                  alt=""
                />
              </ListItemIcon>
              <ListItemText>Purchase Orders</ListItemText>
            </ListItemButton>
          </ListItem>
        </Link> */}
        {/* <ListItem disablePadding>
          <ListItemButton
            onClick={() => setDeliveryScheduleOpen(!DeliveryScheduleOpen)}
          >
            <ListItemIcon className="icon_bg">
              <img
                src={require("../assets/images/tables-icon/delivery_schedule.png")}
                className="img-fluid"
                alt=""
              />
            </ListItemIcon>
            <ListItemText>Schedule</ListItemText>
            <span className="pe-3">
              {" "}
              {DeliveryScheduleOpen ? (
                <KeyboardArrowRightIcon className="fs-6" />
              ) : (
                <KeyboardArrowDownIcon className="fs-6" />
              )}
            </span>
          </ListItemButton>
        </ListItem>
        {DeliveryScheduleOpen && (
          <List>
            <Link to="/time_slot" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/time_slot" ? "active" : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item14")}
              >
                <ListItemButton>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText>Time Slot </ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to="/closing_hours" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/closing_hours"
                    ? "active"
                    : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item34")}
              >
                <ListItemButton>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText>Closing Hour </ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
          </List>
        )} */}
        {/* <Link to="/stock_update" className="link_tag">
          <ListItem
            className={
              location.pathname === "/stock_update" ? "active" : "bg_dashboard"
            }
            disablePadding
            onClick={() => handleClick("item15")}
          >
            <ListItemButton>
              <ListItemIcon className="icon_bg">
                <img
                  src={require("../assets/images/tables-icon/stock_update.png")}
                  className="img-fluid"
                  alt=""
                />
              </ListItemIcon>
              <ListItemText>Stock Management</ListItemText>
            </ListItemButton>
          </ListItem>
        </Link>
        <ListItem disablePadding>
          <ListItemButton onClick={() => setDeliveryBoyOpen(!DeliveryBoyOpen)}>
            <ListItemIcon className="icon_bg">
              <img
                src={require("../assets/images/tables-icon/delivery_boy.png")}
                className="img-fluid"
                alt=""
              />
            </ListItemIcon>
            <ListItemText>Manage Delivery </ListItemText>
            <span className="pe-3">
              {" "}
              {DeliveryBoyOpen ? (
                <KeyboardArrowRightIcon className="fs-6" />
              ) : (
                <KeyboardArrowDownIcon className="fs-6" />
              )}
            </span>
          </ListItemButton>
        </ListItem>
        {DeliveryBoyOpen && (
          <List>
            <Link to="/delivery_boy_list" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/delivery_boy_list"
                    ? "active"
                    : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item16")}
              >
                <ListItemButton>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText>List Delivery Boy </ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
          </List>
        )} */}
        <ListItem disablePadding>
          <ListItemButton onClick={() => setPagesOpen(!PagesOpen)}>
            <ListItemIcon>
              <img
                src={require("../assets/images/tables-icon/pages.png")}
                className="img-fluid"
                alt=""
              />
            </ListItemIcon>
            <ListItemText>Portal & Setting</ListItemText>
            <span className="pe-3">
              {" "}
              {PagesOpen ? (
                <KeyboardArrowRightIcon className="fs-6" />
              ) : (
                <KeyboardArrowDownIcon className="fs-6" />
              )}
            </span>
          </ListItemButton>
        </ListItem>
        {PagesOpen && (
          <List>
            <Link to="/list" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/list" ? "active" : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item17")}
              >
                <ListItemButton>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText>List</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to="/about" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/about" ? "active" : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item17")}
              >
                <ListItemButton>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText>About Us</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
            {/* <Link to="/add-faq" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/add-faq" ? "active" : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item17")}
              >
                <ListItemButton>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText>Faq</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link> */}
            {/* <Link to="/about" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/about" ? "active" : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item17")}
              >
                <ListItemButton>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText>About Us</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link> */}
          </List>
        )}
        {/* <Link to="/declared_reward" className="link_tag">
          <ListItem
            className={
              location.pathname === "/declared_reward"
                ? "active"
                : "bg_dashboard"
            }
            disablePadding
            onClick={() => handleClick("item18")}
          >
            <ListItemButton>
              <ListItemIcon className="icon_bg">
                <img
                  src={require("../assets/images/tables-icon/reward_value.png")}
                  className="img-fluid"
                  alt=""
                />
              </ListItemIcon>
              <ListItemText>Reward & Bonus</ListItemText>
            </ListItemButton>
          </ListItem>
        </Link>
        <Link to="/refer" className="link_tag">
          <ListItem
            className={
              location.pathname === "/refer" ? "active" : "bg_dashboard"
            }
            disablePadding
            onClick={() => handleClick("item19")}
          >
            <ListItemButton>
              <ListItemIcon className="icon_bg">
                <img
                  src={require("../assets/images/tables-icon/referal_amount.png")}
                  className="img-fluid"
                  alt=""
                />
              </ListItemIcon>
              <ListItemText>Referal Amount</ListItemText>
            </ListItemButton>
          </ListItem>
        </Link>

        <Link to="/welcome_bonus" className="link_tag">
          <ListItem
            className={
              location.pathname === "/welcome_bonus" ? "active" : "bg_dashboard"
            }
            disablePadding
            onClick={() => handleClick("item20")}
          >
            <ListItemButton>
              <ListItemIcon className="icon_bg">
                <img
                  src={require("../assets/images/tables-icon/welcome_bonus.png")}
                  className="img-fluid"
                  alt=""
                />
              </ListItemIcon>
              <ListItemText>Welcome Bonus</ListItemText>
            </ListItemButton>
          </ListItem>
        </Link> */}
        {/* <Link to="/order_limit_setting" className="link_tag">
          <ListItem
            className={
              location.pathname === "/order_limit_setting"
                ? "active"
                : "bg_dashboard"
            }
            disablePadding
            onClick={() => handleClick("item21")}
          >
            <ListItemButton>
              <ListItemIcon className="icon_bg">
                <img
                  src={require("../assets/images/tables-icon/order_limit.png")}
                  className="img-fluid"
                  alt=""
                />
              </ListItemIcon>
              <ListItemText>Order limit Setting</ListItemText>
            </ListItemButton>
          </ListItem>
        </Link>
        <Link to="/offer" className="link_tag">
          <ListItem
            className={
              location.pathname === "/offer" ? "active" : "bg_dashboard"
            }
            disablePadding
            onClick={() => handleClick("item22")}
          >
            <ListItemButton>
              <ListItemIcon className="icon_bg">
                <img
                  src={require("../assets/images/tables-icon/offers.png")}
                  className="img-fluid"
                  alt=""
                />
              </ListItemIcon>
              <ListItemText>Offers</ListItemText>
            </ListItemButton>
          </ListItem>
        </Link>
        <Link to="/stock" className="link_tag">
          <ListItem
            className={
              location.pathname === "/stock" ? "active" : "bg_dashboard"
            }
            disablePadding
            onClick={() => handleClick("item23")}
          >
            <ListItemButton>
              <ListItemIcon className="icon_bg">
                <img
                  src={require("../assets/images/tables-icon/stock.png")}
                  className="img-fluid"
                  alt=""
                />
              </ListItemIcon>
              <ListItemText>Stock</ListItemText>
            </ListItemButton>
          </ListItem>
        </Link>
        <Link to="/notification" className="link_tag">
          <ListItem
            className={
              location.pathname === "/notification" ? "active" : "bg_dashboard"
            }
            disablePadding
            onClick={() => handleClick("item24")}
          >
            <ListItemButton>
              <ListItemIcon className="icon_bg">
                <img
                  src={require("../assets/images/tables-icon/notification.png")}
                  className="img-fluid"
                  alt=""
                />
              </ListItemIcon>
              <ListItemText>Notification</ListItemText>
            </ListItemButton>
          </ListItem>
        </Link> */}

        <ListItem disablePadding>
          <ListItemButton onClick={() => setSlideOpen(!slideOpen)}>
            <ListItemIcon>
              <img
                src={require("../assets/images/tables-icon/slider.png")}
                className="img-fluid"
                alt=""
              />
            </ListItemIcon>
            <ListItemText>Slider</ListItemText>
            <span className="pe-3">
              {" "}
              {slideOpen ? (
                <KeyboardArrowRightIcon className="fs-6" />
              ) : (
                <KeyboardArrowDownIcon className="fs-6" />
              )}
            </span>
          </ListItemButton>
        </ListItem>
        {slideOpen && (
          <List>
            <Link to="/main_slider" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/main_slider"
                    ? "active"
                    : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item25")}
              >
                <ListItemButton>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText>Main Slider</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to="/mobile_slider" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/mobile_slider"
                    ? "active"
                    : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item25")}
              >
                <ListItemButton>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText>Mobile Slider</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
            {/* <Link to="/category_banner" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/category_banner"
                    ? "active"
                    : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item73")}
              >
                <ListItemButton>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText>Category Banner</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to="/add_coupon" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/add_coupon"
                    ? "active"
                    : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item40")}
              >
                <ListItemButton>
                  <ListItemIcon className="icon_bg">
                  </ListItemIcon>
                  <ListItemText>Coupon Banner</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to="/home_popup_img" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/home_popup_img"
                    ? "active"
                    : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item40")}
              >
                <ListItemButton>
                  <ListItemIcon className="icon_bg">
                  </ListItemIcon>
                  <ListItemText>Home Pop Up Images</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link> */}
            {/* <Link to="/feature_brand_slider" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/feature_brand_slider"
                    ? "active"
                    : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item26")}
              >
                <ListItemButton>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText>Feature Brand Slider</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to="/web_banner" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/web_banner"
                    ? "active"
                    : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item27")}
              >
                <ListItemButton>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText>Website Banner & Hyper Link</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to="/promotion_section" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/promotion_section"
                    ? "active"
                    : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item28")}
              >
                <ListItemButton>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText>Promotion Section</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to="/login_offer_image" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/login_offer_image"
                    ? "active"
                    : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item29")}
              >
                <ListItemButton>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText>Before/After Login Offer Image</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link> */}
          </List>
        )}

        {/* faq */}

        <ListItem disablePadding>
          <ListItemButton onClick={() => setFaqOpen(!faqOpen)}>
            <ListItemIcon>
              <img
                src={require("../assets/images/tables-icon/referal_amount.png")}
                className="img-fluid"
                alt=""
              />
            </ListItemIcon>
            <ListItemText>Faq</ListItemText>
            <span className="pe-3">
              {" "}
              {faqOpen ? (
                <KeyboardArrowRightIcon className="fs-6" />
              ) : (
                <KeyboardArrowDownIcon className="fs-6" />
              )}
            </span>
          </ListItemButton>
        </ListItem>
        {faqOpen && (
          <List>
            <Link to="/faq" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/faq" ? "active" : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item25")}
              >
                <ListItemButton>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText>Faq</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
          </List>
        )}

        {/* start portfolio dropdown */}
        <ListItem disablePadding>
          <ListItemButton onClick={() => setPortfolioOpen(!portfolioOpen)}>
            <ListItemIcon>
              <img
                src={require("../assets/images/tables-icon/referal_amount.png")}
                className="img-fluid"
                alt=""
              />
            </ListItemIcon>
            <ListItemText>Portfolio</ListItemText>
            <span className="pe-3">
              {" "}
              {portfolioOpen ? (
                <KeyboardArrowRightIcon className="fs-6" />
              ) : (
                <KeyboardArrowDownIcon className="fs-6" />
              )}
            </span>
          </ListItemButton>
        </ListItem>
        {portfolioOpen && (
          <List>
            <Link to="/portfolio_category" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/portfolio_category" ? "active" : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item11_0")}
              >
                <ListItemButton>
                  <ListItemIcon className="icon_bg">
                    {/* <img
                  src={require("../assets/images/tables-icon/testimonial.png")}
                  className="img-fluid"
                  alt=""
                /> */}
                  </ListItemIcon>
                  <ListItemText>Category</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to="/portfolio" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/portfolio" ? "active" : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item11_00")}
              >
                <ListItemButton>
                  <ListItemIcon className="icon_bg">
                    {/* <img
                  src={require("../assets/images/tables-icon/testimonial.png")}
                  className="img-fluid"
                  alt=""
                /> */}
                  </ListItemIcon>
                  <ListItemText>Portfolio</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
          </List>
        )}
        {/* end portfolio dropdown */}

        <Link to="/coupons" className="link_tag">
          <ListItem
            className={
              location.pathname === "/coupons" ? "active" : "bg_dashboard"
            }
            disablePadding
            onClick={() => handleClick("item30")}
          >
            <ListItemButton>
              <ListItemIcon className="icon_bg">
                <img
                  src={require("../assets/images/tables-icon/coupon.png")}
                  className="img-fluid"
                  alt=""
                />
              </ListItemIcon>
              <ListItemText>Coupons</ListItemText>
            </ListItemButton>
          </ListItem>
        </Link>

        {/* start third party dropdown */}
        {/* <ListItem disablePadding>
          <ListItemButton onClick={() => setThirdPartyOpen(!thirdPartyOpen)}>
            <ListItemIcon>
              <img
                src={require("../assets/images/tables-icon/referal_amount.png")}
                className="img-fluid"
                alt=""
              />
            </ListItemIcon>
            <ListItemText>Third Party Setting's</ListItemText>
            <span className="pe-3">
              {" "}
              {thirdPartyOpen ? (
                <KeyboardArrowRightIcon className="fs-6" />
              ) : (
                <KeyboardArrowDownIcon className="fs-6" />
              )}
            </span>
          </ListItemButton>
        </ListItem>
        {thirdPartyOpen && (
          <List>
            <Link to="/sms-integration" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/sms-integration" ? "active" : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item11_0")}
              >
                <ListItemButton>
                  <ListItemIcon className="icon_bg">
                  </ListItemIcon>
                  <ListItemText>Sms Integration</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to="/email-setting" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/email-setting" ? "active" : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item11_00")}
              >
                <ListItemButton>
                  <ListItemIcon className="icon_bg">
                  </ListItemIcon>
                  <ListItemText>Email Setting</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to="/profile-setting" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/profile-setting" ? "active" : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item11_00")}
              >
                <ListItemButton>
                  <ListItemIcon className="icon_bg">
                  </ListItemIcon>
                  <ListItemText>Profile Setting</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to="/theme-setting" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/theme-setting" ? "active" : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item11_00")}
              >
                <ListItemButton>
                  <ListItemIcon className="icon_bg">
                  </ListItemIcon>
                  <ListItemText>Theme Setting</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to="/firebase-setting" className="link_tag">
              <ListItem
                className={
                  location.pathname === "/firebase-setting" ? "active" : "bg_dashboard"
                }
                disablePadding
                onClick={() => handleClick("item11_00")}
              >
                <ListItemButton>
                  <ListItemIcon className="icon_bg">
                  </ListItemIcon>
                  <ListItemText>Firebase Setting</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
          </List>
        )} */}
        {/* end third party dropdown */}

      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      {!isLoginPage && (
        <>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              width: { sm: `calc(100% - ${drawerWidth}px)` },
              ml: { sm: `${drawerWidth}px` },
            }}
          >
            <Toolbar
              sx={{
                display: "flex",
                justifyContent: "space-between",
                height: "40px",
                backgroundColor: "#625C5C",
                hideClasses,
              }}
            >
              <div className="d-flex align-items-center">
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2, display: { sm: "none" } }}
                >
                  <MenuIcon />
                </IconButton>
                <Typography variant="h6" noWrap component="div">
                  <Link to="/" className="me-2 admin_title_header">
                    <AiFillHome
                      color="white"
                      style={{ fontSize: "24px", marginTop: "-5px" }}
                    />
                  </Link>{" "}
                  Decorsaga Sofa & Chair
                </Typography>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6" noWrap component="div">
                  <Link to="/" className="me-2 text-white text-decoration-none">
                    <p
                      color="white"
                      style={{ fontSize: "14px", marginTop: "46px" }}
                    >
                      {formatDateTime(currentDateTime)}
                      {/* <span className="ms-3 me-4 text-white">1:23 PM</span> */}
                    </p>
                  </Link>{" "}
                </Typography>
                <div>
                  {/* Profile Dropdown */}
                  <IconButton
                    onClick={handleProfileClick}
                    aria-controls="profile-menu"
                    aria-haspopup="true"
                    color="inherit"
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="profile-menu"
                    className=""
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleCloseProfile}
                  >
                    <Link
                      to="/edit_profile"
                      className="text-decoration-none text-dark"
                    >
                      {" "}
                      <MenuItem onClick={handleCloseProfile}>
                        Edit Profile{" "}
                      </MenuItem>
                    </Link>
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </Menu>
                </div>
              </div>
            </Toolbar>
          </AppBar>
          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
          >
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true,
              }}
              sx={{
                display: { xs: "block", sm: "none" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
            >
              {!isLoginPage && <>{drawer}</>}
            </Drawer>
            <Drawer
              variant="permanent"
              sx={{
                display: { xs: "none", sm: "block" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
              open
            >
              {!isLoginPage && <>{drawer}</>}
            </Drawer>
          </Box>
        </>
      )}

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        {!isLoginPage && (
          <>
            <Toolbar />
          </>
        )}
        <Routing />
      </Box>
    </Box>
  );
}
