import React, { useEffect, useState } from "react";
import styles from "../EditAttribute/EditAttribute.module.css";
import ContactsIcon from "@mui/icons-material/Contacts";
import same from "../AddImage/Add_Image.module.css";
import { Helmet } from "react-helmet-async";
import { ToastContainer, toast } from "react-toastify";
import "../../Home/Home.css";
import styled from "../AddImage/ImageGrid.module.css";
import axios from "axios";
// import { BsTrash3 } from "react-icons/bs";
import {
  API_BASE_URL,
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
} from "../../../Constant/Global.js";
const Add_Coupon = () => {
  const [product, setProduct] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [url, setUrl] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    const handleAddItem = async () => {
      try {
        const response = await axios.get(
          API_BASE_URL + "admin/get_product_coupon_banner",
          {
            auth: {
              username: API_AUTH_USERNAME,
              password: API_AUTH_PASSWORD,
            },
          }
        );
        setProduct(response.data.data);
        console.log(response.data.data.url);
        setUrl(response.data.data.url);
      } catch (error) {
        console.log("error occured fetching data", error);
      }
    };
    handleAddItem();
  }, []);

  const handleUrlChange = (e) => {
    setUrl(e.target.value);
  };

  const handleAddProduct = async (e) => {
    e.preventDefault();
    try {
      const data = {
        image: selectedImage,
        url: url,
      };
      console.log("data", data);
      const response = await axios.post(
        API_BASE_URL + "admin/product_coupon_banner",
        data,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success(response.data.message);
        const newImage = response.data.data; // Assuming the API response contains the newly added image data
        setProduct(newImage); // Update local state with the new image
        setSelectedImage("");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("errror occured fetching data", error);
    }
  };

  //   Delete product image api

  // const handleDelete = async (id) => {
  //   const confirm = window.confirm("Are you sure want to delete these image");
  //   if (!confirm) {
  //     return;
  //   }
  //   try {
  //     const finalData = { image_id: id };
  //     const response = await axios.post(
  //       API_BASE_URL + "admin/delete_product_image",
  //       finalData,
  //       {
  //         auth: {
  //           username: API_AUTH_USERNAME,
  //           password: API_AUTH_PASSWORD,
  //         },
  //       }
  //     );
  //     toast.success(response.data.message);
  //     const updatedImage = product.filter((item) => item.id !== id);
  //     setProduct(updatedImage);
  //   } catch (error) {
  //     console.log("error occuring delete iamge");
  //   }
  // };

  // image

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (file.type.startsWith("image/")) {
        const reader = new FileReader();

        reader.onload = function (e) {
          setSelectedImage(e.target.result);
          setError(null); // Clear any previous error
        };

        reader.readAsDataURL(file);
      } else {
        setError("Selected file is not an image.");
      }
    }
  };

  const handleImageRemove = () => {
    setSelectedImage(null);
    setError(null); // Clear any previous error
  };

  return (
    <>
      {/* <Helmet>
        <title>Coupon Banner | Decorsaga Design</title>
      </Helmet> */}
      <section className="container-fluid mt-5">
        <div className="row">
          <div className="col-lg-12">
            <div className={styles.add_products}>
              <div
                className={styles.add_products_heading}
                style={{ margin: "0 10px" }}
              >
                <div
                  className={styles.main_heading}
                  style={{ background: "#fff", color: "#282828" }}
                >
                  <ContactsIcon />{" "}
                  <h6 className={styles.heading_add}>
                    Products Sidebar banner
                  </h6>
                </div>
                <div className={styles.form_padding}>
                  <div>
                    {/* <Link
                      to={`/add_image/${addImage}`}
                      className={`btn ${styles.btn_add1}`}
                    >
                      Add Image
                    </Link> */}
                    <form
                      action=""
                      onSubmit={handleAddProduct}
                      className={styles.form_padding}
                    >
                      <div className="form-group">
                        {selectedImage ? (
                          <div className={styles.product_tilte}>
                            <label
                              htmlFor=""
                              className={`mb-2 ${styles.title_heading}`}
                            >
                              Selected Banner (745w x 1175h) :{" "}
                            </label>
                            <div className={styles.select_img_sec}>
                              <img
                                src={selectedImage}
                                alt="Selected"
                                className="img-fluid"
                              />
                            </div>
                            <button
                              onClick={handleImageRemove}
                              className={`btn mt-3 ${same.rem_btn}`}
                            >
                              Remove
                            </button>
                          </div>
                        ) : (
                          <div className={styles.product_tilte}>
                            <label htmlFor="" className={styles.title_heading}>
                              Select Coupon Banner (745w x 1175h):{" "}
                            </label>
                            <input
                              type="file"
                              accept="image/*"
                              onChange={handleImageChange}
                              className="form-control w-100"
                            />
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <div className={`${styles.product_tilte} w-50`}>
                          <label htmlFor="" className={styles.title_heading}>
                            Redirect Url :{" "}
                          </label>
                          <input
                            type="text"
                            value={url}
                            onChange={handleUrlChange}
                            className="form-control w-100"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className={styles.product_add_btn}>
                          <button className={`btn btn-success `}>Update</button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className={`col-12 ${styled.container}`}>
                    {product && Object.keys(product).length > 0 ? (
                      <div style={{ padding: "0px 20px" }} key={product.id}>
                        <img
                          src={product.image}
                          className={`img-fluid}`}
                          style={{ height: "450px" }}
                          alt="Product"
                        />
                        <div className={`mt-2 ${styled["card-body"]}`}>
                          {/* Add the trash icon with a class */}
                          {/* <button
                            onClick={() => handleDelete(product.id)}
                            className={`${styled.deleteButton}`}
                          >
                            <BsTrash3 />
                          </button> */}
                        </div>
                      </div>
                    ) : (
                      <p className="ms-5 text-danger">
                        No coupon banner available
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position={"top-right"}
          autoClose={2000}
          pauseOnHover={false}
          closeOnClick={true}
        />
      </section>
    </>
  );
};

export default Add_Coupon;
