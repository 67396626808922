/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
// import "./Offer.css";
import style from "./style.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { GiKnightBanner } from "react-icons/gi";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Helmet } from "react-helmet-async";
import "../Offers/Offer.css";
import {
  API_AUTH_PASSWORD,
  API_AUTH_USERNAME,
  API_BASE_URL,
} from "../../Constant/Global";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
const AddBlogs = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [title, setTitle] = useState("");
  const [startingFrom, setStartingFrom] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("Y");
  const [validated, setValidated] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  // Function to handle file input change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleProductDescription = (e, editor) => {
    const data = editor.getData();
    setDescription(data);
  };

  // Function to handle image removal
  const handleImageRemove = () => {
    setSelectedImage(null);
  };

  const validateForm = () => {
    let valid = true;
    if (title === "") {
      toast.error("title is required");
      valid = false;
    }
    //  else if (startingFrom === "") {
    //   toast.error("Price is required");
    //   valid = false;
    // }
    else if (description === "") {
      toast.error("Description is required");
      valid = false;
    } else if (selectedImage === null) {
      toast.error("image is required");
      valid = false;
    }

    return valid;
  };

  // add slider

  const handleAddBlog = async (e) => {
    e.preventDefault();
    if (!validateForm() || isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    const finalData = {
      title: title,
      blog_description: description,
      starting_from: startingFrom,
      image: selectedImage,
      status: status,
    };
    console.log("finalData add blog", finalData);
    try {
      const response = await axios.post(
        API_BASE_URL + "admin/add_blog",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success(response.data.message);
        setValidated(false);
        // setIsSubmitting(false);
        setTimeout(() => {
          navigate("/blogs");
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
      // setAllOrder(response.data.data);
    } catch (error) {
      console.log("errror occured fetching data", error);
    }
  };

  const handleStatus = (e) => {
    setStatus(e.target.value);
  };
  return (
    <>
      <Helmet>
        <title>Add Blog | Decorsaga Design</title>
      </Helmet>
      <section className="add_cat_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 d-flex justify-content-center align-items-center">
              <div className={`card ${style.edit_offer_card}`}>
                <div className="card-body">
                  <h5 className="add_c_t">
                    <GiKnightBanner style={{ marginTop: "-.2rem" }} /> Add Blog
                  </h5>
                  <form onSubmit={handleAddBlog}>
                    <div className="row">
                      <div
                        className={`col-lg-3 ${style.edit_offer_label_column}`}
                      >
                        <div className="form-group">
                          <label htmlFor="">Blog Title : *</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="form-group">
                          <input
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            placeholder="Blog Title"
                            className={`form-control ${style.offer_input}`}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="row">
                      <div
                        className={`col-lg-3 ${style.edit_offer_label_column}`}
                      >
                        <div className="form-group">
                          <label htmlFor="">Starting From:</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="form-group">
                          <input
                            type="text"
                            value={startingFrom}
                            onChange={(e) => setStartingFrom(e.target.value)}
                            placeholder="price"
                            className={`form-control ${style.offer_input}`}
                          />
                        </div>
                      </div>
                    </div> */}
                    <div className="row">
                      <div
                        className={`col-lg-3 ${style.edit_offer_label_column}`}
                      >
                        <div className="form-group">
                          <label htmlFor="">Blog Description:</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className={`form-group blog_desc`}>
                          <CKEditor
                            editor={ClassicEditor}
                            data={description}
                            onChange={handleProductDescription}
                          />
                          {/* <input
                            type="text"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Blog Description"
                            className={`form-control ${style.offer_input}`}
                          /> */}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={`col-lg-3 ${style.edit_offer_label_column}`}
                      >
                        <div className="form-group">
                          <label htmlFor="">Image : *</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="form-group">
                          {selectedImage ? (
                            <div className="selected_img_group">
                              <img
                                src={selectedImage}
                                alt="Selected"
                                className="img-fluid"
                              />
                              <br />
                              <button
                                onClick={handleImageRemove}
                                className={`btn ${style.remove_btn}`}
                              >
                                Remove
                              </button>
                            </div>
                          ) : (
                            <>
                              <input
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                                className={`form-control ${style.offer_input}`}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={`col-lg-3 ${style.edit_offer_label_column}`}
                      >
                        <div className="form-group">
                          <label htmlFor="">Select Status : *</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="form-group d-block">
                          <div className="active-sec">
                            <input
                              type="radio"
                              name="status"
                              value="Y"
                              checked={status === "Y"}
                              onChange={handleStatus}
                              className="me-2"
                            />
                            <span>Active</span>
                          </div>
                          <div className="deactive-sec">
                            <input
                              type="radio"
                              name="status"
                              value="N"
                              checked={status === "N"}
                              onChange={handleStatus}
                              className="me-2"
                            />
                            <span>Deactive</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="row">
                    <div
                      className={`col-lg-3 ${style.edit_offer_label_column}`}
                    >
                      <div className="form-group">
                        <label htmlFor="">Sub Category : *</label>
                      </div>
                    </div>
                    <div className="col-lg-9">
                      <div className="form-group">
                        <select
                          name=""
                          id=""
                          className={`form-control ${style.offer_input}`}
                        >
                          <option value="">Sub Category</option>
                        </select>
                      </div>
                    </div>
                  </div> */}
                    <div className="Add_btn_group">
                      <button
                        disabled={isSubmitting}
                        type="submit"
                        className="btn add_to_cat"
                      >
                        {isSubmitting ? "Adding" : "Add"}
                      </button>
                    </div>
                    <div className="NoteBox">
                      <p>
                        Note :-{" "}
                        <span>Please Add (362 x 444) This Size Of Images</span>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position={"top-right"}
          autoClose={2000}
          pauseOnHover={false}
          closeOnClick={true}
        />
      </section>
    </>
  );
};

export default AddBlogs;
