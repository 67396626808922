/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import style from "../blogs/style.module.css";
import { useNavigate } from "react-router-dom";
import { GiKnightBanner } from "react-icons/gi";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Helmet } from "react-helmet-async";
import "../Offers/Offer.css";
import {
  API_AUTH_PASSWORD,
  API_AUTH_USERNAME,
  API_BASE_URL,
} from "../../Constant/Global";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
const AddFaq = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("Y");
  const [validated, setValidated] = useState(false);
  const [category, setCategory] = useState("");
  const navigate = useNavigate();

  const [categoryData, setCategoryData] = useState([]);

  // categories api
  useEffect(() => {
    getList();
  }, []);
  const getList = async () => {
    try {
      const response = await axios.get(API_BASE_URL + "admin/faq_categories", {
        auth: {
          username: API_AUTH_USERNAME,
          password: API_AUTH_PASSWORD,
        },
      });
      console.log("response faq cat list", response.data);
      setCategoryData(response.data.data);
    } catch (error) {
      console.log("errror occured fetching data", error);
    }
  };

  const handleProductDescription = (e, editor) => {
    const data = editor.getData();
    setDescription(data);
  };

  const validateForm = () => {
    let valid = true;
    if (category === "") {
      toast.error("Category Id is required");
      valid = false;
    } else if (title === "") {
      toast.error("Question is required");
      valid = false;
    } else if (description === "") {
      toast.error("Answer is required");
      valid = false;
    }

    return valid;
  };

  // add slider

  const handleAdd = async (e) => {
    e.preventDefault();
    if (!validateForm() || isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    const finalData = {
      faq_category_id: category,
      question: title,
      answer: description,
      status: "Y",
    };
    console.log("finalData add blog", finalData);
    try {
      const response = await axios.post(
        API_BASE_URL + "admin/add_faq",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("response.data", response.data);
      if (response.data.status === true) {
        toast.success(response.data.message);
        setValidated(false);
        // setIsSubmitting(false);
        setTimeout(() => {
          navigate("/faq");
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
      // setAllOrder(response.data.data);
    } catch (error) {
      console.log("errror occured fetching data", error);
    }
  };

  const handleStatus = (e) => {
    setStatus(e.target.value);
  };
  return (
    <>
      <Helmet>
        <title>Faq | Decorsaga Design</title>
      </Helmet>
      <section className="add_cat_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 d-flex justify-content-center align-items-center">
              <div className={`card ${style.edit_offer_card}`}>
                <div className="card-body">
                  <h5 className="add_c_t">
                    <GiKnightBanner style={{ marginTop: "-.2rem" }} /> Add FAQ
                  </h5>
                  <form onSubmit={handleAdd}>
                    <div className="row">
                      <div
                        className={`col-lg-3 ${style.edit_offer_label_column}`}
                      >
                        <div className="form-group">
                          <label htmlFor="">Category : *</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="form-group">
                          <select
                            className={`form-control ${style.offer_input}`}
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                          >
                            <option value="">Select Category</option>
                            {categoryData.map((cat) => {
                              return (
                                <option key={cat.id} value={cat.id}>
                                  {cat.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={`col-lg-3 ${style.edit_offer_label_column}`}
                      >
                        <div className="form-group">
                          <label htmlFor="">Question : *</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="form-group">
                          <input
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            placeholder="Question"
                            className={`form-control ${style.offer_input}`}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={`col-lg-3 ${style.edit_offer_label_column}`}
                      >
                        <div className="form-group">
                          <label htmlFor="">Answer:</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className={`form-group blog_desc`}>
                          <CKEditor
                            editor={ClassicEditor}
                            data={description}
                            onChange={handleProductDescription}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="row">
                      <div
                        className={`col-lg-3 ${style.edit_offer_label_column}`}
                      >
                        <div className="form-group">
                          <label htmlFor="">Select Status : *</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="form-group d-block">
                          <div className="active-sec">
                            <input
                              type="radio"
                              name="status"
                              value="Y"
                              checked={status === "Y"}
                              onChange={handleStatus}
                              className="me-2"
                            />
                            <span>Active</span>
                          </div>
                          <div className="deactive-sec">
                            <input
                              type="radio"
                              name="status"
                              value="N"
                              checked={status === "N"}
                              onChange={handleStatus}
                              className="me-2"
                            />
                            <span>Deactive</span>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="Add_btn_group">
                      <button
                        disabled={isSubmitting}
                        type="submit"
                        className="btn add_to_cat"
                      >
                        {isSubmitting ? "Adding" : "Add"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position={"top-right"}
          autoClose={2000}
          pauseOnHover={false}
          closeOnClick={true}
        />
      </section>
    </>
  );
};

export default AddFaq;
