import React, { useState } from "react";
import styles from "../EditProducts/Edit_Products.module.css";
import ContactsIcon from "@mui/icons-material/Contacts";
import { useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  API_BASE_URL,
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
} from "../../../Constant/Global";
const SEO = ({ toast }) => {
  const { id } = useParams("");
  const navigate = useNavigate();
  const [seoData, setSeoData] = useState({
    title: "",
  });

  const [errors, setErrors] = useState({
    title: "",
  });

  const validateForm = () => {
    let valid = true;
    const newErrors = {
      title: "",
    };

    if (seoData.title === "") {
      newErrors.title = "Add Some Keyword *";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleEditProductChange = (e) => {
    setSeoData({ ...seoData, [e.target.name]: e.target.value });
  };

  // product details by id

  useEffect(() => {
    handleProductDetails();
  }, []);

  const handleProductDetails = async () => {
    try {
      const data = {
        product_id: id,
      };
      const response = await axios.post(
        API_BASE_URL + "admin/get_product_seo",
        data,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("seo details res", response.data);
      setSeoData({
        title: response.data.data.seo_keywords,
      });
    } catch (error) {
      console.log("errror occured fetching data", error);
    }
  };

  // product edit api

  const handleAddSEO = async (e) => {
    e.preventDefault();
    const { title } = seoData;

    if (!validateForm()) {
      return;
    }
    try {
      const data = {
        product_id: id,
        seo_keywords: title,
        // title: title,
      };
      console.log("seo finalData", data);
      const response = await axios.post(
        API_BASE_URL + "admin/update_product_seo",
        data,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success(response.data.message);
        setTimeout(() => {
          navigate("/products");
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("errror occured fetching data", error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Update Product | Decorsaga Design</title>
      </Helmet>
      <section className="container-fluid mb-5">
        <div className="row">
          <div className="col-lg-12" style={{ backgroundColor: "#f3f3f3" }}>
            <div className={styles.add_products}>
              <div className={styles.add_products_heading}>
                <div className={styles.main_heading}>
                  <ContactsIcon /> <h6 className={styles.heading_add}>SEO</h6>
                </div>
                <form className={styles.form_padding} onSubmit={handleAddSEO}>
                  <div className={styles.product_add_one_line}>
                    <div
                      className={styles.product_tilte}
                      style={{ width: "100%" }}
                    >
                      <label htmlFor="" className={styles.title_heading}>
                        SEO Keyword :{" "}
                      </label>
                      <input
                        type="text"
                        name="title"
                        value={seoData.title}
                        onChange={handleEditProductChange}
                        placeholder="Ex : Decorsaga Design, interior design or etc..."
                        className="form-control mt-2"
                        autoComplete="off"
                      />
                      {errors.title && (
                        <p className={`${styles.error_text}`}>{errors.title}</p>
                      )}
                    </div>
                  </div>
                  <div className="seo-note-box">
                    <p className="seo-note-title">
                      <span>Note</span> : Add keyword with comma(,) separated
                      like <span>Decorsaga Design, interior design</span>
                    </p>
                  </div>
                  <div className={`${styles.product_add_btn}`}>
                    <button className={`btn ${styles.btn_add}`}>Save</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SEO;
