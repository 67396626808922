import React, { useEffect, useRef, useState } from "react";
import styles from "../EditAttribute/EditAttribute.module.css";
import ContactsIcon from "@mui/icons-material/Contacts";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "../../Home/Home.css";
import styled from "../AddImage/ImageGrid.module.css";
import axios from "axios";
import { BsTrash3 } from "react-icons/bs";
import AsyncSelect from "react-select/async";
import {
  API_BASE_URL,
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
} from "../../../Constant/Global.js";
const AddVariant = ({ toast }) => {
  const [product, setProduct] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    handleAddItem();
  }, []);

  //   variant
  const handleVariant = async (inputValue) => {
    const finalData = { product_id: id };
    try {
      const response = await axios.post(
        API_BASE_URL + "admin/products",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("variant", response.data);
      const data = response.data.data;
      if (!inputValue) {
        return data.map((item) => ({
          value: item.product_id,
          label: item.product_name,
        }));
      }
      // If there's inputValue, filter options based on the input
      const filteredOptions = data.filter((item) =>
        item.product_name.toLowerCase().includes(inputValue.toLowerCase())
      );

      return filteredOptions.map((item) => ({
        value: item.product_id,
        label: item.product_name,
      }));
    } catch (error) {
      console.log("error fetching data", error);
    }
  };

  //   add product variant
  const handleAddItem = async () => {
    const finalData = { product_id: id };
    try {
      const response = await axios.post(
        API_BASE_URL + "admin/product_variants",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      setProduct(response.data.data);
      console.log("variant added data", response.data.data);
    } catch (error) {
      console.log("error occured fetching data", error);
    }
  };

  const handleChange = (e) => {
    setSelectedVariant(e.target.value);
  };

  const handleRefresh = async () => {
    await handleAddItem();
  };

  const handleAddProduct = async (e) => {
    e.preventDefault();
    try {
      const data = {
        product_id: id,
        variant_id: selectedVariant.value,
      };
      console.log("add variant request", data);
      const response = await axios.post(
        API_BASE_URL + "admin/add_product_variant",
        data,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success(response.data.message);
        console.log("add variant data", response.data.data);
        setSelectedVariant("");
        handleRefresh();
        const newImage = response.data.data; // Assuming the API response contains the newly added image data
        setProduct([newImage, ...product]); // Update local state with the new image
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("errror occured fetching data", error);
    }
  };

  //   Delete product image api

  const handleDelete = async (variantId) => {
    const confirm = window.confirm("Are you sure want to delete these image");
    if (!confirm) {
      return;
    }
    try {
      const finalData = { product_id: id, variant_id: variantId };
      const response = await axios.post(
        API_BASE_URL + "admin/delete_product_variant",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      toast.success(response.data.message);
      const updatedImage = product.filter(
        (item) => item.product_id !== variantId
      );
      setProduct(updatedImage);
    } catch (error) {
      console.log("error occuring delete iamge");
    }
  };

  return (
    <>
      <Helmet>
        <title>Update Product | Decorsaga Design</title>
      </Helmet>
      <section className="container-fluid">
        <div className="row">
          <div className="col-lg-12" style={{ backgroundColor: "#f3f3f3" }}>
            <div className={styles.add_products}>
              <div className={styles.add_products_heading}>
                <div className={styles.main_heading}>
                  <ContactsIcon />{" "}
                  <h6 className={styles.heading_add}>Add Variant</h6>
                </div>
                <div className={styles.form_padding}>
                  <div>
                    <form
                      action=""
                      onSubmit={handleAddProduct}
                      className={styles.form_padding}
                    >
                      <div className="form-group d-block">
                        <AsyncSelect
                          type="text"
                          className="select_area"
                          name="selectedVariant"
                          onChange={(selectedOption) => {
                            setSelectedVariant(selectedOption); // Set the entire selected option object
                          }}
                          value={selectedVariant}
                          loadOptions={handleVariant}
                          isSearchable={true}
                          defaultOptions={true}
                          autoComplete="off"
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              borderRadius: 0, // Set border-radius to 0
                              padding: 1,
                              paddingBottom: 3,
                              marginBottom: 4,
                              boxShadow: "none",
                              borderColor: state.isFocusedVisible
                                ? "black"
                                : provided.borderColor,
                            }),
                          }}
                        />
                        <div className={styles.product_add_btn}>
                          <button className={`btn btn-success `}>Add</button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className={styled.container}>
                    {product && product.length > 0 ? (
                      product.map((item) => (
                        <div className={styled.column} key={item.id}>
                          <div className={`${styled.card} card`}>
                            <img
                              src={item.product_image}
                              className={`card-img-top ${styled["card-img-top"]}`}
                              alt="Product"
                            />
                            <h6 className={styled.product_name_variant}>
                              {item.product_name}
                            </h6>
                            <div
                              className={`card-body p-0 ${styled["card-body"]}`}
                            >
                              {/* Add the trash icon with a class */}
                              <button
                                onClick={() => handleDelete(item.product_id)}
                                className={`${styled.deleteButton}`}
                              >
                                <BsTrash3 />
                              </button>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p className="ms-5 text-danger text-start">
                        No variant available
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddVariant;
