/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
// import "./Offer.css";
import style from "./style.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { GiKnightBanner } from "react-icons/gi";
import "../../Offers/Offer.css";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import {
  API_AUTH_PASSWORD,
  API_AUTH_USERNAME,
  API_BASE_URL,
} from "../../../Constant/Global";
import { ToastContainer, toast } from "react-toastify";

const EditPortfolioCategory = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImage1, setSelectedImage1] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("Y");
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const [errors, setErrors] = useState({
    name: "",
    description: "",
  });

  const validateForm = () => {
    let valid = true;
    const newErrors = {
      name: "",
      description: "",
    };

    // name validation
    if (name === "") {
      newErrors.name = "Name is Require *";
      valid = false;
    } else if (!/^[A-Za-z\s]+$/.test(name)) {
      newErrors.name = "Name can only contain letters";
    }

    if (description === "") {
      newErrors.description = "Description is Require *";
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };

  // Function to handle file input change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Function to handle image removal
  const handleImageRemove = () => {
    setSelectedImage(null);
    setSelectedImage1(null);
  };

  const handleStatus = (e) => {
    setStatus(e.target.value);
  };

  // details by id api

  useEffect(() => {
    const handleCategoryDetails = async () => {
      const finalData = {
        portfolio_category_id: id,
      };
      try {
        const response = await axios.post(
          API_BASE_URL + "admin/portfolio_category_details",
          finalData,
          {
            auth: {
              username: API_AUTH_USERNAME,
              password: API_AUTH_PASSWORD,
            },
          }
        );
        console.log("portfolio_details response", response.data.data);
        setName(response.data.data.name);
        setDescription(response.data.data.description);
        // Check if the image is available in the response
        if (response.data.data.image) {
          setSelectedImage1(response.data.data.image);
        }
        setStatus(response.data.data.status);
      } catch (error) {
        console.log("errror occured fetching data", error);
      }
    };

    handleCategoryDetails();
  }, []);

  // edit data api

  const handleEditSlider = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    const finalData = {
      portfolio_category_id: id,
      name: name,
      status: status,
    };
    // Conditionally include the image property
    if (selectedImage) {
      finalData.image = selectedImage;
    }
    try {
      const response = await axios.post(
        API_BASE_URL + "admin/edit_portfolio_category",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
      // setAllOrder(response.data.data);
    } catch (error) {
      console.log("errror occured fetching data", error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Update Portfolio Category | Decorsaga Design</title>
      </Helmet>
      <section className="add_cat_main ">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 d-flex justify-content-center align-items-center">
              <div className={`card ${style.edit_offer_card}`}>
                <div className="card-body">
                  <h5 className="add_c_t">
                    <GiKnightBanner style={{ marginTop: "-.2rem" }} /> Edit
                    Category
                  </h5>
                  <form onSubmit={handleEditSlider}>
                    <div className="row">
                      <div
                        className={`col-lg-3 ${style.edit_offer_label_column}`}
                      >
                        <div className="form-group">
                          <label htmlFor="">Name : *</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className={`${style.form_box}`}>
                          <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Name Title"
                            className={`form-control ${style.offer_input}`}
                          />
                          {errors.name && (
                            <p className={`${style.error_text}`}>
                              {errors.name}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* <div className="row">
                      <div
                        className={`col-lg-3 ${style.edit_offer_label_column}`}
                      >
                        <div className="form-group">
                          <label htmlFor="">Description:</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className={`${style.form_box}`}>
                          <input
                            type="text"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Description"
                            className={`form-control ${style.offer_input}`}
                          />
                          {errors.description && (
                            <p className={`${style.error_text}`}>
                              {errors.description}
                            </p>
                          )}
                        </div>
                      </div>
                    </div> */}
                    <div className="row">
                      <div
                        className={`col-lg-3 ${style.edit_offer_label_column}`}
                      >
                        <div className="form-group">
                          <label htmlFor="">Image (430w x 445h) : *</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="form-group">
                          {selectedImage1 && !selectedImage && (
                            <div className={`${style.selected_img_group}`}>
                              <img
                                src={selectedImage1}
                                alt="Selected"
                                className="img-fluid"
                              />
                              <br />
                              <button
                                onClick={handleImageRemove}
                                className={`btn ${style.remove_btn}`}
                              >
                                Remove
                              </button>
                            </div>
                          )}
                          {selectedImage && !selectedImage1 && (
                            <div className={`${style.selected_img_group}`}>
                              <img
                                src={selectedImage}
                                alt="Selected"
                                className="img-fluid"
                              />
                              <br />
                              <button
                                onClick={handleImageRemove}
                                className={`btn ${style.remove_btn}`}
                              >
                                Remove
                              </button>
                            </div>
                          )}
                          {!selectedImage1 && !selectedImage && (
                            <input
                              type="file"
                              accept="image/*"
                              onChange={handleImageChange}
                              className={`form-control ${style.offer_input}`}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={`col-lg-3 ${style.edit_offer_label_column}`}
                      >
                        <div className="form-group">
                          <label htmlFor="">Select Status : *</label>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="form-group d-block">
                          <div className="active-sec">
                            <input
                              type="radio"
                              name="status"
                              value="Y"
                              checked={status === "Y"}
                              onChange={handleStatus}
                              className="me-2"
                            />
                            <span>Active</span>
                          </div>
                          <div className="deactive-sec">
                            <input
                              type="radio"
                              name="status"
                              value="N"
                              checked={status === "N"}
                              onChange={handleStatus}
                              className="me-2"
                            />
                            <span>Deactive</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="Add_btn_group">
                      <button type="submit" className="btn add_to_cat">
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position={"top-right"}
          autoClose={2000}
          pauseOnHover={false}
          closeOnClick={true}
        />
      </section>
    </>
  );
};

export default EditPortfolioCategory;
