import React, { useEffect, useRef, useState } from "react";
import styles from "../../Products/EditAttribute/EditAttribute.module.css";
import ContactsIcon from "@mui/icons-material/Contacts";
import same from "../../Products/AddImage/Add_Image.module.css";
import { useParams } from "react-router-dom";
import "../../Home/Home.css";
import styled from "../../Products/AddImage/ImageGrid.module.css";
import axios from "axios";
import { BsTrash3 } from "react-icons/bs";
import { Helmet } from "react-helmet-async";
import {
  API_BASE_URL,
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
} from "../../../Constant/Global.js";
const PortfolioImages = ({ toast }) => {
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [product, setProduct] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [lastImage, setLastImage] = useState("");
  const [images, setImages] = useState();
  const [error, setError] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    handleAddItem();
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        setImages(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAddItem = async () => {
    const finalData = { portfolio_id: id };
    try {
      const response = await axios.post(
        API_BASE_URL + "admin/get_portfolio_gallery",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      setProduct(response.data.data);
      console.log(
        " gallery data",
        response.data.data[response.data.data.length - 1].id
      );
      if (response.data.data && response.data.data.length > 0) {
        const lastImageId =
          response.data.data[response.data.data.length - 1].id;
        setLastImage(lastImageId);
      } else {
        // Handle the case where there are no images in the response data
        setLastImage(null);
      }
    } catch (error) {
      console.log("error occured fetching data", error);
    }
  };

  const handleRefresh = async () => {
    await handleAddItem();
  };

  const validateForm = () => {
    let valid = true;
    if (images.length <= 0) {
      toast.error("Image is required");
      valid = false;
    }

    return valid;
  };

  const handleAddProduct = async (e) => {
    e.preventDefault();
    if (!validateForm() || isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    setLoading(true);
    try {
      const data = {
        portfolio_id: id,
        // image: selectedImage,
        image: images || "",
      };
      console.log("add product image finalData", data);
      const response = await axios.post(
        API_BASE_URL + "admin/add_portfolio_gallery_image",
        data,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        setIsSubmitting(false);
        setLoading(false);
        toast.success(response.data.message);
        console.log("add_portfolio_gallery_image data", response.data.data);
        // setSelectedImage("");
        setImages("");
        handleRefresh();
        const newImage = response.data.data; // Assuming the API response contains the newly added image data
        // setProduct([newImage, ...product]);
        setProduct([newImage, ...product]);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("errror occured fetching data", error);
    }
  };

  //   Delete product image api

  const handleDelete = async (id) => {
    const confirm = window.confirm("Are you sure want to delete these image ?");
    if (!confirm) {
      return;
    }
    try {
      const finalData = { portfolio_gallery_image_id: id };
      const response = await axios.post(
        API_BASE_URL + "admin/delete_portfolio_gallery_image",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      toast.success(response.data.message);
      const updatedImage = product.filter((item) => item.id !== id);
      setProduct(updatedImage);
    } catch (error) {
      console.log("error occuring delete iamge");
    }
  };

  const handleImageRemove = (img) => {
    setImages("");
    // setImages([]);
    setError(null);
  };

  return (
    <>
      <Helmet>
        <title>Product Images | Decorsaga Design</title>
      </Helmet>
      <section className="container-fluid">
        <div className="row">
          <div className="col-lg-12" style={{ backgroundColor: "#f3f3f3" }}>
            <div className={styles.add_products}>
              <div className={styles.add_products_heading}>
                <div className={styles.main_heading}>
                  <ContactsIcon />{" "}
                  <h6 className={styles.heading_add}>Add Images</h6>
                </div>
                <div className={styles.form_padding}>
                  <div>
                    {/* <Link
                      to={`/add_image/${addImage}`}
                      className={`btn ${styles.btn_add1}`}
                    >
                      Add Image
                    </Link> */}
                    <div className={styles.form_padding}>
                      <div className="">
                        {/* latest code multi select images */}

                        {images ? (
                          <div className={`${styled.add_multi_img_input_box}`}>
                            <label
                              htmlFor=""
                              className={`mb-2 ${styles.title_heading}`}
                            >
                              Selected Image (500w X 365h) :{" "}
                            </label>
                            <div className={`${styled.select_multi_img_box}`}>
                             
                                <div
                                  className={`${styled.select_multi_img_sub_box}`}
                                >
                                  <div className={styled.select_img_sec}>
                                    <img
                                      src={images}
                                      alt="Selected"
                                      className="img-fluid"
                                    />
                                  </div>
                                  <button
                                    onClick={() => handleImageRemove(images)}
                                    className={`btn mt-3 ms-0 ${same.rem_btn}`}
                                  >
                                    Removes
                                  </button>
                                </div>
                            </div>
                          </div>
                        ) : (
                          <div className={`${styled.add_multi_img_input_box}`}>
                            <label
                              htmlFor=""
                              className={`mb-2 ${styles.title_heading}`}
                            >
                              Selected Image (500w X 365h) :{" "}
                            </label>
                            <input
                              type="file"
                              onChange={handleFileChange}
                              className="form-control"
                            />
                          </div>
                        )}
                        <div
                          // className={styles.product_add_btn}
                          onClick={handleAddProduct}
                          style={{ float: "right", marginBottom: "2rem" }}
                        >
                          <button
                            disabled={isSubmitting}
                            type="submit"
                            className={`btn btn-success `}
                          >
                            {isSubmitting ? "Adding" : " Add Image"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  {loading ? (
                    <div className="text-center">
                      <img
                        src={require("../../../component/assets/images/loading.gif")}
                        alt="loading"
                        height={30}
                        width={30}
                        className="img-fluid"
                      />
                      <p className="text-center">Please Wait </p>
                    </div>
                  ) : (
                    <div className={`${styled.multi_img_box}`}>
                      {product && product.length > 0 ? (
                        product.map((item, index) => (
                          <div
                            key={index}
                            className={`${styled.multi_img_box_sub_sec}`}
                          >
                            <img src={item.image} alt="decorsaga" />
                            <button
                              onClick={() => handleDelete(item.id)}
                              className={`${styled.deleteButton}`}
                            >
                              <BsTrash3 />
                            </button>
                          </div>
                        ))
                      ) : (
                        <p className="ms-5 text-danger">No images available</p>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PortfolioImages;
